import { makeStyles, Select, SelectProps } from "@material-ui/core";
import { color } from "@resources/styles";
import React from "react";
import styled from "styled-components";

type StyledSelectProps = { paperStyle?: any; inputStyle?: any } & Omit<SelectProps, "MenuProps">;

const useStyles = makeStyles((theme) => ({
  input: (props: any) => ({ ...props.inputStyle }),
  paper: (props: any) => ({
    maxWidth: 210,
    maxHeight: "30vh",
    marginTop: "-1px",
    border: `2px solid ${color.blue}`,
    borderRadius: "4px",
    backgroundColor: color.realWhite,
    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
    "&:focus": {
      outline: "unset",
    },
    "& ul": {
      padding: 0,
      borderRadius: "4px",
      "& li": {
        whiteSpace: "normal",
        minHeight: "50px",
        color: color.black,
        fontSize: "17px",
        lineHeight: "20px",
        "& :hover": {
          backgroundColor: color.secondaryOpacity(0.25),
        },
      },
    },
    ".Mui-selected": {
      backgroundColor: color.secondaryOpacity(0.6),
      "& :hover": {
        backgroundColor: color.secondaryOpacity(0.25),
      },
    },
    ...props.paperStyle,
  }),
}));
const StyledSelect = React.forwardRef<HTMLSelectElement, StyledSelectProps>(({ paperStyle, inputStyle, ...props }, ref) => {
  const classes = useStyles({ inputStyle });
  return (
    <Select
      ref={ref}
      {...props}
      MenuProps={{
        classes: { paper: classes.paper },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }}
      inputProps={{
        classes: {
          outlined: classes.input,
        },
      }}
    />
  );
});

export default StyledSelect;
