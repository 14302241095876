import config from "@config/index";
import { cannalogueAlert } from "@reducers/alerts";
import { Checkout, Dob, Order } from "@reducers/types";
import USAProvince from "@resources/mock/USAProvince";
import * as ROUTE from "@resources/routeConst";
import redirect from "@utils/redirect";
import { unflatten } from "flat";
import _ from "lodash";
import moment from "moment";
import { i18n, useTranslation } from "./i18n";
import isServer from "./isServer";
import { detectBot } from "./userAgent";
import useWindow from "./useWindow";

export const USMode = config.country === "CA" ? false : true;

export const buildErrorMessage = (t) => (
  {
    required: t("common:common.validation_error.required"),
    invalidEmail: t("common:common.validation_error.invalid_email"),
    invalidPhone: t("common:common.validation_error.invalid_phone"),
    invalidName: t("common:common.validation_error.invalid_name"),
    tooLong: (length) => t("common:common.validation_error.text_too_long", { length }),
    tooShort: (length) => t("common:common.validation_error.text_too_short", { length }),
    fileSizeError: (size) => t("common:common.validation_error.file_size_too_large", { size }),
    fileTypeError: (type) => t("common:common.validation_error.file_type_error", { type }),

  }
);

export const findImageVersion = () => {
  let imageTag = "";
  if (isServer) {
    // @ts-ignore
    imageTag = global.imageTag;
  } else {
    // @ts-ignore
    imageTag = window.imageTag;
  }

  return imageTag;
};

const CMS_BASE = `${config.staticContent}/cms`;
export const IMG_NOT_AVAILABLE_URL = `${CMS_BASE}/images/other/image_not_available_v2.png?version=${findImageVersion()}`;
export const MEDICAL_DOCUMENT = (lang) => `${CMS_BASE}/pdfs/${lang}/Cannalogue_Medical_Document.pdf?version=${findImageVersion()}`;
export const REGISTRATION_DOCUMENT = (lang) => `${CMS_BASE}/pdfs/${lang}/Cannalogue_Registration_Document.pdf?version=${findImageVersion()}`;
export const PRIVACY_POLICY = (lang) => `${CMS_BASE}/pdfs/${lang}/Privacy_Policy.pdf?version=${findImageVersion()}`;
export const TERMS_AND_CONDITINOS = (lang) => `${CMS_BASE}/pdfs/${lang}/Terms_and_Conditions.pdf?version=${findImageVersion()}`;
export const PERMANENT_ADDRESS_AMEND_FORM = (lang) => `${CMS_BASE}/pdfs/${lang}/Cannalogue_Permanent_Address_Amendment_Form.pdf?version=${findImageVersion()}`;
export const REGISTRATION_AMEND_FORM = (lang) => `${CMS_BASE}/pdfs/${lang}/Cannalogue_Registration_Amendment_Form.pdf?version=${findImageVersion()}`;
export const HCP_AMEND_FORM = (lang) => `${CMS_BASE}/pdfs/${lang}/Healthcare_Practitioner_Amendment_Form.pdf?version=${findImageVersion()}`;

export const useProvinceOptions = () => {
  const { getObject } = useTranslation("common");
  return USMode ? USAProvince : (getObject("common.province") || {});
};

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
}
/**
 * Valid phone formats
 * (123) 456-7890
 * (123)456-7890
 * 123-456-7890
 * 123.456.7890
 * 1234567890
 * +31636363634
 * 075-63546725
 */
export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const nameRegExp = /^[a-zA-Z'-\sàáâäèéêëîïôùûü]*$/i;
export const vacNumberRegExp = /^(k|K)\d{7}$/i;
export const vacNumberErrorMessage = "VAC number must starts with letter K followed by 7 digits";

export const isTablet = (width: string): boolean => {
  return width === "xs" || width === "sm" || width === "md";
};

export const serverSideRequire = (name: string) => {
  // tslint:disable-next-line:no-eval
  return eval(`require("${name}")`);
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getBuildID = () => {
  if (useWindow && useWindow.__NEXT_DATA__) {
    return useWindow.__NEXT_DATA__.buildId;
  }
};

/**
 * Build option tree from a nested object
 * @param option
 * @param parent
 * @param level
 */
export const buildOptionTree = (option, parent, level) => {
  const childrenNodes = _(option)
    .omit("value", "label", "count")
    .map((val, key) => [key, val]);
  const nodes = childrenNodes.map(([key, val]) => {
    const node = {
      key,
      level,
      parent,
      children: [],
      ..._.pick(val, "value", "label", "count"),
    };
    // @ts-ignore-line
    node.children = buildOptionTree(val, node, level + 1);
    return node;
  });
  return nodes.value();
};

export const unFlattenOptions = (options: any[] = []) => {
  const r = {};
  _(options).each((c) => (r[c.value.split("/").join(".")] = c));
  const newOptions = unflatten(r);
  return buildOptionTree(newOptions, null, 0);
};

export function isValidName(value) {
  return /^[a-zA-Z'-\sàáâäèéêëîïôùûü]*$/i.test(_.trim(value));
}

export function isValidAlphabet(value) {
  return /^[a-zA-Z\s-]*$/i.test(value);
}

export function isDigitOnly(input) {
  return /^\d+$/.test(input);
}

export function isAlphabetAndNumber(input) {
  return /^[a-zA-Z\s0-9]*$/i.test(input);
}

export function isValidEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_.trim(email));
}

export function deepConvertNull2Undefined(obj) {
  return _.transform(obj, (result: any, value: any, key: any) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || _.isPlainObject(value)) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = deepConvertNull2Undefined(value);
    }

    if (value === null) {
      return undefined;
    }

    // Append when recursing arrays.
    if (Array.isArray(result)) {
      return result.push(value);
    }

    result[key] = value;
    return;
  });
}

/**
 * Tranfrom input obj to css style string
 * @param obj
 */
export const object2CssString = (obj) => {
  return _.transform(
    obj,
    (result: any, val: any, key: string) => {
      const newKey = key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
      // tslint:disable-next-line:no-parameter-reassignment
      result += `${newKey}: ${val};`;
    },
    ""
  );
};

/**
 * Remvove inejcted CSS style on load https://material-ui.com/guides/server-rendering/
 */
export const removeInjectedCSS = () => {
  // Remove the server-side injected CSS.
  if (!detectBot(navigator.userAgent)) {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  }
};

export const formatBirthday = (birthday: string): string => {
  return moment(birthday).format("MMM-DD-YYYY");
};

export const formatPrice = (price: number | undefined): string => {
  if (price) {
    return `$${price?.toFixed(2)}`
  }
  return ""
};

export const formatPhoneNumber = (phoneNumberString: string | undefined): string => {
  if (phoneNumberString) {
    let cleaned = phoneNumberString.replace(/\D/g, "");
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
  }
  return "";
};

export const isLeapYear = (year: number): boolean => {
  // feb is 29 days
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    return true;
  }
  return false;
};

export const getDDHandle = (year: number, month: number): number => {
  if (month === 2) {
    if (isLeapYear(year)) {
      return 29;
    }
    return 28;
  }
  if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
    return 31;
  }
  return 30;
};

export const checkout2GApayload = (checkout: Checkout) => {
  const adjustments = checkout.adjustments;
  const orders = checkout.orders;
  const tax_total = adjustments
    .filter((a) => a.source_type === "TaxRule")
    .map((i) => i.amount)
    .reduce((acc, cur) => acc + cur, 0);
  const shipping_total = adjustments
    .filter((a) => a.source_type === "ShippingMethods::ShippingMethod")
    .map((i) => i.amount)
    .reduce((acc, cur) => acc + cur, 0);
  const items = _.flatten(orders.map((o) => o.order_items)).map((oi: any) => ({
    id: oi.variant_id,
    name: oi.variant_name,
    brand: oi.producer_name,
    variant: oi.variant_combined_options,
    category: oi.first_category,
    quantity: oi.quantity,
    price: oi.price,
  }));
  return {
    transaction_id: _.get(checkout, "number"),
    value: _.get(checkout, "total"),
    tax: tax_total,
    shipping: shipping_total,
    items,
  };
};

export const dobObject2String = (dob: Dob): string => {
  return `${dob?.year}-${String(dob?.month).padStart(2, "0")}-${String(dob?.day).padStart(2, "0")}`;
};

export const dobString2Object = (birthday: string | string[]): Dob => {
  let birthdayArr = birthday || [];
  if (typeof birthday === "string") {
    birthdayArr = birthday.split("-");
  }

  return {
    year: parseInt(birthdayArr[0], 10) || null,
    month: parseInt(birthdayArr[1], 10) || null,
    day: parseInt(birthdayArr[2], 10) || null,
  };
};

export const isReqeustError = (error) => {
  return error?.response?.status < 500 && error?.response?.data?.message;
};

export const serverErrorCode = (error) => {
  return error?.response?.data?.errorCode;
};

export const isFormError = (error) => {
  const { data, status } = error.response || {};
  return status === 400 && data.data && typeof data.data === "object";
};

export const getFormErrors = (error) => {
  return error?.response?.data?.data || {};
};

export const requestErrorMessage = (error) => {
  if (error?.response?.status === 401) {
    return `Your session has expired, please log in.`;
  }

  return error?.response?.data?.message || `System error. please try again later.`;
};

export const requestErrorData = (error) => {
  return error?.response?.data?.data;
};

export const goToLogInOn401 = async (error) => {
  if (error?.response?.status === 401) {
    redirect({ location: ROUTE.LOGIN });
  }
  throw error;
};

export const alertRequestError = (options = {}) => {
  return async (err) => {
    const { excepts = [] } = options as any;
    if (!_.includes(excepts, err?.response?.status) && isReqeustError(err)) {
      cannalogueAlert.error(requestErrorMessage(err));
    }
    throw err;
  };
};

/**
 * Handle error when given status code met
 *
 * example:
 *  axiosRequest(...).catch(
 *    // catch 401
 *    onStatusCode(401, () => {
 *       // deal with form errors here
 *    })
 *  ).catch(
 *    // show error messsge if there is one
 *    alertRequestError()
 *  )
 *
 * @param handler
 */
export const onStatusCode = (code, handler) => {
  return async (err) => {
    if (err?.response?.status === code) {
      handler(err);
    }
    throw err;
  };
};

/**
 * Handle form error else re-throw the error;
 *
 * example:
 *  axiosRequest(...).catch(
 *    // catch form errors
 *    onFormError(errors => {
 *       // deal with form errors here
 *    })
 *  ).catch(
 *    // show error messsge if there is one
 *    alertRequestError()
 *  )
 *
 * @param handler
 */
export const onFormError = (handler: any) => {
  return async (err: { [key: string]: any }) => {
    if (isFormError(err)) {
      handler(getFormErrors(err));
    }
    throw err;
  };
};

/**
 * ON SSR, we want to use winstonLogger
 */
export const useWistonLoggerOnServer = () => {
  // @ts-ignore
  if (isServer && global.winstonLogger) {
    // @ts-ignore
    const logger = global.winstonLogger;
    // @ts-ignore
    global.nextLogger = {};
    // @ts-ignore
    global.nextLogger.info = ((message, ...data) => {
      logger.info(`${message} ${data}`);
    }).bind(logger);

    // @ts-ignore
    global.nextLogger.error = logger.error.bind(logger);
    // @ts-ignore
    global.nextLogger.warn = logger.warn.bind(logger);
  } else {
    if (!isServer) {
      // @ts-ignore
      window.nextLogger = console;
    } else {
      // @ts-ignore
      global.nextLogger = console;
    }
  }
};

// Print version: SEP-09-2020, used in list
// Human version: Sep 9, 2020
export const convertDate2Local: (date?: string, format?: "print" | "human") => string = (date, format = "print") => {
  if (date) {
    const dDate = new Date(date);
    if (format === "print") {
      const month = new Intl.DateTimeFormat(i18n.language, { month: "short" }).format(dDate);
      const day = new Intl.DateTimeFormat(i18n.language, { day: "numeric" }).format(dDate);
      const year = new Intl.DateTimeFormat(i18n.language, { year: "numeric" }).format(dDate);
      return `${month.toUpperCase()}-${`0${day}`.slice(-2)}-${year}`;
    }

    if (format === "human") {
      return new Intl.DateTimeFormat(i18n.language, { year: "numeric", month: "short", day: "numeric" }).format(dDate);
    }
  }
  return "";
};

export const getNumberOfOrderItems = (orders: (Order[] | undefined)): number => {
  if (!orders) { return 0; }
  return _.reduce(
    orders,
    (sum, o) => {
      return sum + _.reduce(o.order_items, (oiSum, oi) => oiSum + oi.quantity, 0);
    },
    0
  );
};

export const getInitial = (user) => {
  const initial = `${_.toUpper(user?.first_name?.substring(0, 1))}${_.toUpper(user?.last_name?.substring(0, 1))}`;
  return initial || null;
};

/**
 * Determine it is linking to the collections.
 * If it does, make it as client-side rendering.
 * to: /collections/[collection]?asd=asd      // javascript path with query
 * asPath: /collections/cannabis?asd=asd      // browser understandable path with query
 */
export const getRoute = (link?: string) => {
  if (link?.includes("/collections/")) {
    const queryParams = link.split("?");
    return { to: `${ROUTE.PRODUCTS_HREF}${queryParams.length > 1 ? `?${queryParams[1]}` : ""}`, asPath: link };
  }
  return { to: link };
};

/**
 *
 * @param errors
 */
export function getFormikFirstErrorKey(errors) {
  if (typeof errors === "string") {
    return "";
  }

  if (Array.isArray(errors)) {
    return getFormikFirstErrorKey(errors[0]);
  }

  if (typeof errors === "object") {
    const k = Object.keys(errors)[0];
    const v = errors[k];
    if (Array.isArray(v)) {
      return `${k}[0].${getFormikFirstErrorKey(v[0])}`;
    } else if (typeof v === "object") {
      return `${k}.${getFormikFirstErrorKey(v)}`;
    } else {
      return k;
    }
  }
}

export const findPageNameByPath = (path: string) => {
  const pathname = path.split("?")?.[0];
  let pageRoutes: any[] = [];
  if (isServer) {
    // @ts-ignore
    pageRoutes = global.pageRoutes;
  } else {
    // @ts-ignore
    pageRoutes = window.pageRoutes;
  }
  for (let page of pageRoutes) {
    let re = new RegExp(page.regex.slice(0, -2).substring(1), "i");
    if (re.test(pathname) && page.priority > 0) {
      return page.page;
    }
  }

  return undefined;
};

export const checkLocalStorage = () => {
  if (isServer) return;
  if (typeof localStorage === "object") {
    try {
      localStorage.setItem("localStorage", "test");
      localStorage.removeItem("localStorage");
    } catch (err) {
      Storage.prototype._setItem = Storage.prototype.setItem;
      Storage.prototype.setItem = () => null;
      window.alert("Your web browser does not support storing settings locally. In Safari, the most common cause of this is using \"Private Browsing Mode\". Some settings may not save or some features may not work properly for you.");
    }
  }
};

export function toSentence(arr?: string[]) {
  if (!arr) return "";
  return arr.reduce(
    (prev, curr, i) => {
      return prev + curr + ((i === arr.length - 2) ? " and " : ", ");
    }, "")
    .slice(0, -2);
}

export const addressToString = (address) => {
  if (_.isEmpty(address)) return "";
  return `${address.street}, ${address.city}, ${address.province}, ${address.country}, ${address.postal}`
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const colorWithOpacity = (hexColor: string, opacity: number) => {
  return `rgba(${hexToRgb(hexColor)?.r}, ${hexToRgb(hexColor)?.g}, ${hexToRgb(hexColor)?.b}, ${opacity})`
}
