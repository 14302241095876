import { Row, StyledParagraph } from "@components/general";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { color } from "@resources/styles";
import { registerSearchFilter } from "@utils/elasticsearch/config";
import { getCurrentESName, useMultiSelect } from "@utils/elasticsearch/hooks";
import { TermsFilterComponent } from "@utils/elasticsearch/types";
import _ from "lodash";
import React from "react";
import MultiSelectView from "./MultiSelectView";

let MultiSelectFilter: TermsFilterComponent<{ name: string }> = ({ id, name, ...config }) => {
  const { value, hide = false, options, updateValue } = useMultiSelect(getCurrentESName(), { id, ...config });

  if (hide) {
    return null;
  }

  let selectOptions = options;

  if (options && options.length === 0) {
    return null;
  }

return (
    <Row>
      <Row>
        <StyledParagraph align="left" color={color.black} size={17} lineHeight={20} variant="body2">
          {name}
        </StyledParagraph>
      </Row>
      {options ? (
        <MultiSelectView options={selectOptions} value={value as string[]} onChange={updateValue} />
      ) : (
        _.times(5).map((index) => (
          <Grid key={index} item xs={12}>
            <Skeleton variant="text" animation="wave" height={30} />
          </Grid>
        ))
      )}
    </Row>
  );
};

MultiSelectFilter = React.memo(MultiSelectFilter, _.isEqual);

MultiSelectFilter.manullyResigsterFilter = (esName, { id, ...config }) => {
  registerSearchFilter(esName, id, { ...config, type: "terms" });
};

export default MultiSelectFilter;
