import { getStore } from "@utils/elasticsearch/config";
import useWindow from "@utils/useWindow";
import produce from "immer";
import _ from "lodash";
import { Reducer } from "redux";
import { createAction, handleActions } from "redux-actions";

let timer: any = null;

enum AlertType {
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
}

export interface AlertDataProps {
  message: string;
  type: AlertType;
}

export const removeAlertAction: any = createAction("REMOVE_ALERT", async () => {
  return {};
});

const showAlertAction: any = createAction("SHOW_ALERT", async (message, type: AlertType) => {
  let convertedMessage = "";
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(() => {
    if (useWindow) {
      useWindow.reduxStore.dispatch(removeAlertAction());
    }
  }, 8000);

  if (typeof message === "string") {
    if (_.isEmpty(message)) {
      convertedMessage = _.capitalize(type);
    } else {
      convertedMessage = message;
    }
  } else if (Array.isArray(message)) {
    convertedMessage = message.join(", ");
  } else if (typeof message === "object") {
    convertedMessage = JSON.stringify(message);
  } else {
    convertedMessage = "Something is wrong. Please try again later";
  }
  return { message: convertedMessage, type };
});

export const cannalogueAlert = {
  info: (msg) => getStore().dispatch(showAlertAction(msg, AlertType.INFO)),
  success: (msg) => getStore().dispatch(showAlertAction(msg, AlertType.SUCCESS)),
  error: (msg) => getStore().dispatch(showAlertAction(msg, AlertType.ERROR)),
  clearAll: () => getStore().dispatch(removeAlertAction()),
};

const initialState = { message: "", type: AlertType.SUCCESS };

const reducer: Reducer<AlertDataProps> = handleActions(
  {
    [showAlertAction]: {
      FULFILLED: (state, { payload: { message, type } }) =>
        produce(state, (draft) => {
          draft.message = message;
          draft.type = type;
        }),
    },
    [removeAlertAction]: {
      FULFILLED: (state) => ({ ...state, message: "", type: AlertType.SUCCESS }),
    },
  },
  initialState
);

export default reducer;
