
import WalletConnect from "@walletconnect/client";
import axios, { AxiosInstance } from "axios";
import { Reducer } from "redux";
import { createAction, handleActions } from "redux-actions";
import { IAssetData, IGasPrices, IParsedTx } from "./types";
import _ from "lodash";
export interface CryptoProps {
  connector: WalletConnect | null;
  fetching: boolean;
  connected: boolean;
  chainId: number;
  showModal: boolean;
  pendingRequest: boolean;
  uri: string;
  accounts: string[];
  address: string;
  result: any | null;
  assets: IAssetData[];
}

const INITIAL_STATE: CryptoProps = {
  connector: null,
  fetching: false,
  connected: false,
  chainId: 1,
  showModal: false,
  pendingRequest: false,
  uri: "",
  accounts: [],
  address: "",
  result: null,
  assets: []
};

const api: AxiosInstance = axios.create({
  baseURL: "https://ethereum-api.xyz",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export async function apiGetAccountAssets(address: string, chainId: number): Promise<IAssetData[]> {
  const response = await api.get(`/account-assets?address=${address}&chainId=${chainId}`);
  const { result } = response.data;
  return result;
}

export async function apiGetAccountTransactions(
  address: string,
  chainId: number,
): Promise<IParsedTx[]> {
  const response = await api.get(`/account-transactions?address=${address}&chainId=${chainId}`);
  const { result } = response.data;
  return result;
}

export const apiGetAccountNonce = async (address: string, chainId: number): Promise<string> => {
  const response = await api.get(`/account-nonce?address=${address}&chainId=${chainId}`);
  const { result } = response.data;
  return result;
};

export const apiGetGasPrices = async (): Promise<IGasPrices> => {
  const response = await api.get(`/gas-prices`);
  const { result } = response.data;
  return result;
};

export const removeConnectorAction = createAction("REMOVE_CONNECTOR", async () => {
  return {};
});

export const resetCryptoAction = createAction("RESET_CRYPTO", async () => {
  return {};
});

export const updateCryptoAction = createAction("UPDATE_CRYPTO", async (payload) => {
  return { ...payload };
});

const reducer: Reducer = handleActions(
  {
    [updateCryptoAction]: {
      FULFILLED: (state, { payload }) => {
        return { ...state, ...payload };
      },
    },
    [resetCryptoAction]: {
      FULFILLED: () => {
        return { ...INITIAL_STATE };
      },
    },
    [removeConnectorAction]: {
      FULFILLED: (state, { payload }) => {
        return { ...state, ...payload };
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
