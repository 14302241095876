import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { WidthContext } from ".";

interface ILightboxStyleProps {
  show: boolean;
  height?: number;
  opacity?: number;
  offset?: number;
}

const SLightbox = styled.div<ILightboxStyleProps>`
  transition: opacity 0.1s ease-in-out;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: ${({ height }) => (height ? `${height}px` : "100vh")};
  margin-left: -50vw;
  top: ${({ offset }) => (offset ? `-${offset}px` : 0)};
  left: 50%;
  z-index: 101;
  will-change: opacity;
  background-color: ${({ opacity }) => {
    let alpha = 0.4;
    if (typeof opacity === "number") {
      alpha = opacity;
    }
    return `rgba(0, 0, 0, ${alpha})`;
  }};
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const SModalContainer = styled.div<{ mobile: boolean }>`
  position: relative;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  top:  ${({ mobile }) => (mobile ? "100px" : "250px")};
`;

const SHitbox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface ICloseButtonStyleProps {
  size: number;
  onClick?: any;
}

const SCloseButton = styled.div<ICloseButtonStyleProps>`
  transition: "all 0.1s ease-in-out";
  position: absolute;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  right: ${({ size }) => `${size / 1.6667}px`};
  top: ${({ size }) => `${size / 1.6667}px`};
  opacity: 0.5;
  cursor: pointer;
  z-index: 1;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    content: " ";
    height: ${({ size }) => `${size}px`};
    width: 2px;
    background: rgb(12, 12, 13);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const SCard = styled.div<{ mobile: boolean }>`
  position: relative;
  width: 600px;
  max-width: 100%;
  padding: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  max-height: calc(100vh - ${({ mobile }) => (mobile ? "120px" : "270px")}); // 100vh - top offset - 20px
`;

const SModalContent = styled.div`
  position: relative;
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

interface IModalProps {
  children: React.ReactNode;
  show: boolean;
  toggleModal: any;
  opacity?: number;
  closeOnBackDropClick?: boolean;
  destroyOnClose?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  children, destroyOnClose = false, show, toggleModal, opacity, closeOnBackDropClick = true
}) => {
  const [modalHeight, setModalHeight] = useState(0);
  const [offset, setOffset] = useState(0);
  const lightBoxRef = useRef<HTMLDivElement>(null);

  const width = useContext(WidthContext);
  const mobile = width === "xs" || width === "sm";

  const toggle = async () => {
    const d = typeof window !== "undefined" ? document : "";
    const body = d ? d.body || d.getElementsByTagName("body")[0] : "";
    if (body) {
      if (show) {
        body.style.position = "";
      } else {
        body.style.position = "fixed";
      }
    }
    toggleModal();
  };

  useEffect(() => {
    if (lightBoxRef.current) {
      const lightboxRect = lightBoxRef.current?.getBoundingClientRect();
      const _offset = lightboxRect.top > 0 ? lightboxRect.top : 0;
      setOffset(_offset);
      const d = typeof window !== "undefined" ? document : "";
      const body = d ? d.body || d.getElementsByTagName("body")[0] : undefined;
      if (body?.clientHeight) {
        setModalHeight(body.clientHeight);
      }
    }
  }, []);

  return (
    <SLightbox show={show} height={modalHeight} offset={offset} opacity={opacity} ref={lightBoxRef}>
      <SModalContainer mobile={mobile}>
        {closeOnBackDropClick && <SHitbox onClick={toggle} />}
        <SCard mobile={mobile}>
          <SCloseButton size={25} onClick={toggle} />
          <SModalContent>{destroyOnClose && !show ? null : children}</SModalContent>
        </SCard>
      </SModalContainer>
    </SLightbox>
  );
};

export default Modal;
