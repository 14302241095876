import * as ROUTE from "@resources/routeConst";

const config = {
  rootCategory: "Cannabis",
  collectionUrl: ROUTE.HEMP,
  elasticsearch: {
    name: "hemp",
    url: `/api/search/`,
    indices: ["products"],
    baseQuery: {
      bool: {
        should: [
          {
            prefix: {
              "categories.raw": "Cannabis"
            }
          }
        ]
      }
    },
    keywordSearch: true, // for display or not in keyword search
  },
  sortOptions: [
    { value: "-manual_weight", title: "sorter.sort_option.relevance" },
    { value: "price", title: "sorter.sort_option.price_low_high" },
    { value: "-price", title: "sorter.sort_option.price_high_low" },
    { value: "product_specs/cbg[number]", title: "CBD: Low to High" },
    { value: "-product_specs/cbg[number]", title: "CBD: High to Low" },
    { value: "product_specs/cbd[number]", title: "sorter.sort_option.cbd_low_high" },
    { value: "-product_specs/cbd[number]", title: "sorter.sort_option.cbd_high_low" },
    { value: "producer.name.raw", title: "Supplier: A-Z" },
    { value: "-producer.name.raw", title: "Supplier: Z-A" },
    { value: "name.raw", title: "sorter.sort_option.strain_az" },
    { value: "-name.raw", title: "sorter.sort_option.strain_za" },
  ],
  filterOptions: [
    {
      component: `FreeShippingFilter`,
    },
    {
      component: `InStockFilter`,
    },
    {
      component: `CategoryFilter`,
      prefix: "Cannabis",
      react: ["Supplier", "Price"]
    },
    {
      component: `SearchableMultipleSelectFilter`,
      id: "Supplier",
      name: "Supplier",
      showCount: true,
      enableSelectAll: true,
      size: 100,
      dataField: "producer.name.raw",
      react: ["Category", "Price"]
    },
    {
      component: `RangeSliderFilter`,
      id: "Price",
      name: "Price",
      dynamic: true,
      dataField: "price",
      textBox: {
        textAlign: "left",
        startAdornment: "$",
      },
      react: ["Category", "Supplier"]
    },
  ],
  // resultComponent: `ShopCannabisCard`
  resultComponent: {
    component: `SearchResultCard`,
    showIcon: false,
    showSpecs: [
      { key: "cbg", format: "CBG: ${value}" },
      { key: "cbd", format: "CBD: ${value}" }],
  }
};

export default config;
