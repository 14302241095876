import { StyledAutoComplete } from "@components/form";
import { Row, StyledParagraph } from "@components/general";
import { Grid, Paper } from "@material-ui/core";
import { color } from "@resources/styles";
import _ from "lodash";
import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import styled from "styled-components";

interface SuggestionFilterViewProps extends Omit<React.DOMAttributes<any>, "onChange">, Omit<React.InputHTMLAttributes<any>, "onChange"> {
  value: any;
  nameKey: string;
  titleKey: string;
  dataKey: string;
  onChange: (event: any, value: string) => void;
  getFetch: (inputValue: string) => any;
  onSelected: ({ value, selected }: { value: any; selected: any }) => Promise<void>;
}

const StyledAutosuggest = styled(({ className, ...others }) => (
  <Autosuggest theme={{ container: className, suggestionsContainer: "suggestion-container", suggestionsList: "suggestion-list" }} {...others} />
))`
  .suggestion-container {
    z-index: 1;
  }

  .suggestion-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const SuggestionFilterView: React.FC<SuggestionFilterViewProps> = ({
  value,
  nameKey,
  titleKey,
  dataKey,
  onChange,
  getFetch,
  onSelected,
  ...extra
}) => {
  const [suggestions, setSuggestions] = useState<any>([]);
  const anchor = React.useRef<HTMLDivElement>();

  const handleChange = (event, { newValue }) => {
    onChange(event, newValue);
  };

  const clearSuggestion = () => {
    setSuggestions([]);
  };

  const getSuggestion = async ({ value: v }) => {
    setSuggestions([]);
    if (v) {
      const data = await getFetch(v.toLowerCase());
      setSuggestions(data);
    }
  };

  const handleSubmit = (event?, data?) => {
    const submitValue = data ? data.suggestionValue : value;
    let selected = data ? data.suggestion : null;
    if (!data) {
      suggestions.forEach((section) => {
        _.get(section, dataKey).forEach((d) => {
          if (_.get(d, nameKey) === value) {
            selected = d;
          }
        });
      });
    }
    onSelected({ value: submitValue, selected });
    setSuggestions([]);
  };

  const getAttachmentLocation = (type: "top" | "width") => {
    if (type === "top") {
      const top = anchor && anchor.current && anchor.current.offsetTop + anchor.current.getBoundingClientRect().height;
      return top;
    }
    const width = anchor && anchor.current && anchor.current.clientWidth + 1.5;
    return width || 0;
  };

  return (
    <StyledAutosuggest
      multiSection={true}
      suggestions={suggestions}
      onSuggestionsFetchRequested={getSuggestion}
      onSuggestionsClearRequested={clearSuggestion}
      onSuggestionSelected={handleSubmit}
      focusInputOnSuggestionClick={false}
      getSuggestion={(suggestion) => suggestion}
      getSuggestionValue={(suggestion: any) => _.get(suggestion, nameKey)}
      renderSuggestion={(suggestion: any, { query, isHighlighted }) => {
        const background: any = () => {
          if (isHighlighted) {
            return color.blueOpcity(0.4);
          }
          if (suggestion.name === value) {
            return color.blueOpcity(0.6);
          }
          return "unset";
        };
        return (
          <Row style={{ padding: "15px", backgroundColor: background() }}>
            <Grid item xs={12}>
              <StyledParagraph align="left" guideline="p3" color={color.black}>
                {_.get(suggestion, nameKey)}
              </StyledParagraph>
            </Grid>
          </Row>
        );
      }}
      renderSectionTitle={(section) => {
        return (
          <Row style={{ padding: "5px 0 5px 15px", backgroundColor: color.greyBorderOpacity(0.2) }}>
            <Grid item xs={12}>
              <StyledParagraph align="left" guideline="p3" color={color.black}>
                <span className="bold">
                  {_.capitalize(_.get(section, titleKey))} ({_.get(section, dataKey, []).length})
                </span>
              </StyledParagraph>
            </Grid>
          </Row>
        );
      }}
      getSectionSuggestions={(section) => _.get(section, dataKey)}
      renderInputComponent={({ ref, ...props }: any) => (
        <StyledAutoComplete
          {...props}
          onChange={(e) => props.onChange(e, { newValue: e.target.value, method: "type" })}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSubmit();
            } else if (props.onKeyDown) {
              props.onKeyDown(e);
            }
          }}
          onBlur={(e) => props.onBlur && props.onBlur(e)}
          showSearchIcon={!value}
          value={props.value}
          fullWidth
          divRef={anchor}
          inputRef={ref}
          aria-label={props.placeholder}
        />
      )}
      renderSuggestionsContainer={(props) => (
        <Paper
          elevation={2}
          variant="outlined"
          style={{
            display: props.children ? "block" : "none",
            position: "absolute",
            top: getAttachmentLocation("top"),
            width: getAttachmentLocation("width"),
            maxHeight: "40vh",
            overflow: "auto",
            border: `2px solid ${color.blue}`,
            boxShadow: `0px 6px 5px 6px ${color.blueOpcity(0.5)}`,
            zIndex: 14,
          }}
          {...props.containerProps}
        >
          {props.children}
        </Paper>
      )}
      inputProps={{
        ...extra,
        value,
        type: "search",
        id: "Search",
        onChange: handleChange,
        autoComplete: "off",
      }}
    />
  );
};

export default SuggestionFilterView;
