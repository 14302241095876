import { StyledParagraph } from "@components/general";
import useFormField from "@hooks/useFormField";
import { Collapse, FormHelperText, Grid, MenuItem } from "@material-ui/core";
import { color } from "@resources/styles";
import { getFormikFirstErrorKey } from "@utils/utils";
import React, { useEffect, useRef } from "react";
import StyledOutlinedFormControl from "./StyledOutlinedFormControl";
import StyledSelect from "./StyledSelect";

const FormikSelectField: React.FC<any> = ({ label, placeholder, name, disabled, optional, debounce = true, children, ...custom }) => {
  const { field, isSubmitting, submitCount, hasError, error, errors } = useFormField({ name, debounce });
  const defaultValue = " ";

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hasError && submitCount > 0 && getFormikFirstErrorKey(errors) === name) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  return (
    <StyledOutlinedFormControl disabled={isSubmitting || disabled}>
      <Grid container ref={ref}>
        {label && (
          <div style={{ padding: "10px 0", width: "100%", display: "flex" }}>
            <StyledParagraph
              align="left"
              style={{ fontWeight: 600 }}
              lineHeight={20}
              size={17}
              color={color.black}
              component="label"
              textEllipsis={false}
              variant="body1"
            >
              <label htmlFor={name}>{label}</label>
            </StyledParagraph>
            {optional && (
              <StyledParagraph
                style={{ marginLeft: 5 }}
                align="left"
                guideline="p4"
                color={color.black}
                textEllipsis={false}
                variant="caption"
                dangerouslySetInnerHTML={{ __html: optional }}
              />
            )}
          </div>
        )}
      </Grid>

      <StyledSelect
        style={{ color: field.value ? color.black : color.greyDarker }}
        error={hasError}
        {...field}
        {...custom}
        value={field.value ? field.value : defaultValue}
      >
        <MenuItem key={"default"} disabled={true} value={defaultValue}>
          {placeholder}
        </MenuItem>
        {children && children}
      </StyledSelect>
      <Collapse in={hasError} className="invalid-feedback">
        <FormHelperText error={hasError}>{error}</FormHelperText>
      </Collapse>
    </StyledOutlinedFormControl>
  );
};

export default FormikSelectField;
