import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import color from "@resources/styles/color";
import { contrast } from "@utils/colorContrast";
import { colorWithOpacity } from "@utils/utils";
import _ from "lodash";
import Link from "next/link";
import React from "react";

interface PrimaryButtonProps {
  secondary?: boolean;
  to?: string;
  asPath?: string;
  loading?: boolean;
  dangerous?: boolean;
}

const useStyles = makeStyles({
  primary: (props: any) => ({
    border: `1px solid ${color.primary}`,
    color: contrast(color.white, color.primary) > contrast(color.black, color.primary) ? color.white : color.black,
    borderRadius: "4px",
    backgroundColor: color.primary,
    ...(props.size !== "small" ? { padding: "13px 18px" } : { padding: "4px 18px" }),
    letterSpacing: "0.8px",
    boxShadow: "0 2px 4px 0 rgba(82, 82, 83, 0.3)",
    maxWidth: "100%",
    textTransform: "none",
    "&:hover": {
      backgroundColor: color.primaryDarker,
      boxShadow: "0 2px 4px 0 rgba(82, 82, 83, 0.3)",
    },
  }),
  secondary: (props: any) => ({
    border: `1px solid ${color.blue}`,
    color: color.blue,
    borderRadius: "4px",
    backgroundColor: color.realWhite,
    ...(props.size !== "small" ? { padding: "13px 18px" } : { padding: "4px 18px" }),
    letterSpacing: "0.8px",
    boxShadow: "none",
    maxWidth: "100%",
    textTransform: "none",
    "&:hover": {
      backgroundColor: color.secondaryOpacity(0.5),
    },
  }),
  dangerous: (props: any) => ({
    border: `1px solid ${color.red}`,
    color: color.red,
    borderRadius: "4px",
    backgroundColor: color.realWhite,
    ...(props.size !== "small" ? { padding: "13px 18px" } : { padding: "4px 18px" }),
    letterSpacing: "0.8px",
    boxShadow: "none",
    maxWidth: "100%",
    textTransform: "none",
    "&:hover": {
      backgroundColor: colorWithOpacity(color.red, 0.5),
    },
  }),
});

const PrimaryButton: React.FC<ButtonProps<React.ElementType, PrimaryButtonProps>> = ({
  secondary = false,
  to,
  asPath,
  disabled,
  loading = false,
  children = "",
  dangerous = false,
  ...props
}) => {
  const classes = useStyles(props);
  let buttonClass = classes.primary;
  if (secondary) buttonClass = classes.secondary;
  if (dangerous) buttonClass = classes.dangerous;
  return (
    <>
      {to ? (
        to.match(/^http/g) ? (
          <Button href={to} disabled={disabled || loading} variant="outlined" component="a" className={buttonClass} {...props}>
            {loading && <CircularProgress size={16} style={{ marginRight: 5 }} />}
            {children}
          </Button>
        ) : (
          <Link href={to} as={asPath}>
            <Button href={asPath ? asPath : to} disabled={disabled || loading} variant="outlined" component="a" className={buttonClass} {...props}>
              {loading && <CircularProgress size={16} style={{ marginRight: 5 }} />}
              {children}
            </Button>
          </Link>
        )
      ) : (
        <Button disabled={disabled || loading} variant="outlined" className={buttonClass} {...props}>
          {loading && <CircularProgress size={16} style={{ marginRight: 5 }} />}
          {children}
        </Button>
      )}
    </>
  );
};

export default React.memo(PrimaryButton, _.isEqual);
