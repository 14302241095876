import searchConfig from "@config/ui/products-search/index";
import { StoreProps } from "@reducers/store";
import * as ROUTE from "@resources/routeConst";
import {
  clearFiltersExceptKeywordAction,
  clearOneFilterAction,
  getESState,
  loadFilterAction,
  loadSearchResultAction,
  loadSuggestion,
  reloadFiltersThatReactsToThis,
  setPageAction,
  subscribeFilterValueChangesAndUpdateUrl,
  unSubscribeFilterValueChanges,
  updateFitlerValueAction
} from "@utils/elasticsearch/reducer";
import usePrevious from "@utils/usePrevious";
import useWindow from "@utils/useWindow";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedValue } from "../../hooks/useDebouncedFunction";
import { getESConfigs, getSearchFilters, registerElasticSearch, registerSearchFilter, unRegisterSearchFilter } from "./config";
import { RangeFilterState, SearchFilterState, SortFilterState, TermFilterState, TermsFilterState } from "./types";

interface ElasticSearchConfig {
  name: string;
  url: string;
  indices: string[];
  baseQuery?: object;
  keywordSearch: boolean;
}

let currentESName: string | null = null;

export const getCurrentESName = () => {
  return currentESName;
};

export const getCurrentOrAllESName = (forceAll = false) => {
  if (forceAll) {
    const es = getESConfigs();
    return Object.keys(es).map((e) => e);
  }
  if (useWindow) {
    const es = getESConfigs();
    return Object.keys(es).map((e) => e);
  }
  return [currentESName];
};

export const getKeywordSearchESNames = () => {
  const es = getESConfigs();
  return Object.keys(es).filter((esName) => es[esName].keywordSearch).map(key => key);
};

export const useElasticSearch = (config: ElasticSearchConfig) => {
  registerElasticSearch(config.name, config);
  currentESName = config.name;
};

export const useMultiElasticSearch = (configs: ElasticSearchConfig[]) => {
  configs.forEach((config) => {
    registerElasticSearch(config.name, config);
    // currentESName = config.name;
  });
};

export const useSingleSelect = (esName, { id, ...opts }) => {
  const { value, hide, options } = useSelector((state: any) => getESState(state, esName).filters[id] || {}, _.isEqual) as TermFilterState;
  const dispatch = useDispatch();

  // register filter on componentDidMount
  useEffect(() => {
    if (opts.value) {
      // set default value
      updateValue(opts.value);
    }
    registerSearchFilter(esName, id, { ...opts, type: "term" });
    return () => { unRegisterSearchFilter(esName, id); };
  }, []);

  // load filer options on componentDidMount
  useEffect(() => {
    dispatch(loadFilterAction(esName, id));
  }, []);

  const updateValue = async (val) => {
    // update filter value
    await dispatch(updateFitlerValueAction(esName, id, val));
    // reload search results
    dispatch(loadSearchResultAction(esName, { resetPage: true }));
    // reload filers that reacts to current one's change
    reloadFiltersThatReactsToThis(esName, id);
  };

  return {
    value,
    hide,
    options: options && options.map((option) => ({ ...option, label: opts.showCount ? `${option.label} (${option.count})` : option.label })),
    updateValue,
  };
};

export const useMultiSelect = (esName, { id, ...opts }) => {
  const { value, hide, options } = useSelector((state: any) => getESState(state, esName).filters[id] || {}, _.isEqual) as TermsFilterState;
  const { enableSelectAll } = opts;
  const dispatch = useDispatch();

  // register filter on componentDidMount
  useEffect(() => {
    registerSearchFilter(esName, id, { ...opts, type: "terms" });
    return () => { unRegisterSearchFilter(esName, id); };
  }, []);

  // load filer options on componentDidMount
  useEffect(() => {
    dispatch(loadFilterAction(esName, id));
  }, []);

  const updateValue = async (val) => {
    // update filter value
    await dispatch(updateFitlerValueAction(esName, id, val));
    // reload search results
    dispatch(loadSearchResultAction(esName, { resetPage: true }));
    // reload filers that reacts to current one's change
    reloadFiltersThatReactsToThis(esName, id);
  };

  return {
    value,
    hide,
    options: options && options.map((option) => ({ ...option, label: opts.showCount ? `${option.label} (${option.count})` : option.label })),
    updateValue,
  };
};

export const useRangeSelect = (esName, { id, ...opts }) => {
  const { value, hide, options } = useSelector((state: any) => getESState(state, esName).filters[id] || {}, _.isEqual) as RangeFilterState;
  const { dynamic } = opts;
  const dispatch = useDispatch();

  const handleDebouncedValueChange = async (val) => {
    await dispatch(updateFitlerValueAction(esName, id, val));
    dispatch(loadSearchResultAction(esName, { resetPage: true }));
    reloadFiltersThatReactsToThis(esName, id);
  };

  const [debouncedValue, debouncedSetter] = useDebouncedValue(value, handleDebouncedValueChange, 500);

  // register filter on componentDidMount
  useEffect(() => {
    registerSearchFilter(esName, id, { ...opts, type: "range" });
    return () => { unRegisterSearchFilter(esName, id); };
  }, []);

  // load dynamic range
  useEffect(() => {
    if (dynamic) { dispatch(loadFilterAction(esName, id)); }
  }, []);

  return {
    hide,
    value: debouncedValue,
    options,
    updateValue: debouncedSetter,
  };
};

export const useSearchResult = (esName) => {
  const { data, total, loading } = useSelector((state: StoreProps) => getESState(state, esName).result || {});
  const { current, size } = useSelector((state: StoreProps) => getESState(state, esName).pagination || {});
  const dispatch = useDispatch();
  const hasMore = current * size < total;
  const address = useSelector((store: StoreProps) => store.address);

  useEffect(() => {
    subscribeFilterValueChangesAndUpdateUrl(esName);
    return () => unSubscribeFilterValueChanges(esName);
  }, []);

  useEffect(() => {
    reloadSearchResult();
  }, [address])

  const reloadSearchResult = async () => {
    await dispatch(
      loadSearchResultAction(esName, {
        resetPage: true
      }
      ));
    _(getSearchFilters(esName)).each((val, key) => {
      dispatch(loadFilterAction(esName, key));
    });
  }

  const setPage = async (pageNum: number) => {
    await dispatch(setPageAction(esName, pageNum));
    await dispatch(loadSearchResultAction(esName, { resetPage: false, append: true }));
  };

  const nextPage = (event) => {
    setPage(current + 1);
  };

  return {
    data,
    total,
    loading,
    current,
    size,
    setPage,
    nextPage,
    hasMore,
    reload: reloadSearchResult
  };
};

export const useKeywordSearch = (esNameArray, { id, ...opts }) => {
  const type = "search";
  const [value, setValue] = useState("");
  const { push } = useRouter();
  const state = useSelector((s: any) => s);
  const prevValue = usePrevious(value);
  const dispatch = useDispatch();
  const updateValue = async (event, val) => {
    setValue(val);
    const async_arr = getCurrentOrAllESName(true).map(async (esName) => await dispatch(updateFitlerValueAction(esName, id, val)));
    await Promise.all(async_arr);
  };

  useEffect(() => {
    esNameArray.forEach((esName) => {
      registerSearchFilter(esName, id, { ...opts, type });
    });

    esNameArray.forEach((esName) => {
      const { value: stateValue } = getESState(state, esName).filters[id] as SearchFilterState || {};
      if (stateValue) { updateValue(null, stateValue); }
    });

    return () => {
      esNameArray.forEach((esName) => {
        unRegisterSearchFilter(esName, id);
      });
    };
  }, []);

  useEffect(() => {
    if (prevValue !== undefined) {
      esNameArray.forEach((esName) => {
        const { value: stateValue } = getESState(state, esName).filters[id] as SearchFilterState || {};
        if (stateValue !== value) { setValue(""); }
      });
    }

  }, [state.elasticsearch]);

  useEffect(() => {
    if (prevValue !== undefined && value === "" && !_.isEqual(prevValue, value)) {
      esNameArray.forEach((esName) => {
        reloadFiltersThatReactsToThis(esName, id);
        dispatch(loadSearchResultAction(esName, { resetPage: true }));
      });
    }
  }, [value]);

  const submitValue = async ({ value: val, selected }) => {
    await updateValue(null, val);
    esNameArray.forEach((esName) => {
      reloadFiltersThatReactsToThis(esName, id);
      dispatch(loadSearchResultAction(esName, { resetPage: true }));
    });
    if (selected) {
      // @ts-ignore
      const categoryConfig = searchConfig.filter(config => {
        if (selected?.categories?.[0]) {
          return selected.categories[0].startsWith(config.rootCategory);
        }
        return false;
      });
      if (!_.isEmpty(categoryConfig)) {
        const collection = categoryConfig[0].collectionUrl;
        if (collection === window.location.pathname) {
          let search = window.location.search;
          if (search) {
            search = `${search}&Keyword=${val}`
          } else {
            search = `?Keyword=${val}`;
          }
          window.history.replaceState({}, "", `${window.location.pathname}${search}`);
        } else {
          push(`${ROUTE.PRODUCTS_HREF}?Keyword=${val}`, `${collection}?Keyword=${val}`);
        }
      }
    }
  };

  const getSuggestion = async (val) => {
    const async_arr = esNameArray.map(async (esName) => await loadSuggestion(esName, id));
    const data = await Promise.all(async_arr);
    const strucutured = esNameArray.map((esName, index) => ({
      title: esName,
      data: data[index]
    }));
    return strucutured;
  };

  return {
    value: value || "",
    updateValue,
    submitValue,
    getSuggestion
  };
};

export const useSort = (esName, { ...opts }) => {
  const id = "Sort";
  const { value } = useSelector((state: any) => getESState(state, esName).filters[id] || {}, _.isEqual) as SortFilterState;
  const dispatch = useDispatch();

  useEffect(() => {
    registerSearchFilter(esName, id, { ...opts, type: "sort" });
  }, []);

  const updateValue = async (val) => {
    if (!_.isEqual(val, opts.defaultValue)) {
      // update filter value
      await dispatch(updateFitlerValueAction(esName, id, val));
    } else {
      dispatch(clearOneFilterAction(esName, id));
    }
    // reload search results
    dispatch(loadSearchResultAction(esName, { resetPage: true }));
  };

  return {
    value: value || opts.defaultValue,
    options: opts.sortOptions,
    updateValue
  };
};
