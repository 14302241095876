import { CircleButton, Row, WidthContext } from "@components/general";
import { Grid } from "@material-ui/core";
import IconArrowBack from "@resources/images/IconArrowBack.svg";
import IconArrowForward from "@resources/images/IconArrowForward.svg";
import { color } from "@resources/styles";
import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
export interface CardCarouselProps<T> {
  data: T[];
  cardComponent: ForwardRefExoticComponent<PropsWithoutRef<{ data: T, loading?: boolean }> & RefAttributes<HTMLDivElement>>;
  children?: any;
  loading?: boolean;
}

const MobileSwipable = styled.div<any>`
  width: 100%;
  display: flex;
  margin-top: 30px;
  flex-wrap: "nowrap";
  padding-bottom: 50px;
`;

const StyledPaper = styled.div<any>`
  padding: 16px;
  max-width: 260px;
  min-width: 245px;
  border-radius: 9.97px;
  margin: 10px;
  background-color: ${color.realWhite};
  position: relative;
  display: flex;
  justify-content: center;
  transition: 200ms ease-out;
  cursor: pointer;
  box-shadow: ${(props) => (!props.$mobile && props.$selected ? "0 8px 16px 0 rgba(0,0,0,0.2)" : "0 4px 8px 0 rgba(0,0,0,0.2)")};
`;

const CardCarousel = <T extends any>({ data = [], cardComponent: CardComponent, children, loading = false }: CardCarouselProps<T>) => {
  const width = useContext(WidthContext);
  const mobile = width === "xs";

  const carouselRef = useRef<HTMLDivElement>(null);
  const cardComponentRef = useRef<HTMLDivElement>(null);

  const [forcusedCardIndex, setFocusedCardIndex] = useState(-1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const [translateX, setTranslateX] = useState<number>(0);

  useEffect(() => {
    setFocusedCardIndex(0);
  }, [width]);

  const carouselWidth = carouselRef?.current?.clientWidth || 0;
  // default card width to 265 to fix pagination not display issue
  const cardComponentWidth = cardComponentRef?.current?.parentElement?.clientWidth || 265;
  const cardSlideWidth = cardComponentWidth + 20; // card padding left and right are 10 each
  // number of items in one page, add 10px to ignore the last item right margin
  const stepSize = Math.floor((carouselWidth + 10) / cardSlideWidth);

  const hasPreviousPage = () => {
    return currentSlideIndex > 0;
  };

  const hasNextPage = () => {
    return currentSlideIndex < (data.length / stepSize - 1);
  };

  const goBack = () => {
    if (hasPreviousPage()) {
      const transformDistance = - stepSize * (currentSlideIndex - 1) * cardSlideWidth;
      setTranslateX(transformDistance);
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const goNext = () => {
    if (hasNextPage()) {
      const transformDistance = - stepSize * (currentSlideIndex + 1) * cardSlideWidth;
      setTranslateX(transformDistance);
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const resetPage = () => {
    setCurrentSlideIndex(0);
    setFocusedCardIndex(-1);
    setTranslateX(0);
  }

  useEffect(() => {
    resetPage();
  }, [data])

  return (
    <Grid container style={{ overflow: mobile ? "scroll" : "hidden" }}>
      {mobile ? (
        <MobileSwipable ref={carouselRef}>
          {data.map((cardData, index) => (
            <StyledPaper
              key={index}
              style={{ width: "100%" }}
              $mobile={mobile}
              $selected={index === forcusedCardIndex}
              onMouseOver={() => setFocusedCardIndex(index)}
              onMouseLeave={() => setFocusedCardIndex(-1)}
            >
              <CardComponent data={cardData} loading={loading} />
            </StyledPaper>
          ))}
        </MobileSwipable>
      ) : (
        <div style={{ width: "100%", overflow: "hidden" }} ref={carouselRef}>
          <div
            style={{ display: "flex", transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s", transform: `translateX(${translateX}px)` }}
          >
            {
              loading ? _.times(stepSize, index => <StyledPaper
                key={index}
                style={{ marginBottom: 20 }}
              >
                <CardComponent ref={cardComponentRef} data={data[0]} loading={loading} />
              </StyledPaper>)
                :
                data.map((cardData, index) => (
                  <StyledPaper
                    key={index}
                    style={{ marginBottom: 20 }}
                    $mobile={mobile}
                    $selected={index === forcusedCardIndex}
                    onMouseOver={() => setFocusedCardIndex(index)}
                    onMouseLeave={() => setFocusedCardIndex(-1)}
                  >
                    <CardComponent ref={cardComponentRef} data={cardData} loading={loading} />
                  </StyledPaper>
                ))
            }
          </div>

          <Row style={{ paddingLeft: 15 }} justify={children ? "space-between" : "center"} alignItems="center">
            {
              (hasPreviousPage() || hasNextPage()) && (
                <Grid item>
                  <CircleButton
                    style={{ marginRight: 10 }}
                    disabled={!hasPreviousPage()}
                    onClick={() => {
                      goBack();
                    }}
                    aria-label="previous product"
                  >
                    <IconArrowBack style={{ height: 15, width: 15 }} />
                  </CircleButton>
                  <CircleButton
                    disabled={!hasNextPage()}
                    onClick={() => {
                      goNext();
                    }}
                    aria-label="next product"
                  >
                    <IconArrowForward style={{ height: 15, width: 15 }} />
                  </CircleButton>
                </Grid>
              )
            }
            {children && (
              <Grid item style={{ marginLeft: 10 }}>
                {children}
              </Grid>
            )}
          </Row>
        </div>
      )}
    </Grid>
  );
};

export default CardCarousel;
