import { SuggestionFilter } from "@components/filter";
import { CheckoutPopover, GridLayout, PrimaryButton, StyledParagraph } from "@components/general";
import Logo from "@components/general/Logo";
import AddressSelector from "@components/address-selector/index";
import config from "@config/index";
import { Grid } from "@material-ui/core";
import { StoreProps } from "@reducers/store";
import IconShoppingBag from "@resources/images/IconShoppingBag.svg";
import * as ROUTE from "@resources/routeConst";
import { color } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import { formatPrice } from "@utils/utils";
import React from "react";
import { useSelector } from "react-redux";
import UserDropdown from "./UserDropdown";
import WideScreenMenu from "./WideScreenMenu";

const WideScreenHeader: React.FC<any> = () => {
  const checkout = useSelector((state: StoreProps) => state.checkout);
  const user = useSelector((state: StoreProps) => state.user);
  const item_total = checkout.item_total || 0;
  const total_grams_in_cart = checkout.total_medical_grams || 0;
  const used_quantity = user?.prescription?.current_period?.used_quantity || 0;
  const period_total = user.prescription?.current_period?.total || 0;
  const { tOrNull: t } = useTranslation("components/navigation");

  return (
    <div style={{ boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.1)" }}>
      <GridLayout outterStyle={{ border: `1px solid ${color.grey}` }}>
        <Grid style={{ flexWrap: "nowrap", paddingTop: 40, paddingBottom: 20 }} alignItems="center" container justify="space-between" spacing={4}>
          <Grid item style={{ marginTop: -20 }}>
            <Logo />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Grid container spacing={3} justify="flex-start" style={{ flexWrap: "nowrap" }}>
              {
                config.drup && <Grid item>
                  <AddressSelector />
                </Grid>
              }

              <Grid item style={{ flexGrow: 1 }}>
                <SuggestionFilter
                  id="Keyword"
                  name="Keyword"
                  placeholder={t("suggestion_input_placeholder")}
                  dataField={["name", "producer.name", "categories", "cannabis_type", "spec.brand", "supplier.name"]}
                  fieldWeights={[3, 2, 1, 1, 1, 1]}
                  size={20}
                />
              </Grid>
              {user.first_name ? (
                <Grid item>
                  <UserDropdown />
                </Grid>
              ) : (
                <Grid item>
                  <PrimaryButton to={ROUTE.LOGIN} loading={user.loading} style={{ padding: "14.5px 65px", whiteSpace: "nowrap" }}>
                    {t("login_register")}
                  </PrimaryButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid style={{ flexWrap: "nowrap" }} container justify="flex-end" alignItems="center" spacing={3}>
              {user.first_name ? (
                <>
                  <Grid item>
                    <CheckoutPopover />
                  </Grid>
                  {config.medicalFlow && (
                    <Grid item>
                      <Grid container style={{ paddingRight: 0, minWidth: 60, maxWidth: 80 }} direction="column">
                        <Grid item>
                          <StyledParagraph textEllipsis align="right" size={19} color={color.black}>
                            {formatPrice(item_total)}
                          </StyledParagraph>
                        </Grid>
                        <Grid item>
                          <StyledParagraph textEllipsis align="right" size={15} color={color.black}>
                            {used_quantity + total_grams_in_cart}/{period_total}g
                          </StyledParagraph>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid style={{ textAlign: "center" }} item>
                  <IconShoppingBag />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </GridLayout>
      <WideScreenMenu />
    </div>
  );
};

export default WideScreenHeader;
