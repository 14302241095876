import _ from "lodash";
import pathToRegexp from "path-to-regexp";
import { useEffect, useMemo, useState } from "react";
import uniqid from "uniqid";

const actionSubsribers: any = {};
const urlPatthenSubscribers: any = {};

function isConfigMatchUrlPattern(config, pattern) {
  let [requestUrl, ..._rest] = config.url.split("?");
  let [method, url] = pattern.split(" ");
  if (!url) {
    url = method;
    method = null;
  }

  let urlRegex = pathToRegexp(url);
  if (!method) {
    return urlRegex.test(requestUrl);
  } else {
    let _method = method.toLowerCase();
    return _method.indexOf(config.method.toLowerCase()) !== -1 && urlRegex.test(requestUrl);
  }
}

export function smartErrorReduxPlugin(store: any) {
  return (next: any) => (action: any) => {
    // Object.keys(actionSubsribers).forEach((key) => {
    //   if (actionSubsribers[key] && typeof actionSubsribers[key] === "function") {
    //     actionSubsribers[key](action);
    //   }
    // });
    next(action);
  };
}

export function smartErrorAxiosRequestPlugin(config) {
  Object.keys(urlPatthenSubscribers).forEach((key) => {
    if (urlPatthenSubscribers[key]) {
      let shouldCall = urlPatthenSubscribers[key].urlPatterns.some((pattern) => {
        /**
         * if pattern is a function, then run the function
         */
        if (typeof pattern === "string") {
          return isConfigMatchUrlPattern(config, pattern);
          // return pattern(config.url, config.method, config.data);
        }
        return false;
      });

      if (shouldCall) {
        urlPatthenSubscribers[key].handler(true, config.url);
      }
    }
  });
  return config;
}

export function smartErrorAxiosErrorPlugin(error) {
  Object.keys(urlPatthenSubscribers).forEach((key) => {
    if (urlPatthenSubscribers[key]) {
      let shouldCall = urlPatthenSubscribers[key].urlPatterns.some((pattern) => {
        /**
         * if pattern is a function, then run the function
         */
        if (typeof pattern === "string") {
          return isConfigMatchUrlPattern(error.config, pattern);
        }
        return false;
      });

      if (shouldCall) {
        urlPatthenSubscribers[key].handler(false, error);
      }
    }
  });
  return Promise.reject(error);
}

export const useSmartError = (captureOnActions: string[] = []): any => {
  const errorHookId = useMemo<string>(() => uniqid("smart_errors_"), []);
  const [error, setError] = useState<any>();

  const handleUrlPatternMatching = (started, res) => {
    if (started) {
      setError(null);
    } else {
      setError(res);
    }
  };

  useEffect(() => {
    urlPatthenSubscribers[errorHookId] = {
      handler: handleUrlPatternMatching,
      urlPatterns: captureOnActions,
    };
    return () => {
      delete actionSubsribers[errorHookId];
      delete urlPatthenSubscribers[errorHookId];
    };
  }, []);

  return error;
};
