import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { color } from "@resources/styles";
import isServer from "@utils/isServer";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import WidthContext from "./WidthContext";

const StyledFigure = styled.figure<any>`
  img {
    ${(props) =>
      props.onMouseMove &&
      `
      &: hover {
      opacity: 0;
      cursor: crosshair;
    }`};
    object-fit: contain;
    width: 100%;
    min-height: 375px;
  }
  margin: 0 auto;
  display: block;
  display: flex;
  width: 100%;
  max-height: 375px;
  justify-content: center;
  background-repeat: no-repeat;
`;

interface ZoomableImgProps {
  imgUrl: string;
  largeImgUrl?: string;
  alt?: string;
  style?: any;
}

const ZoomableImg: React.FC<ZoomableImgProps> = ({ imgUrl, largeImgUrl, alt, style = {} }) => {
  const width = useContext(WidthContext);
  const mobile = ["xs", "sm"].includes(width);
  const [img, setImg] = useState(isServer ? null : new Image());
  const [imageReady, setImageReady] = useState(false);
  const [status, setStatus] = useState({
    backgroundPosition: "0% 0%",
    backgroundImage: "none",
  });

  useEffect(() => {
    if (!isServer && img) {
      img.src = imgUrl;
      img.onload = () => setImageReady(true);
    }
    setImageReady(true);
    return () => {
      if (!isServer && img) {
        img.onload = null;
      }
    };
  }, [imgUrl]);

  const handleMouseMove = (event) => {
    const { left, top, width: w, height } = event.target.getBoundingClientRect();
    const offsetHeight = window.pageYOffset || document.documentElement.scrollTop;
    const x = ((event.pageX - left) / w) * 100;
    const y = ((event.pageY - (top + offsetHeight)) / height) * 100;
    setStatus({ backgroundPosition: `${x}% ${y}%`, backgroundImage: `url(${largeImgUrl || imgUrl})` });
  };

  const handleMouseLeave = (_) => {
    setStatus({ backgroundPosition: "0% 0%", backgroundImage: "none" });
  };

  return (
    <Grid style={{ padding: "0 5px" }} container justify="center" alignItems="center">
      <Grid
        style={{
          border: `1px solid ${color.greyBorder}`,
          minHeight: 250,
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          backgroundColor: mobile ? color.realWhite : "unset",
          ...style,
        }}
        item
        xs={12}
        md={9}
      >
        {isServer ? (
          <StyledFigure style={{ ...status }} onMouseMove={largeImgUrl && handleMouseMove} onMouseLeave={largeImgUrl && handleMouseLeave}>
            <img alt={alt} src={imgUrl} />
          </StyledFigure>
        ) : imageReady ? (
          mobile ? (
            <img
              style={{ objectFit: "scale-down", maxHeight: width === "xs" ? 300 : 350, maxWidth: "100%" }}
              alt={alt}
              src={img ? img.src : imgUrl}
            />
          ) : (
            <StyledFigure style={{ ...status }} onMouseMove={largeImgUrl && handleMouseMove} onMouseLeave={largeImgUrl && handleMouseLeave}>
              <img alt={alt} src={img ? img.src : imgUrl} />
            </StyledFigure>
          )
        ) : (
          <Skeleton variant="rect" animation="wave" height={mobile ? 300 : 375} width={"100%"} />
        )}
      </Grid>
    </Grid>
  );
};

export default ZoomableImg;
