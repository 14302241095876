import _ from "lodash";

export function detectIE(agent: string = "") {
  return agent.includes("MSIE") || agent.includes("Trident");
}

export function detectBot(agent?: string) {
  if (!agent) {
    return false;
  }

  if (agent.match(/Twitterbot/i) // Twitter
    || agent.match(/Lighthouse/i) // Google lighthouse
    || agent.match(/Google/i)           // Google
    || agent.match(/Applebot/i)          // Apple
    || agent.match(/Bingbot/i)            // MS Bing
    || agent.match(/Slurp/i)              // Yahoo
    || agent.match(/DuckDuckBot/i)          // DuckDuckGo
    || agent.match(/Baiduspider/i)          // Baidu
    || agent.match(/YandexBot/i)          // Yandex Russian
    || agent.match(/Sogou/i)              // Sogou
    || agent.match(/Exabot/i)              // Exabot France
    || agent.match(/facebot/i)              // facebot
    || agent.match(/facebook/i)              // facebook external
    || agent.match(/ia_archiver/i)              // Alexa
    || agent.match(/Slack/i)                // Slack
    || agent.match(/rogerbot/i)                // moz
    || agent.match(/LinkedIn/i)                // LinkedIn
    || agent.match(/embedly/i)                // embedly
    || agent.match(/Quora/i)                // Quora
    || agent.match(/ShowyouBot/i)                // ShowyouBot
    || agent.match(/Pinterest/i)                // Pinterest
    || agent.match(/W3C_Validator/i)                // W3C_Validator
    || agent.match(/outbrain/i)                // outbrain

  ) {
    return true;
  }

  return false;

}

export function detectMobile(agent?: string) {
  if (!agent) {
    return false;
  }

  if (agent.match(/Android/i)
    || agent.match(/webOS/i)
    || agent.match(/iPhone/i)
    || agent.match(/iPad/i)
    || agent.match(/iPod/i)
    || agent.match(/BlackBerry/i)
    || agent.match(/Windows Phone/i)
  ) {
    return true;
  }

  return false;
}

export const getUserAgent = (req) => {
  return req ? _.get(req, "headers.user-agent", "") : navigator.userAgent;
};

export const getInitWidth = (req) => {
  return detectMobile(getUserAgent(req)) ? "xs" : "xl";
};
