import Grid, { GridProps } from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";

const StyledRow = styled(Grid)`
  padding: 10px 0;
`;

const StyledCol = styled(Grid)`
  padding: 10px 0;
`;

/**
 * ### Cannalogue style base - container
 *
 * Default set a padding `10px` on top and bottom
 * @extends Grid
 */
export const Row = React.forwardRef<HTMLDivElement, GridProps>(({ children, ...props }, ref) => {
  return (
    <StyledRow container {...props} ref={ref}>
      {children}
    </StyledRow>
  );
});

/**
 * ### Cannalogue style base - item
 *
 * Default set a padding `10px` on top and bottom
 * @extends Grid
 */
export const Col: React.FC<GridProps> = ({ children, ...props }) => {
  return (
    <StyledCol item {...props}>
      {children}
    </StyledCol>
  );
};
