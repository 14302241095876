import { color } from "@resources/styles";
import React from "react";
import styled from "styled-components";

interface StyledTagProps extends React.HTMLAttributes<any> {
  style?: any;
  type?: "primary" | "secondary" | "default" | "blue" | "red" | "cyan" | "purple" | "green" | "gold"
}

const predefinedTypes = {
  primary: { background: color.gold, color: color.semiBlack, border: "none" },
  secondary: { background: color.teal, color: "#13c2c2", border: "none" },
  default: { background: "#fafafa", color: "rgba(0,0,0,.65)", border: "1px solid #d9d9d9" },
  blue: { background: "none", color: "#096dd9", border: `1px solid #91d5ff` },
  red: { background: "none", color: "#cf1322", border: `1px solid #ffa39e` },
  green: { background: "none", color: "#389e0d", border: "1px solid #b7eb8f" },
  cyan: { background: "none", color: "#08979c", border: "1px solid  #87e8de" },
  purple: { background: "none", color: "#531dab", border: "1px solid  #d3adf7" },
  gold: { background: "none", color: "#d46b08", border: "1px solid #ffd591" }
};

const StyledSpan = styled.span<any>`
  border-radius: 2px;
  padding: 2px 7px;
  white-space: nowrap;
  line-height: 20px;
  background: ${(props) => predefinedTypes[props?.type].background};
  color: ${(props) => predefinedTypes[props?.type].color};
  border: ${(props) => predefinedTypes[props?.type].border};
  font-size: 13px;
`;

const Tag: React.FC<StyledTagProps> = ({ style, type = "primary", children, ...extra }) => (
  <div style={{ lineHeight: "20px" }}>
    <StyledSpan style={style} type={type} {...extra}>
      {children}
    </StyledSpan>
  </div>
);

export default Tag;
