import { GridLayout } from "@components/general";
import { Grid } from "@material-ui/core";
import { StoreProps } from "@reducers/store";
import ArrowDropDown from "@resources/images/ArrowDropDown.svg";
import { color } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import { getRoute } from "@utils/utils";
import _ from "lodash";
import Link from "next/link";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { LinkButton, PrimaryButton, StyledParagraph } from "../general";

const NavBar = styled.nav`
  overflow: hidden;
  background-color: white;
  & a {
    float: left;
    text-align: center;
    text-decoration: none;
  }
`;

const MenuDiv = styled.div<any>`
  & .arrow-down {
    transition: 200ms ease-in-out;
  }
  & .drop-button {
    border-bottom: 1px solid ${color.realWhite};
  }
  &:hover {
    & .drop-button {
      color: ${color.blue};
      border-bottom: 1px solid ${color.blue};
    }
  }
  ${(props) =>
    props.hover
      ? `
    & .arrow-down {
      transform: rotate(-0.5turn);
      fill: ${color.blue};
    }`
      : null}
`;

const DropdownList = styled(({ display, children, ...props }) => <div {...props}>{children}</div>)`
  display: ${(props) => (props.display ? "block" : "none")};
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: ${color.semiWhite};
`;

const DropdownList2 = styled(({ display, children, width, ...props }) => <div {...props}>{children}</div>)`
  display: ${(props) => (props.display ? "block" : "none")};
  position: absolute;
  width: ${(props) => (props.width > 150 ? props.width + 100 : props.width + 166)}px;
  margin-left: ${(props) => (props.width > 150 ? -50 : -83)}px;
  z-index: 10;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: ${color.semiWhite};
`;

const DropdownItemTitle = ({ children }) => (
  <StyledParagraph style={{ padding: "15px 5px" }} guideline="p3" align="left" color={color.black} variant="body2">
    {children}
  </StyledParagraph>
);

const DropdownItemList: React.FC<any> = ({ children, to, asPath, set }) => {
  return (
    <Grid container>
      {to || asPath ? (
        <Link href={to} as={asPath}>
          <StyledParagraph
            className="inherit"
            hoverAction
            style={{ padding: "10px 5px", textAlign: "left" }}
            guideline="p3"
            color={color.black}
            component="a"
          >
            {children}
          </StyledParagraph>
        </Link>
      ) : (
        <StyledParagraph
          className="inherit"
          hoverAction
          style={{ padding: "10px 5px", textAlign: "left" }}
          guideline="p3"
          color={color.black}
          component="a"
        >
          {children}
        </StyledParagraph>
      )}
    </Grid>
  );
};

const MenuItem = ({ menuItem, expand, turnOnMenu }) => {
  const linkRef = useRef<any>(null);
  const { tOrNull: t } = useTranslation(["components/navigation"]);
  const { name: menuItemName, label: menuItemLabel, link: menuItemLink, children = [], type, button, position } = menuItem;
  const isTopMenu = !!position;
  const getMenuName = () => {
    if (isTopMenu) {
      return menuItemLabel || t(menuItemName);
    } else {
      return menuItemLabel || t(menuItemName) || menuItemName;
    }
  };

  if (type === "link") {
    return (
      <MenuDiv>
        <LinkButton
          className="drop-button"
          {...getRoute(menuItemLink)}
          color={color.black}
          onMouseOver={() => turnOnMenu(true)}
          onTouchStart={() => turnOnMenu(true)}
          style={{ padding: "23.5px 0" }}
        >
          {getMenuName()}
        </LinkButton>
      </MenuDiv>
    );
  }

  if (type === "list") {
    return (
      <MenuDiv ref={linkRef} hover={expand}>
        <LinkButton {...getRoute(menuItemLink)} className="drop-button" onMouseOver={() => turnOnMenu(true)} onTouchStart={() => turnOnMenu(true)}>
          {t(`${menuItemName}.title`)} <ArrowDropDown style={{ width: 20, height: 20 }} className="arrow-down" />
        </LinkButton>
        <DropdownList2 width={linkRef?.current?.clientWidth || 300} display={expand}>
          <GridLayout>
            <Grid style={{ padding: "20px 0" }} container>
              {children.map((subMenuItem, index) => (
                <Grid key={index} container>
                  <DropdownItemList key={index} {...getRoute(subMenuItem.link)}>
                    {t(`${menuItemName}.${subMenuItem.name}`) || `${menuItemName}.${subMenuItem.name}`}
                  </DropdownItemList>
                </Grid>
              ))}
            </Grid>
          </GridLayout>
        </DropdownList2>
      </MenuDiv>
    );
  }
  return (
    <MenuDiv hover={expand}>
      <LinkButton {...getRoute(menuItemLink)} className="drop-button" onMouseOver={() => turnOnMenu(true)} onTouchStart={() => turnOnMenu(true)}>
        {t(`${menuItemName}.title`)} <ArrowDropDown style={{ width: 20, height: 20 }} className="arrow-down" />
      </LinkButton>
      <DropdownList display={expand}>
        <GridLayout>
          <Grid container style={{ borderBottom: `1px solid ${color.grey}`, padding: "20px 0" }} >
            {
              children.map((subMenuItem) => {
                // each column display at most 7 items
                const numberOfItems = Object.keys(subMenuItem.children).length;
                const MAX_ITEMS_PER_COLUMN = 7;
                return _.times(numberOfItems / MAX_ITEMS_PER_COLUMN + 1, col =>
                  <Grid key={col} item md={3} lg={2}>
                    {
                      col === 0 ? <LinkButton {...getRoute(subMenuItem.link)}>
                        <DropdownItemTitle>{t(`${menuItemName}.${subMenuItem.name}.title`) || subMenuItem.name}</DropdownItemTitle>
                      </LinkButton>
                        :
                        <div style={{ height: 53 }} />
                    }

                    {Object.keys(subMenuItem.children).slice(col * MAX_ITEMS_PER_COLUMN, (col + 1) * MAX_ITEMS_PER_COLUMN).map((k, i) => (
                      <DropdownItemList key={i} {...getRoute(subMenuItem.children[k].link)}>
                        {t(`${menuItemName}.${subMenuItem.name}.${subMenuItem.children[k].name}`) || subMenuItem.children[k].name}
                      </DropdownItemList>
                    ))}
                  </Grid>
                )
              }
              )}
          </Grid>
        </GridLayout>
        {button && (
          <GridLayout>
            <Grid style={{ padding: "20px 0" }} item md={3} lg={2}>
              <Grid container>
                <PrimaryButton secondary={true} {...getRoute(button.link)} fullWidth>
                  {t(button.name) || button.name}
                </PrimaryButton>
              </Grid>
            </Grid>
          </GridLayout>
        )}
      </DropdownList>
    </MenuDiv>
  );
};

const Menu = () => {
  const { menu } = useSelector((state: StoreProps) => state.navigation.header);
  const [hover, setHover] = useState<null | string>(null);
  const left = menu?.filter((nav) => nav.position === "left");
  const right = menu?.filter((nav) => nav.position === "right");
  return (
    <NavBar onMouseLeave={() => setHover(null)}>
      <GridLayout container justify="space-between" style={{ flexWrap: "nowrap" }}>
        {!_.isEmpty(left) && (
          <Grid container item spacing={3} style={{ flexWrap: "nowrap" }}>
            {left.map((menuItem, idx) => {
              return (
                <Grid key={idx} item>
                  <MenuItem
                    key={`${menuItem.name}_${idx}`}
                    menuItem={menuItem}
                    expand={hover === menuItem.name}
                    turnOnMenu={(value) => {
                      value ? setHover(menuItem.name) : setHover(null);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}

        <Grid container item justify="space-between" style={{ flexWrap: "nowrap" }}>
          {right.map((menuItem, idx) => {
            return (
              <Grid item key={idx}>
                <MenuItem
                  key={`${menuItem.name}_${idx}`}
                  menuItem={menuItem}
                  expand={hover === menuItem.name}
                  turnOnMenu={(value) => {
                    value ? setHover(menuItem.name) : setHover(null);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </GridLayout>
    </NavBar>
  );
};

export default Menu;
