import config from "@config/index";
import axios from "axios";
import { getBuildID } from "./utils";

/**
 * Config AWS S3 and cloud front to accept CORS
 * https://stackoverflow.com/questions/17533888/s3-access-control-allow-origin-header
 * http://blog.celingest.com/en/2014/10/02/tutorial-using-cors-with-cloudfront-and-s3/
 * Whitelist headers:
 *    Access-Control-Request-Headers
 *    Access-Control-Request-Method
 *    Cache-Control
 *    Origin
 * @param {*} language en or fr
 * @param {*} fileName file name in S3 without json ending
 */
export function loadLanguages(language = "en", fileName = "common") {
  if (!["en", "fr"].includes(language)) {
    throw new Error(`Language ${language} cannot be recognized`);
  }
  return axios.get(`${config.staticContent}/cms/resource-bundle/${language}/${fileName}.yaml?version=${getBuildID()}`);
}

/**
 * Converter
 * - convert / to `landing`
 * - convert /education to `education`
 * - convert /cannalogue-column to `cannalogue-column`
 * - convert /my-account/info to `my-account_info`
 * - convert /collections/[collection] by query ex) `cannabis` or `hamp`
 * - convert /cannalogue-column/[...all] to `cannalogue-column`
 * - convert /somthingelse/[somthing] to `somthing` ex) /products/[productId] to `productId`
 * @param {string} pathname
 * @param {*=} [query=null] - optional query (which is coming from next.js's query)
 */
export function parseMatchToFilename(pathname: string, query: any = null) {
  let pageName = pathname.substring(1);
  if (pageName === "_error") {
    pageName = "";
  } else if (pageName === "") {
    pageName = "landing";
  } else if (pageName.indexOf("[") !== -1) {
    const parsed = pageName.substring(pageName.lastIndexOf("["), pageName.lastIndexOf("]")).replace(/\[|\]/g, "");
    if (query && parsed === "collection") {
      pageName = query[parsed];
    } else if (parsed === "...all") {
      pageName = pathname.substring(1, pageName.indexOf("/") + 1);
    } else {
      pageName = parsed;
    }
  }
  // else if (pageName.indexOf("/") !== -1) {
  //   pageName = pageName.replace(/\//g, "_");
  // }

  return pageName;
}

export function getCurrentPathName(pathname, query: any = null) {
  return parseMatchToFilename(pathname, query);
}
