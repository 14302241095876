import ArrowDefaultDown from "@resources/images/ArrowDefaultDown.svg";
import ArrowDown from "@resources/images/ArrowDown.svg";
import styled from "styled-components";

interface ArrowProps {
  direction?: "left" | "right" | "up" | "down";
  arrowType?: "default" | "sharp";
}

const Arrow = styled(({ direction, arrowType = "default", ...props }) => {
  if (arrowType === "sharp") {
    return <ArrowDefaultDown {...props} />;
  }
  return <ArrowDown {...props} />;
})<ArrowProps>`
  transform: rotate(
    ${(props) => {
      if (props.direction === "left") {
        return 90;
      } else if (props.direction === "top") {
        return 180;
      } else if (props.direction === "down") {
        return 0;
      }
      return -90;
    }}deg
  );
  vertical-align: middle;
`;

export default Arrow;
