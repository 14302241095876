import { FormikProps } from "formik";
import React, { createContext } from "react";

const FormikContext = createContext<FormikProps<any> | undefined>(undefined);

const FormProvider = ({ children, formik }) => {
  return <FormikContext.Provider value={formik}>{children}</FormikContext.Provider>;
};

export default FormProvider;
