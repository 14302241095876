import { Row, StyledParagraph } from "@components/general";
import useFormField from "@hooks/useFormField";
import { Checkbox, Collapse, FormControlLabel, FormHelperText, Grid } from "@material-ui/core";
import IconCheckmarkChecked from "@resources/images/IconCheckmarkChecked.svg";
import IconCheckmarkUnchecked from "@resources/images/IconCheckmarkUnchecked.svg";
import { color } from "@resources/styles";
import { getFormikFirstErrorKey } from "@utils/utils";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const CheckboxOutline = styled(IconCheckmarkUnchecked)`
  height: 18px;
  width: 18px;
  stroke-width: 3px;
  border-radius: 3px;
`;

const CheckboxChecked = styled(IconCheckmarkChecked)`
  height: 18px;
  width: 18px;
  stroke-width: 2px;
  border-radius: 3px;
`;

const StyledCheckbox = styled(Checkbox) <any>`
  ${(props) =>
    props.$error &&
    `
    & svg rect {
      stroke: red;
    }
  `}
  &.focused {
    background-color: ${color.grayLighter} !important;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
`;

const FormikCheckBoxField: React.FC<any> = ({ name, label, children, ...custom }) => {
  const { field, isSubmitting, hasError, submitCount, error, errors, setFieldValue } = useFormField({ name });
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hasError && submitCount > 0 && getFormikFirstErrorKey(errors) === name) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  // use controlled field, to make sure initial checked working
  const handleChange = (event) => {
    setFieldValue(event.target.name, event.target.checked);
  };

  return (
    <Grid container ref={ref}>
      <StyledFormControlLabel
        disabled={isSubmitting}
        label={
          <StyledParagraph
            hoverAction
            color={color.black}
            guideline="p2"
            style={{ paddingTop: 5 }}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        }
        control={
          <StyledCheckbox
            color="primary"
            $error={hasError}
            checked={field.value}
            onChange={handleChange}
            icon={<CheckboxOutline />}
            checkedIcon={<CheckboxChecked />}
          />
        }
        labelPlacement="end"
        {...field}
        {...custom}
      />
      <Collapse in={hasError}>
        <Grid item style={{ display: "block" }} className="invalid-feedback">
          <FormHelperText error={hasError}>{error}</FormHelperText>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default FormikCheckBoxField;
