import { cannalogueAlert } from "@reducers/alerts";
import { StoreProps, useCurrentUser } from "@reducers/store";
import { AddressObject } from "@reducers/types";
import { loadUserProfileAction } from "@reducers/user";
import * as ROUTE from "@resources/routeConst";
import isServer from "@utils/isServer";
import { alertRequestError, onStatusCode } from "@utils/utils";
import _ from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { provinceQuery } from "./elasticsearch/querySyntaxHelper";
import { retrieveGeocode } from "./services";

export const useLoginRequired = () => {
  const router = useRouter();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user?.email) {
      dispatch(loadUserProfileAction()).catch((err) => {
        alertRequestError({ excepts: [401] })(err);
        onStatusCode(401, () => {
          cannalogueAlert.error("Your session has expired, please log in.");
          router.push({
            pathname: ROUTE.LOGIN,
            query: { returnUrl: window.location.pathname + window.location.search },
          });
        })(err);
      });
    }
  }, []);

  return {};
};

export const useCurrentAddress = () => {
  const [currentAddress, setCurrentAddress] = useState<AddressObject>();

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  const success = useCallback(async (pos) => {
    const crd = pos.coords;
    const coordinates: [number, number] = [crd.latitude, crd.longitude]
    const data = await retrieveGeocode(coordinates);
    setCurrentAddress(data);
  }, [])

  const error = useCallback((err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }, [])

  useEffect(() => {
    getCurrentAddress();
  }, [])

  const getCurrentAddress = (onError?: () => void) => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(success, (err) => {
        error(err);
        if (onError) {
          onError();
        }
      }, options);
    }
  }
  return { currentAddress, getCurrentAddress };
}

export const useLocation = (initialValues?: AddressObject) => {
  const [address, setAddress] = useLocalStorage("address", initialValues);
  return [address, setAddress];
}

export const useLocalStorage = (key, initialValue) => {
  let defaultValue = initialValue;
  if (initialValue instanceof Function) {
    defaultValue = initialValue();
  }
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return defaultValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return defaultValue
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      // If error also return defaultValue
      // console.log(error);
      return defaultValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const useTimeout = (cb, delay) => {
  const cbRef = useRef<() => void>();
  const timeoutRef = useRef();

  useEffect(() => {
    cbRef.current = cb;
  }, [cb])

  const set = useCallback(
    () => {
      if (cbRef.current) {
        cbRef.current();
      };
    },
    [delay],
  )

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [])

  useEffect(() => {
    set();
    return clear;
  }, [set, delay, clear])

  const reset = useCallback(() => {
    clear();
    set();
  }, [])

  return { clear, reset };
}

export const useProvinceQuery = (initialValue?: string) => {
  const [searchQuery, setSearchQuery] = useState<any>();
  const address = useSelector((store: StoreProps) => store.address);
  const [province, setProvince] = useState<string | undefined>(initialValue);

  useEffect(() => {
    if (!address?.province && !isServer && localStorage.getItem("province")) {
      const _province = localStorage.getItem("province");
      if (_province) setProvince(_province);
    }
  }, []);

  useEffect(() => {
    if (address?.province && address.province !== province) {
      setProvince(address.province);
    }
  }, [address]);

  useEffect(() => {
    if (province) {
      setSearchQuery(provinceQuery(province));
    }
  }, [province]);

  return [searchQuery];
}
