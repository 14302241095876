import _ from "lodash";
import { Store } from "redux";

let reduxStore: Store | null = null;
const searchFilters = {};
const esConfigs = {};

export const setStore = (store: Store) => {
  reduxStore = store;
};

export const getStore = (): Store => {
  if (!reduxStore) {
    throw new Error("Failed to get redux store! Please make sure you called setStore(...)");
  }
  return reduxStore;
};

export const getESConfigs = (esName?: string) => {
  if (esName) {
    return esConfigs[esName];
  } else {
    return esConfigs;
  }
};

export const getSearchFilters = (esName: string) => {
  return searchFilters[esName] || {};
};

export const getSearchAPI = (esName: string) => {
  const { url, indices } = esConfigs[esName];
  return `${url}${indices.join(",")}/_search`;
};

export const registerElasticSearch = (id: string, opts) => {
  esConfigs[id] = opts;
};

export const registerSearchFilter = (esName: string, id: string, opts) => {
  searchFilters[esName] = searchFilters[esName] || {};

  if (_.isEmpty(searchFilters[esName][id]) || !_.isEqual(searchFilters[esName][id], opts)) {
    searchFilters[esName][id] = opts;
  }
};

export const unRegisterSearchFilter = (esName: string, id: string) => {
  searchFilters[esName] = searchFilters[esName] || {};
  delete searchFilters[esName][id];
};
