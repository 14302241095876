import config from "@config/index";
import { getCurrentPathName } from "@utils/contentsManager";
import { useTranslation } from "@utils/i18n";
import { findImageVersion, getBuildID } from "@utils/utils";
import _ from "lodash";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import ServerRouteContext from "./ServerRouteContext";

export interface SEOoptionsProps {
  title?: string;
  description?: string;
  keywords?: string | string[];
  image?: string;
}

interface SEOProps {
  options?: SEOoptionsProps;
}

const SEO: React.FC<SEOProps> = ({ options }) => {
  const router = useRouter();
  const localeFileName = getCurrentPathName(router.pathname, router.query);
  const { t } = useTranslation([localeFileName, "common"]);
  const serverRoute = useContext(ServerRouteContext);
  const metaData = {
    title: t(["meta.title", `common:common.meta.title`]),
    description: t(["meta.description", `common:common.meta.description`]),
    keywords: t(["meta.keywords", `common:common.meta.keywords`]),
    canonical: `${config.baseURL}${serverRoute.asPath}`,
  };

  return (
    <Head>
      <title>{options?.title || metaData.title}</title>
      <meta name="description" content={options?.description || metaData.description} />
      <meta
        name="keywords"
        content={
          options && options.keywords && !_.isEmpty(options.keywords)
            ? typeof options.keywords === "string"
              ? options.keywords
              : options.keywords.join(", ")
            : metaData.keywords
        }
      />
      <link rel="canonical" href={metaData.canonical} />

      <meta property="og:title" content={options?.title ? options.title : metaData.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={options?.description ? options.description : metaData.description} />
      <meta property="og:url" content={metaData.canonical} />
      {options?.image ? (
        <>
          <meta property="og:image" content={options.image} />
          <meta property="og:image:secure_url" content={options.image} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="350" />
          <meta property="og:image:alt" content={options && options.title ? options.title : metaData.title} />
        </>
      ) : (
        <>
          <meta property="og:image" content={`${config.staticContent}/cms/images/logos/logo_green.png?version=${findImageVersion()}`} />
          <meta property="og:image:secure_url" content={`${config.staticContent}/cms/images/logos/logo_green.png?version=${findImageVersion()}`} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="186" />
          <meta property="og:image:height" content="70" />
          <meta property="og:image:alt" content="Cannalogue" />
        </>
      )}
      {/* <meta property="fb:admins" content={"Facebook numberic ID"} /> */}
    </Head>
  );
};

export default SEO;
