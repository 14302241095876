import React, { useContext } from "react";
import Link from "next/link";
import * as ROUTE from "@resources/routeConst";
import config from "@config/index";
import { WidthContext } from ".";
import { findImageVersion } from "@utils/utils";
interface LogoProps {
  white?: boolean;
  style?: React.CSSProperties;
  mini?: boolean;
}

const Logo: React.FC<LogoProps> = ({ style = {}, mini = false, white = false }) => {
  const width = useContext(WidthContext);
  const tablet = width === "xs" || width === "sm";
  const adjustedSize = tablet ? { height: 53 } : {};

  let logoFileName = "logo";
  if (white) {
    logoFileName = `${logoFileName}_white`;
  }

  if (tablet) {
    logoFileName = `${logoFileName}_mini`;
  }

  return (
    <Link href={ROUTE.LANDING}>
      <a className="inherit">
        <img
          src={`${config.staticContent}/cms/images/logos/${logoFileName}.svg?version=${findImageVersion()}`}
          alt="logo"
          style={{ cursor: "pointer", ...adjustedSize, ...style }}
        />
      </a>
    </Link>
  )
}

export default Logo;
