import { NavigationProps } from "@reducers/navigation";
import * as ROUTE from "@resources/routeConst";
import http from "@utils/axios";
import _ from "lodash";
import qs from "qs";

const menus: NavigationProps = {
  menu: [
    {
      name: "my_account",
      type: "list",
      hide: "mobile",
      position: "top",
      children: [
        {
          name: "account_info",
          link: ROUTE.MY_ACCOUNT_ACCOUNT_INFO,
        }, {
          name: "order_returns",
          link: ROUTE.MY_ACCOUNT_ORDER_RETURNS,
        }, {
          name: "recurring_orders",
          link: ROUTE.MY_ACCOUNT_RECURRING_ORDERS,
        }, {
          name: "invoices",
          link: ROUTE.MY_ACCOUNT_INVOICES
        }, {
          name: "shipping_address",
          link: ROUTE.MY_ACCOUNT_ADDRESSES
        }, {
          name: "payment_info",
          link: ROUTE.MY_ACCOUNT_PAYMENT_INFO
        }, {
          name: "newsletter_preference",
          link: ROUTE.MY_ACCOUNT_NEWSLETTER
        },
        {
          name: "promotions",
          link: ROUTE.MY_ACCOUNT_PROMOTIONS
        }
      ]
    },
    {
      name: "home",
      position: "top",
      link: ROUTE.LANDING
    },
    {
      name: "special_offers",
      link: ROUTE.SPECIAL_OFFERS,
      position: "left",
      type: "link",
    },
    {
      name: "local_delivery",
      link: ROUTE.LOCAL_DELIVERY,
      position: "left",
      type: "link",
    },
    {
      name: "Partner with us",
      type: "link",
      position: "right",
      link: ROUTE.PARTNER_WITH_US,
    }
    // {
    //   name: "about_cannalogue",
    //   type: "list",
    //   position: "right",
    //   children: [
    //     {
    //       name: "the_cannalogue_story",
    //       link: ROUTE.ABOUT_CANNALOGUE_STORY
    //     },
    //     // {
    //     //   name: "the_founders",
    //     //   link: ROUTE.ABOUT_CANNALOGUE_FOUNDERS
    //     // },
    //     {
    //       name: "the_vision_plus_mission",
    //       link: ROUTE.ABOUT_CANNALOGUE_VISION
    //     },
    //     {
    //       name: "the_advisory_board",
    //       link: ROUTE.ABOUT_CANNALOGUE_ADVISORY_BOARD
    //     },
    //     {
    //       name: "cannalogue_newsroom",
    //       link: ROUTE.NEWSROOM,
    //     },
    //   ],
    // },
  ],
};

export const asynMenu = async () => {
  try {
    const { data: accessories_menus }: { data: { [key: string]: string[] } } = await http.get("/api/app_settings/shop_accessories_menus");
    const { data: cannabis_menus }: { data: { [key: string]: string[] } } = await http.get("/api/app_settings/shop_medical_cannabis_menus");

    const accessories_menus_items = Object.entries(accessories_menus).map(([name, subMenu]) => {
      return {
        name: _.findLast(name.split("/")),
        link: `${ROUTE.ACCESSORIES}?${qs.stringify({ Category: [name] })}`,
        children: [...subMenu.map((menu) => {
          return {
            name: _.findLast(menu.split("/")),
            link: `${ROUTE.ACCESSORIES}?${qs.stringify({ Category: [menu] })}`
          };
        }), {
          name: `All ${_.findLast(name.split("/"))}`,
          link: `${ROUTE.ACCESSORIES}?${qs.stringify({ Category: [name] })}`,
        }],
      };
    });

    const cannabis_menus_items = Object.entries(cannabis_menus).map(([name, subMenu]) => {
      return {
        name: _.findLast(name.split("/")),
        link: ROUTE.PRODUCTS,
        children: subMenu.map((menu) => {
          return {
            name: _.findLast(menu.split("/")),
            link: `${ROUTE.PRODUCTS}?${qs.stringify({ [name]: [menu] })}`
          };
        }),
      };
    });

    const dynamicItems = [
      {
        name: "shop_cannabis",
        type: "cascade",
        position: "left",
        children: cannabis_menus_items,
        button: {
          name: "shop_cannabis.button",
          link: ROUTE.PRODUCTS
        }
      }, {
        name: "shop_accessories",
        type: "cascade",
        position: "left",
        children: accessories_menus_items,
        button: {
          name: "shop_accessories.button",
          link: ROUTE.ACCESSORIES
        }
      }
    ];
    return {
      ...menus,
      menu: [...dynamicItems, ...menus.menu]
    };
  } catch (error: any) {
    // @ts-ignore
    nextLogger.info(error);
    return { ...menus };
  }
};

export default menus;
