import config from "@config/index";
import { FooterProps } from "@reducers/navigation";
import * as ROUTE from "@resources/routeConst";

const footerConfig: FooterProps = {
  navigation: [
    {
      name: "navigation.resources.title",
      children: [
        { name: "navigation.resources.faqs", link: ROUTE.FAQ },
      ]
    },
    {
      name: "navigation.legal.title",
      children: [
        { name: "navigation.legal.terms_and_conditions", link: ROUTE.TERMSANDCONDITIONS },
        { name: "navigation.legal.privacy_policy", link: ROUTE.PRIVACYPOLICY }
      ]
    },
    {
      name: "navigation.company.title",
      children: [
        { name: "navigation.company.about", link: ROUTE.ABOUT_US },
        { name: "navigation.company.contact_us", link: ROUTE.CONTACT_US },
        { name: "navigation.company.suppliers", link: ROUTE.PARTNERS },
      ]
    },
    {
      name: "navigation.connect.title",
      children: [
        { name: "navigation.connect.facebook", link: config.social_link.facebook },
        { name: "navigation.connect.twitter", link: config.social_link.twitter },
        // { name: "navigation.connect.instagram", link: config.social_link.instagram },
        { name: "navigation.connect.linkedIn", link: config.social_link.linkedin },
      ]
    },
  ]
};

export default footerConfig;
