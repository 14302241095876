import { FieldInputProps, useFormikContext } from "formik";
import _ from "lodash";
import { useDebouncedValue } from "./useDebouncedFunction";

interface UseFormProps {
  name: string;
  debounce?: boolean;
}

const useFormField = ({ name, debounce = true }: UseFormProps) => {
  const formik = useFormikContext();
  const { errors, touched } = formik;
  const { value, onChange, ...restField }: FieldInputProps<any> = formik.getFieldProps(name);
  const hasError: boolean = _.get(touched, name) && _.get(errors, name) !== undefined ? true : false;
  const error = _.get(errors, name);

  const [debouncedValue, debouncedSetter] = useDebouncedValue(value, onChange, 100);

  const onBlur = (event) => {
    restField.onBlur(event && event.target ? event : { target: { name } });
  };

  const debouncedField = {
    value: debouncedValue?.target ? debouncedValue.target.value : debouncedValue,
    onChange: debouncedSetter,
    ...restField,
    onBlur,
  };

  return {
    ...formik,
    name,
    field: debounce ? debouncedField : { ...formik.getFieldProps(name) },
    hasError,
    error,
  };
};

export default useFormField;
