import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { color } from "@resources/styles";
import React, { ReactNode, useContext } from "react";
import { Fade } from "react-reveal";
import styled, { css } from "styled-components";
import WidthContext from "./WidthContext";

export interface StyledParagraphProps extends Omit<TypographyProps, "color"> {
  /**
   * Font size, default as `20px`
   */
  size?: number;

  /**
   * Font color, default as `white`
   */
  color?: string;

  /**
   * Set the text ellipsis with true or number. display like..., default as `false`
   *
   * __number of line may have some issue on safari__
   */
  textEllipsis?: boolean | number;

  /**
   * line height
   */
  lineHeight?: number;

  /**
   * Enable the hover action, hover to change to blue color and show the cursor, default as `false`
   */
  hoverAction?: boolean;

  /**
   * Change the component, default as `p`
   */
  component?: React.ElementType<any>;

  /**
   * Fade animation
   */
  fade?: "left" | "right" | "clear" | "top" | "bottom" | null;

  /**
   * Animation delay
   */
  delay?: number;
  children?: ReactNode;

  /**
   * Follow the guideline
   *
   * __component h__
   * @param h1 web: (size: 45, lineHeight: 55), mobile: (size: 35, lineHeight: 45)
   * @param h2 web: (size: 35, lineHeight: 45), mobile: (size: 30, lineHeight: 40)
   * @param h3 web: (size: 24, lineHeight: 30)
   * @param h4 web: (size: 20, lineHeight: 24)
   *
   * __component p__
   * @param p1 web: (size: 20, lineHeight: 30), mobile: (size: 18, lineHeight: 26)
   * @param p2 web: (size: 16, lineHeight: 24), mobile: (size: 16, lineHeight: 26)
   * @param p3 web: (size: 15, lineHeight: 25)
   * @param p4 web: (size: 13, lineHeight: 20)
   */
  guideline?: "h1" | "h2" | "h3" | "h4" | "p1" | "p2" | "p3" | "p4";
  href?: string;
  htmlFor?: string;
  alt?: string;
}

const useGuideLine = {
  h1: (width: string) =>
    width === "xs" ? { size: 35, lineHeight: 45, variant: "h1", component: "h1" } : { size: 45, lineHeight: 55, variant: "h1", component: "h1" },
  h2: (width: string) =>
    width === "xs" ? { size: 30, lineHeight: 40, variant: "h1", component: "h2" } : { size: 35, lineHeight: 45, variant: "h1", component: "h2" },
  h3: (width: string) => ({ size: 24, lineHeight: 30, variant: "h1", component: "h3" }),
  h4: (width: string) => ({ size: 20, lineHeight: 24, variant: "h1", component: "h4" }),

  p1: (width: string) =>
    width === "xs" ? { size: 18, lineHeight: 26, variant: "body1", component: "p" } : { size: 20, lineHeight: 30, variant: "body1", component: "p" },
  p2: (width: string) => ({ size: 16, lineHeight: width === "xs" ? 26 : 24, variant: "body1", component: "p" }),
  p3: (width: string) => ({ size: 15, lineHeight: 25, variant: "body1", component: "p" }),
  p4: (width: string) => ({ size: 13, lineHeight: 20, variant: "body1", component: "p" }),
};

const hoverActionCss = css`
  cursor: pointer;
  &:hover {
    color: ${color.blue};
    font-weight: 500;
  }
`;
const hoverActionToWhiteCss = css`
  cursor: pointer;
  &:hover {
    font-weight: 500;
  }
`;

const EllipsisCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EllipsisByLinesCss = css<any>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.textEllipsis || 3};
  display: flexbox;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledParagraphCss = css<any>`
  color: ${(props) => (props.color ? props.color : color.white)};
  font-size: ${(props) => (props.size ? props.size : 20)}px;
  .link-blue {
    ${hoverActionCss}
    text-decoration: underline;
  }
  .link-white {
    ${hoverActionToWhiteCss}
    text-decoration: underline;
  }
  .underline{
    text-decoration: underline;
  }
  & a {
    color: ${(props) => (props.color ? props.color : color.white)};
    text-decoration: none;
  }
  ${(props) => (props.lineHeight ? `line-height: ${props.lineHeight}px;` : null)}
  ${(props) => (props.hoverAction ? hoverActionCss : null)}
  ${(props) => {
    if (typeof props.textEllipsis === "number") {
      return EllipsisByLinesCss;
    } else if (props.textEllipsis === true) {
      return EllipsisCss;
    }
    return null;
  }}
`;

// tslint:disable-next-line:no-shadowed-variable
const Styled = styled(({ size, color, hoverAction, textEllipsis, lineHeight, children, ...props }) => (
  <Typography component="p" {...props}>
    {children}
  </Typography>
))`
  ${StyledParagraphCss}
`;
/**
 * ### Cannalogue base text wrapper
 * @param size
 * Font size, default as `20px`
 * @param color
 * Font color, default as `white`
 * @param textEllipsis
 * Set the text ellipsis with true or number. display like..., default as `false` __number of line may have some issue on safari__
 * @param lineHeight
 * line height
 * @param hoverAction
 * Enable the hover action, hover to change to blue color and show the cursor, default as `false`
 * @param component
 * Change the component, default as `p`
 * @param fade
 * Fade animation
 *
 * @param delay
 * Animation delay
 *
 * @param guideline
 * Follow the guideline
 *
 * __component h__
 * @param h1 web: (size: 45, lineHeight: 55), mobile: (size: 35, lineHeight: 45)
 * @param h2 web: (size: 35, lineHeight: 45), mobile: (size: 30, lineHeight: 40)
 * @param h3 web: (size: 24, lineHeight: 30)
 * @param h4 web: (size: 20, lineHeight: 24)
 *
 * __component p__
 * @param p1 web: (size: 20, lineHeight: 30), mobile: (size: 18, lineHeight: 26)
 * @param p2 web: (size: 16, lineHeight: 24), mobile: (size: 16, lineHeight: 26)
 * @param p3 web: (size: 15, lineHeight: 25)
 * @param p4 web: (size: 13, lineHeight: 20)
 *
 * @extends Typography This inherits the `Typography`
 */
const StyledParagraph = React.forwardRef<any, StyledParagraphProps>(
  // tslint:disable-next-line:no-shadowed-variable
  ({ size, color, fade, delay, guideline, textEllipsis = false, lineHeight, align = "inherit", hoverAction = false, children, ...props }, ref) => {
    const width = useContext(WidthContext);
    const guideValues = guideline ? useGuideLine[guideline](width) : null;
    const cssLineHeight = lineHeight || guideValues?.lineHeight;
    const cssFontSize = size || guideValues?.size;
    const variant = guideValues?.variant;
    const component = guideValues?.component;
    if (fade) {
      const direction = {};
      direction[fade] = true;
      return (
        <Fade {...direction} delay={delay ? delay : 300} duration={1000}>
          <Styled
            size={cssFontSize}
            color={color}
            lineHeight={cssLineHeight}
            align={align}
            textEllipsis={textEllipsis}
            hoverAction={hoverAction}
            variant={variant}
            component={component}
            {...props}
          >
            {children && children}
          </Styled>
        </Fade>
      );
    } else {
      return (
        <Styled
          size={cssFontSize}
          color={color}
          lineHeight={cssLineHeight}
          align={align}
          textEllipsis={textEllipsis}
          hoverAction={hoverAction}
          variant={variant}
          component={component}
          {...props}
        >
          {children && children}
        </Styled>
      );
    }
  }
);

export default StyledParagraph;
