import { createMuiTheme } from "@material-ui/core";
import config from "@config/index";

const theme = {
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#F9B846"
    }
  },
  typography: {
    fontFamily: "Proxima Nova W01 Light",
    body1: {
      fontFamily: "Proxima Nova W01 Regular"
    },
    body2: {
      fontFamily: "Proxima Nova W05 Semibold"
    },
    caption: {
      fontFamily: "Proxima Nova W02 Italic"
    },
    subtitle1: {
      fontFamily: "Proxima Nova W01 Regular"
    },
    subtitle2: {
      fontFamily: "Proxima Nova W01 Regular"
    },
    button: {
      fontFamily: "Proxima Nova W05 Semibold",
      fontSize: 15,
      lineHeight: "20pt"
    }
  }
};

const drupTheme = {
  palette: {
    primary: {
      main: "#0d82fd",
    },
    secondary: {
      main: "#fdb501"
    }
  },
  typography: {
    fontFamily: "Montserrat",
    body1: {
      fontFamily: "Montserrat"
    },
    body2: {
      fontFamily: "Montserrat"
    },
    caption: {
      fontFamily: "Montserrat"
    },
    subtitle1: {
      fontFamily: "Montserrat"
    },
    subtitle2: {
      fontFamily: "Montserrat"
    },
    button: {
      fontFamily: "Montserrat",
      fontSize: 14,
      lineHeight: "20pt",
      fontWeight: 600
    }
  }
};

export default createMuiTheme(config.drup ? drupTheme : theme);
