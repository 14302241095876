import footCA from "@config/ui/footer-CA";
import menus from "@config/ui/navigation-CA";
import { Reducer } from "redux";
import { createAction, handleActions } from "redux-actions";

export interface MenuItemType {
  name: string;
  label?: string;
  link?: string;
  type?: "link" | "list" | "cascade";
  hide?: "mobile" | "desktop";
  children?: MenuItemType[];
  position?: "left" | "right" | "top"; // position for web navbar
  button?: {
    name: string;
    link?: string;
  };
}

export interface NavigationProps {
  menu: MenuItemType[];
  [dynamic_nav: string]: any;
}

export interface FooterProps {
  navigation: FooterItemType[];
}

export interface FooterItemType {
  name: string;
  link?: string;
  children?: FooterItemType[];
}

export interface NavigationStoreProps {
  loaded: boolean;
  header: NavigationProps;
  footer: FooterProps;
}

const initialState: NavigationStoreProps = {
  loaded: false,
  header: menus,
  footer: footCA,
};

export const updateConfigAction: any = createAction("UPDATE_CONFIG_ACTION");

const reducer: Reducer<NavigationStoreProps> = handleActions(
  {
    [updateConfigAction]: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
  initialState
);

export default reducer;
