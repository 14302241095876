import { getKeywordSearchESNames, useKeywordSearch } from "@utils/elasticsearch/hooks";
import { KeywordFilterComponent } from "@utils/elasticsearch/types";
import _ from "lodash";
import React from "react";
import SuggestionFilterView from "./SuggestionFilterView";

const SuggestionFilter: KeywordFilterComponent<{ name: string; placeholder?: string }> = ({ id, name, placeholder, ...config }) => {
  const { value, updateValue, submitValue, getSuggestion } = useKeywordSearch(getKeywordSearchESNames(), { id, ...config });

  return (
    <SuggestionFilterView
      placeholder={placeholder}
      value={value}
      onChange={updateValue}
      nameKey="name"
      titleKey="title"
      dataKey="data"
      getFetch={getSuggestion}
      onSelected={submitValue}
    />
  );
};

export default React.memo(SuggestionFilter, _.isEqual);
