import { StyledParagraph } from "@components/general";
import { Divider, Grid, makeStyles, Menu } from "@material-ui/core";
import { StoreProps } from "@reducers/store";
import { logoutAction } from "@reducers/user";
import ArrowDropDown from "@resources/images/ArrowDropDown.svg";
import { color, theme } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import { useLogout } from "@utils/services";
import Link from "next/link";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Dropdown = styled.div<any>`
  .button-box {
    border: 1px solid ${color.grey};
    border-radius: 4px;
    background-color: ${color.realWhite};
    display: flex;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 12px;
    padding-left: 10px;
    min-width: 200px;
    cursor: pointer;
  }
  ${(props) =>
    props.open
      ? `
    .button-box {
      border: 1px solid ${color.blue};
    }
    & p {
      color: ${color.blue};
    }
    & .arrow-down {
      transform: rotate(-0.5turn);
      fill: ${color.blue};
    }`
      : null}
`;

const StyledMenu = styled(Menu)<any>`
  .MuiMenu-paper {
    border-radius: 0;
    background-color: unset;
    width: ${(props) => (props.width ? props.width - 1 : "unset")}px;
    margin-top: 7px;
  }
  .MuiList-root {
    padding: 0;
  }
`;

const MenuPaper = styled.div`
  border-radius: 4px;
  background-color: ${color.white};
  border: 1px solid ${color.blue};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  &:focus {
    outline: unset;
  }
  & p,
  a {
    & :hover {
      background-color: ${color.secondary};
    }
  }
`;

const flexGrow = makeStyles({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(1.5),
  },
});

const UserDropdown = () => {
  const { menu } = useSelector((state: StoreProps) => state.navigation.header);
  const user = useSelector((state: StoreProps) => state.user);
  const [__, logout] = useLogout();
  const dispatch = useDispatch();
  const { tOrNull: t } = useTranslation("components/navigation");
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const spaceing = flexGrow();
  const myAccountMenu = menu.filter((item) => item.name === "my_account")[0];

  const handleSignout = () => {
    dispatch(logoutAction());
    logout();
  };

  return (
    <Dropdown open={open} ref={anchorRef}>
      <span className="button-box" onClick={() => setOpen(true)}>
        <StyledParagraph align="left" textEllipsis={true} className={spaceing.root} size={15} color={color.black} variant="body1">
          {t("my_account.welcome_message", { first_name: user.first_name })}
        </StyledParagraph>
        <ArrowDropDown style={{ width: 20, height: 20, marginLeft: 16 }} className="arrow-down" />
      </span>
      <StyledMenu
        width={anchorRef && anchorRef.current && anchorRef.current.clientWidth}
        elevation={0}
        anchorEl={anchorRef.current}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transitionDuration={{
          enter: 250,
          exit: 100,
        }}
        PaperProps={{}}
      >
        <MenuPaper>
          {myAccountMenu?.children?.map(({ link, name }, index) => (
            <Grid key={`${name}_${index}`} container>
              {link && (
                <Link href={link}>
                  <StyledParagraph
                    className="inherit"
                    hoverAction
                    style={{ padding: "15px 15px", width: "100%" }}
                    align="left"
                    guideline="p3"
                    color={color.semiBlack}
                    component="a"
                  >
                    {t(`${myAccountMenu.name}.${name}`)}
                  </StyledParagraph>
                </Link>
              )}
            </Grid>
          ))}
          <Divider style={{ width: "100%" }} />
          <StyledParagraph hoverAction style={{ padding: "15px 15px" }} align="left" guideline="p3" color={color.semiBlack} onClick={handleSignout}>
            {t("my_account.sign_out")}
          </StyledParagraph>
        </MenuPaper>
      </StyledMenu>
    </Dropdown>
  );
};

export default UserDropdown;
