
import { axiosRequestSpinnerPlugin, axiosResponseErrorSpinnerPlugin, axiosResponseSpinnerPlugin } from "@components/general/SmartSpinner";
import configuration from "@config/index";
import { smartErrorAxiosErrorPlugin, smartErrorAxiosRequestPlugin } from "@hooks/useSmartError";
import { cannalogueAlert } from "@reducers/alerts";
import { i18n } from "@utils/i18n";
import axios, { CancelTokenSource } from "axios";
import axiosRetry from "axios-retry";
import _ from "lodash";
import moment from "moment-timezone";
import isServer from "./isServer";
import { clearCsrfToken, loadCsrfToken, readCsrfToken } from "./services";
import { serverSideRequire } from "./utils";

const InvalidCSRFTokenMessage = "Invalid CSRF Token";

let httpContext: any = null;
// load httpContext in sever mode
if (isServer) {
  httpContext = serverSideRequire("express-http-context");
}

const APP_SERVER = configuration.appServer;
const http = axios.create({ timeout: 1 * 60 * 1000 }); // one minute timeout
const CancelToken = axios.CancelToken;
export let cancel: any = null;

// Add smart spinner interceptors
axios.interceptors.request.use(axiosRequestSpinnerPlugin);
axios.interceptors.response.use(axiosResponseSpinnerPlugin, axiosResponseErrorSpinnerPlugin);

http.interceptors.request.use(axiosRequestSpinnerPlugin);
http.interceptors.response.use(axiosResponseSpinnerPlugin, axiosResponseErrorSpinnerPlugin);

// Add smart error interceptors
axios.interceptors.request.use(smartErrorAxiosRequestPlugin);
axios.interceptors.response.use(undefined, smartErrorAxiosErrorPlugin);

http.interceptors.request.use(smartErrorAxiosRequestPlugin);
http.interceptors.response.use(undefined, smartErrorAxiosErrorPlugin);

// Global error handling
const handleError = async (error) => {
  const genericErrMsg = i18n.t("common:common.validation_error.general");

  // server error global handle
  if (error.response && error.response.status >= 500) {
    cannalogueAlert.error(genericErrMsg);
    return Promise.reject(error);
  }

  // other error pass down
  return Promise.reject(error);
};

const IgnoreCsrf = ["/gateway/csrf/token"];

const csrfToken = async (config) => {
  for (let url in IgnoreCsrf) {
    if (_.startsWith(config.url, IgnoreCsrf[url])) {
      return config;
    }
  }

  const token = await readCsrfToken();
  config.headers["CSRF-Token"] = token;
  return config;
};

const cancelToken = async (config) => {
  config.headers.cancelToken = new CancelToken((c) => {
    cancel = c;
  });
  return config;
};

if (!isServer) {
  http.interceptors.request.use(csrfToken);
}
http.interceptors.request.use(cancelToken);

// Set auth header in local mode
if (process.env.LOCAL_MODE) {
  http.interceptors.request.use((config) => {
    if (isServer && httpContext) {
      const cookieName = process.env.COOKIE_NAME || "";
      if (httpContext.get("req.cookies") && httpContext.get("req.cookies")[cookieName]) {
        let authHeader = {};
        authHeader = { authorization: `Bearer ${httpContext.get("req.cookies")[cookieName]}` };
        config.headers = { ...config.headers, ...authHeader };
        config.withCredentials = true;
      }
    }
    return config;
  }, handleError);
}

http.interceptors.request.use((config) => {
  config.cancelToken = RequestTokenHolder.request?.token;

  if (isServer && httpContext) {
    config.url = `${APP_SERVER}${config.url}`;
    if (httpContext.get("req.headers") && httpContext.get("req.headers").cookie) {
      config.headers = { ...config.headers, cookie: httpContext.get("req.headers").cookie };
      config.withCredentials = true;
    }
  }

  if (isServer && process.env.NODE_ENV !== "production") {
    // @ts-ignore
    nextLogger.info(`[ServerSide Http Request]: ${config.url}`, config.data);
  }

  if (!isServer) {
    config.headers["Client-Timezone"] = moment.tz.guess();
  }

  return config;
}, handleError);

http.interceptors.response.use((response) => {
  // products 0-10/54 - return from 0 to 10; total 54
  if (response.headers["content-range"]) {
    const range = response.headers["content-range"];
    const [fromTo, total] = range.split(" ")[1].split("/");
    const [from, to] = fromTo.split("-");

    response.headers.from = Number(from);
    response.headers.to = Number(to);
    response.headers.total = Number(total);
  }

  return response;
}, handleError);

/**
 * make axios retry on some conditions
 */
axiosRetry(http, {
  retries: 2,
  retryCondition: (error) => {
    if (403 === error?.response?.status && JSON.stringify(error.response?.data).includes(InvalidCSRFTokenMessage)) {
      clearCsrfToken();
      loadCsrfToken();
      return true;
    }
    return false;
  }
});

export const RequestTokenHolder: { request: CancelTokenSource | null } = { request: null };

export default http;
