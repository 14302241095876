import { ServerRouteContext, WidthContext } from "@components/general";
import config from "@config/index";
import { CssBaseline, StylesProvider, ThemeProvider, withWidth } from "@material-ui/core";
import { getCheckoutAction } from "@reducers/checkout";
import { loadUserProfileAction } from "@reducers/user";
import "@resources/font.css";
import GlobalStyle from "@resources/styles/global";
import theme from "@resources/styles/theme";
import { loadAppConfigs, registerAllElasticSearch } from "@utils/configHelper";
import { getCookie, VISITOR_ID } from "@utils/cookie";
import { setStore } from "@utils/elasticsearch/config";
import { appWithTranslation } from "@utils/i18n";
import isServer from "@utils/isServer";
import redirect from "@utils/redirect";
import { detectBot, detectIE, getInitWidth, getUserAgent } from "@utils/userAgent";
import useWindow from "@utils/useWindow";
import { checkLocalStorage, findPageNameByPath, removeInjectedCSS, useWistonLoggerOnServer } from "@utils/utils";
import withRedux from "next-redux-wrapper";
import Router from "next/router";
import React, { Component } from "react";
import "react-day-picker/lib/style.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import reactRevealConfig from "react-reveal/globals";
import "react-square-payment-form/lib/default.css";
import configureStore from "../reducers/store";

// https://www.react-reveal.com/docs/
// In some cases, when the javascript bundle arrives much later than the HTML&CSS it might cause a flickering
reactRevealConfig({ ssrFadeout: true });

useWistonLoggerOnServer();
class MyApp extends Component<any> {
  public static async getInitialProps({ Component: Comp, ctx }) {
    const { store, pathname, asPath, query, req, res } = ctx;

    // set es store
    setStore(store);

    // Disable IE
    if (detectIE(getUserAgent(req)) && !pathname.includes("/not-supported")) {
      redirect({ ctx, location: "/not-supported" });
    }

    // Verify age
    const componentAgeVerifyRequired = config.ageVerification && (Comp.ageVerification === undefined || Comp.ageVerification);
    if (!detectBot(getUserAgent(req)) && componentAgeVerifyRequired && !getCookie(VISITOR_ID)) {
      if (!isServer) {
        redirect({ ctx, location: `/age-verification?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}` });
      } else {
        redirect({ ctx, location: `/age-verification?returnUrl=${encodeURIComponent(req.originalUrl)}` });
      }
    }

    let botOnlyProps = {};
    let appProps = Comp.getInitialProps ? await Comp.getInitialProps(ctx) : {};
    if (detectBot(getUserAgent(req))) {
      botOnlyProps = Comp.getInitialPropsForBot ? await Comp.getInitialPropsForBot(ctx) : {};
    }

    await loadAppConfigs();

    return {
      pageProps: { namespacesRequired: appProps?.namespacesRequired || [] },
      appProps: { ...botOnlyProps, ...appProps },
      initialWidth: getInitWidth(req),
      routeProps: { pathname, asPath, query },
    };
  }

  public state = {
    history: [],
  };
  constructor(props) {
    super(props);
    registerAllElasticSearch();
  }

  /**
   *
   * Enforcement replace page instead of going back (state change)
   * This method is required for managing redux state when it goes back page as a client-side rendering.
   *
   */
  public forceRedirect(e) {
    const extra = e.currentTarget.location.href.split("?");
    const pageName = findPageNameByPath(e.currentTarget.location.pathname);
    if (pageName) {
      Router.replace(`${pageName}${extra.length > 1 ? `?${extra[1]}` : ""}`, e.currentTarget.location.href);
    } else {
      Router.replace(`${e.currentTarget.location.pathname}${extra.length > 1 ? `?${extra[1]}` : ""}`, undefined);
    }
  }

  public async componentDidMount() {
    const {
      store: { dispatch },
    } = this.props;

    checkLocalStorage();

    Router.events.on("beforeHistoryChange", (url) => {
      const windowPathname = window.location.pathname.replace(window.location.origin, "");
      const windowSearch = window.location.search && window.location.search.substr(1);
      const windowAsPath = `${window.location.pathname}${window.location.search}`;

      // @ts-ignore
      this.state.history.push({
        // @ts-ignore
        pathname: windowPathname,
        // @ts-ignore
        asPath: windowAsPath,
        // @ts-ignore
        searchParams: windowSearch,
      });
    });

    /**
     * @SSG
     * if want to use SSG only
     */

    // @ts-ignore
    dispatch(loadUserProfileAction()).catch((error) => nextLogger.info());
    // @ts-ignore
    dispatch(getCheckoutAction()).catch((error) => nextLogger.info());

    useWindow.addEventListener("popstate", this.forceRedirect);

    /**
     * Load top message to redux store
     * Subscribe streaming top message change to session storage
     */
    // loadMessageInitialState();
    // @ts-ignore
    // this.unsubscribeMessageListener = writeMessageToSessionStorageSubscriber();

    /**
     * Server side injected css breaks style. Need to be removed https://material-ui.com/guides/server-rendering/
     */
    removeInjectedCSS();
  }

  public componentWillUnmount() {
    // @ts-ignore
    if (this.unsubscribeMessageListener) {
      // @ts-ignore
      this.unsubscribeMessageListener();
    }
    useWindow.removeEventListener("popstate", this.forceRedirect);
  }

  public render() {
    const { Component: Comp, pageProps, initialWidth, appProps, store, routeProps } = this.props;
    const WidthProvider = withWidth({ initialWidth })(({ ...props }: any) => (
      <WidthContext.Provider value={props.width}>{props.children}</WidthContext.Provider>
    ));
    const ServerRouteProvider = ({ ...props }: any) => (
      <ServerRouteContext.Provider value={{ ...routeProps, history: this.state.history }}>{props.children}</ServerRouteContext.Provider>
    );
    setStore(store); // set store

    /**
     * @param pageProps is for new Nextjs features
     * such as
     * @const getStaticProps SSG
     * @const getServerSideProps SSR
     * are required
     */

    /**
     * @param appProps is for getInitialProps of each pages
     */

    return (
      <GoogleReCaptchaProvider reCaptchaKey={config.reCAPTCHA}>
        <Provider store={store}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <WidthProvider>
                <ServerRouteProvider>
                  <CssBaseline />
                  <GlobalStyle />
                  <Comp {...pageProps} {...appProps} />
                </ServerRouteProvider>
              </WidthProvider>
            </ThemeProvider>
          </StylesProvider>
        </Provider>
      </GoogleReCaptchaProvider>
    );
  }
}

export default withRedux(configureStore)(appWithTranslation(MyApp));
