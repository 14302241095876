import Grid, { GridProps } from "@material-ui/core/Grid";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import color from "@resources/styles/color";
import clsx from "clsx";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import WidthContext from "./WidthContext";

export interface GrideLayoutProps extends Omit<GridProps, "style"> {
  /**
   * Inner component style
   */
  style?: React.CSSProperties;

  /**
   * Outter(Grid Layout system) component style, this GridLayout component has default left and right space 4%, 5%, 6% (depends on screen)
   *
   * @see [Grid](https://www.w3schools.com/css/css_grid.asp) information
   */
  outterStyle?: React.CSSProperties;

  /**
   * Set the background image, this 'background' will set to outter component with `url(${background}) 50% 50% / cover no-repeat, grey`
   * __WARN__: do not use it for background colors.
   */
  backgroundImage?: string;

  /**
   * Set the overlay for backgroud
   */
  backgroudOverlay?: boolean;
  /**
   * Set the gap of the inner component has top and bottom padding with 90px and 75px (depends on screen).
   * Also instead of `div`, inner component will change to `section` component
   *
   * web - top: 90px, bottom: 75px
   *
   * mobile - top: 60px, bottom: 55px
   */
  section?: boolean;

  /**
   * Set the space between inner component. default left and right space 4%, 5%, 6% (depends on screen)
   */
  gridSpace?: string;
  children?: ReactNode;
}

const GridStyle = styled.div<any>`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr ${(props) => props.gridSpace} minmax(250px, 1440px) ${(props) => props.gridSpace} 1fr;
  -ms-grid-columns: 1fr ${(props) => props.gridSpace} minmax(250px, 1440px) ${(props) => props.gridSpace} 1fr;

  .grid-1 {
    grid-column: 1;
    -ms-grid-column: 1;
  }
  .grid-2 {
    grid-column: 2;
    -ms-grid-column: 2;
  }
  .grid-3 {
    grid-column: 3;
    -ms-grid-column: 3;
  }
  .grid-4 {
    grid-column: 4;
    -ms-grid-column: 4;
  }
  .grid-5 {
    grid-column: 5;
    -ms-grid-column: 5;
  }
`;

/**
 * ### How to learn the Grid? Here we have [Grid Garden](https://cssgridgarden.com/)!
 * @param style(Inner) Inner component style
 * @param outterStyle(Outter)
 * Outter(Grid Layout system) component style, this GridLayout component has default left and right space 4%, 5%, 6% (depends on screen)
 * @see [Grid information](https://www.w3schools.com/css/css_grid.asp)
 *
 * @param backgroundImage(Outter)
 * Set the background image, this 'background' will set to outter component with `url(${background}) 50% 50% / cover no-repeat, grey`
 * __WARN__: do not use it for background colors.
 *
 * @param section(Inner)
 * Set the gap of the inner component has top and bottom padding with 90px and 75px (depends on screen).
 * Also instead of `div`, inner component will change to `section` component
 *
 * web - top: 90px, bottom: 75px
 *
 * mobile - top: 60px, bottom: 55px
 *
 * @param gridSpace(Outter)
 * Set the space between inner component. default left and right space 4%, 5%, 6% (depends on screen)
 * @extends Grid this inherit all the grid properties
 */
const GrideLayout = React.forwardRef((props: GrideLayoutProps, ref) => {
  const { style, outterStyle, backgroundImage, backgroudOverlay = false, className, children, section, gridSpace, ...rest } = props;
  const width = useContext(WidthContext);
  const mobile = width === "xs";

  // let defaultOuterStyles = {};
  // if (backgroundImage) {
  //   if (backgroudOverlay) {
  //     defaultOuterStyles = {
  //       background: `linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)),
  //  url(${backgroundImage}) 50% 50% / cover no-repeat, ${color.grey}`,
  //       backgroundSize: "cover",
  //     };
  //   } else {
  //     defaultOuterStyles = {
  //       background: `url(${backgroundImage}) 50% 50% / cover no-repeat, ${color.semiWhite}`,
  //       backgroundSize: "cover",
  //     };
  //   }
  // }

  const [defaultOuterStyles, setDefaultOuterStyles] = useState<CSSProperties>({});
  useEffect(() => {
    if (backgroundImage) {
      const imageLoader = new Image();
      imageLoader.src = backgroundImage as string;
      imageLoader.onload = () => {
        if (backgroudOverlay) {
          setDefaultOuterStyles({
            background: `linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)),url(${backgroundImage}) 50% 50% / cover no-repeat, ${color.grey}`,
            backgroundSize: "cover",
          });
        } else {
          setDefaultOuterStyles({
            background: `url(${backgroundImage}) 50% 50% / cover no-repeat, ${color.grey}`,
            backgroundSize: "cover",
          });
        }
      };
    }
  }, []);

  const defaultResponsivePadding = (w) => {
    switch (w) {
      case "xs":
        return "4%";
      case "sm":
        return "5%";
      default:
        return "6%";
    }
  };

  return (
    <GridStyle ref={ref} gridSpace={gridSpace ? gridSpace : defaultResponsivePadding(width)} style={{ ...defaultOuterStyles, ...outterStyle }}>
      <div className="grid-1"></div>
      <div className="grid-2"></div>
      {section ? (
        <Grid
          container
          className={clsx(className, "grid-3")}
          style={{ paddingTop: mobile ? "60px" : "90px", paddingBottom: mobile ? "55px" : "75px", ...style }}
          component="section"
          {...rest}
        >
          {children && children}
        </Grid>
      ) : (
        <Grid className={clsx(className, "grid-3")} container style={style} {...rest}>
          {children && children}
        </Grid>
      )}
      <div className="grid-4"></div>
      <div className="grid-5"></div>
    </GridStyle>
  );
});

export default GrideLayout;
