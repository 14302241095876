// root
export const LANDING = "/";
export const CONTACT_US = "/contact-us";
export const INSURANCE_COVERAGE = "/insurance-coverage";
export const PARTNERS = "/partners";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const CHOOSING_MEDICAL_CANNABIS = "/choosing-medical-cannabis";
export const FAQ = "/faq";
export const MEDICAL_FAQ = "/medical-faq";
export const EDUCATION = "/education";
export const NEWSROOM = "/newsroom";
export const CANNABIS_101 = "/cannabis-101";
export const CORONAVIRUS = "/coronavirus";
export const ARTHRITIS = "/arthritis-clinical-trial";
export const DISCOVERY = "/discovery";
export const UNITED_PROGRAM = "/united";
export const PRIVACYPOLICY = "/privacy-policy";
export const TERMSANDCONDITIONS = "/terms-and-conditions";
export const CANNALOGUE_FORMS = "/cannalogue-forms";
export const CBD_HELP = "/cbdhelp";

// getting-started
export const GETTING_STARTED = "/getting-started";
export const GETTING_STARTED_DOSING_INSTRUCTIONS = `${GETTING_STARTED}/dosing-instructions`;
export const GETTING_STARTED_METHODS_OF_CONSUMPTION = `${GETTING_STARTED}/methods-of-consumption`;
export const GETTING_STARTED_SAFE_USE = `${GETTING_STARTED}/safe-use`;
export const GETTING_STARTED_WHAT_TO_EXPECT = `${GETTING_STARTED}/what-to-expect`;

// about-cannalogue
export const ABOUT_US = "/about-us";
export const ABOUT_CANNALOGUE = "/about-cannalogue";
export const ABOUT_CANNALOGUE_STORY = `${ABOUT_CANNALOGUE}/cannalogue-story`;
export const ABOUT_CANNALOGUE_FOUNDERS = `${ABOUT_CANNALOGUE}/founders`;
export const ABOUT_CANNALOGUE_VISION = `${ABOUT_CANNALOGUE}/vision`;
export const ABOUT_CANNALOGUE_ADVISORY_BOARD = `${ABOUT_CANNALOGUE}/advisory-board`;

// HCPs
export const COMPASSIONATE_CARE = "/compassionate-care";
export const AFFILIATE_PROGRAM = "/affiliate-program";
export const CLINICAL_TRIALS_RESEARCH = "/research";
export const WHY_CONSIDER_CANNALOGUE = "/why-consider-cannalogue";
export const QUICK_GUIDE_MEDICAL_CANNABIS = "/quick-guide-medical-cannabis";

// products
export const PRODUCTS_HREF = "/collections/[collection]";
export const PRODUCTS = "/collections/cannabis";
export const HEMP = "/collections/hemp";
export const ACCESSORIES = "/collections/accessories";
export const PPE = "/collections/ppe";
export const PRODUCTS_DETAIL_HREF = "/products/[productId]";
export const PRODUCTS_DETAIL = (productId: string) => `/products/${productId}`;

// my-account
export const MY_ACCOUNT = "/my-account";
export const MY_ACCOUNT_ACCOUNT_INFO = `${MY_ACCOUNT}/account-info`;
export const MY_ACCOUNT_INVOICES = `${MY_ACCOUNT}/invoices`;
export const MY_ACCOUNT_MEDICAL_INFO = `${MY_ACCOUNT}/medical-info`;
export const MY_ACCOUNT_HEALTH_PROFILE = `${MY_ACCOUNT}/health-profile`;
export const MY_ACCOUNT_NEWSLETTER = `${MY_ACCOUNT}/newsletter-preference`;
export const MY_ACCOUNT_ORDER_RETURNS = `${MY_ACCOUNT}/order-returns`;
export const MY_ACCOUNT_ORDER_RETURNS_DETAIL = (orderId: string): string => `${MY_ACCOUNT_ORDER_RETURNS}/detail?id=${orderId}`;

export const MY_ACCOUNT_RECURRING_ORDERS = `${MY_ACCOUNT}/recurring-orders`;
export const MY_ACCOUNT_NEW_RECURRING_ORDER = (orderId: string): string => `${MY_ACCOUNT}/recurring-orders/new?order_id=${orderId}`;
export const MY_ACCOUNT_RECURRING_ORDER_DETAIL = (recurringOrderId: string): string => `${MY_ACCOUNT}/recurring-orders/detail?id=${recurringOrderId}`;
export const MY_ACCOUNT_RECURRING_ORDER_HISTORY = (recurringOrderId: string): string => `${MY_ACCOUNT}/recurring-orders/history?id=${recurringOrderId}`;

export const MY_ACCOUNT_PAYMENT_INFO = `${MY_ACCOUNT}/payment-info`;
export const MY_ACCOUNT_PROMOTIONS = `${MY_ACCOUNT}/promotions`;
export const MY_ACCOUNT_ADDRESSES = `${MY_ACCOUNT}/shipping-address`;
export const MY_ACCOUNT_TRACK_RESULTS = `${MY_ACCOUNT}/track-results`;
export const MY_ACCOUNT_TRACK_RESULTS_DETAIL = (sessionId: string | number) => `${MY_ACCOUNT}/track-results/${sessionId}`;
export const MY_ACCOUNT_TRACK_RESULTS_NEW = `${MY_ACCOUNT}/track-results/new`;

// registration
export const REGISTRATION = "/registration";
export const REGISTRATION_SIGNUP = `${REGISTRATION}/signup`;
export const REGISTRATION_TRANSFER = `${REGISTRATION}/transfer`;
export const REGISTRATION_TRANSFER_VERIFIED = `${REGISTRATION}/transfer-email-verified`;
export const REGISTRATION_EMAIL_VERIFIED = `${REGISTRATION}/email-verified`;

// checkout
export const SHOPPING_CART = "/shopping-cart";
export const CHECKOUT_SHIPPING_INFO = "/checkout/shipping-info";
export const CHECKOUT_PAYMENT_INFO = "/checkout/payment-info";
export const CHECKOUT_REVIEW = "/checkout/review";
export const CHECKOUT_COMPLETE_HREF = `/checkout/complete/[checkoutNumber]`;
export const CHECKOUT_COMPLETE = (checkoutNumber: string) => `/checkout/complete/${checkoutNumber}`;

export const SPECIAL_OFFERS = "/special-offers";
export const LOCAL_DELIVERY = "/local-delivery";

// drup
export const PARTNER_WITH_US = "/supplier";
