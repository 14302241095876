import { GridLayout, LinkButton, Row, StyledParagraph } from "@components/general";
import Logo from "@components/general/Logo";
import WidthContext from "@components/general/WidthContext";
import { Grid } from "@material-ui/core";
import { FooterItemType } from "@reducers/navigation";
import { StoreProps } from "@reducers/store";
import { color } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import React, { useContext } from "react";
import { useSelector } from "react-redux";

interface FooterColumnProps {
  title: string;
  items?: FooterItemType[];
}

const FooterColumn: React.FC<FooterColumnProps> = ({ title = "", items = [] }) => {
  const { tOrNull: t } = useTranslation("components/footer");
  return (
    <Grid item xs={6} md="auto">
      <Row>
        <StyledParagraph align="left" style={{ fontSize: 13, fontWeight: "bold" }} component="h2">
          {t(title)}
        </StyledParagraph>
      </Row>
      {items.map((item, idx) => {
        if (t(item.name)) {
          return (
            <Row key={idx}>
              {item?.link ? (
                <LinkButton
                  varients="link"
                  to={item.link}
                  style={{ fontWeight: 400, fontSize: 13, color: color.realWhite, fontFamily: "Proxima Nova W01 Regular" }}
                >
                  {t(item.name)}
                </LinkButton>
              ) : (
                <StyledParagraph align="left" size={13} guideline="p4">
                  {t(item.name)}
                </StyledParagraph>
              )}
            </Row>
          )
        } else {
          return null;
        }
      })}
    </Grid>
  );
};

const Footer = ({ extraFooterComponent: ExtraFooterComponent }) => {
  const { footer } = useSelector((state: StoreProps) => state.navigation);
  const width = useContext(WidthContext);
  const tablet = width === "xs" || width === "sm";
  const { t } = useTranslation("components/footer");
  return (
    <footer>
      <GridLayout style={{ marginBottom: 50 }} outterStyle={{ background: color.blueGradientForFooter }}>
        <Grid container justify="center">
          <Grid item xs={10} md={12}>
            {ExtraFooterComponent && <ExtraFooterComponent />}

            {/* Logo & Footer columns */}
            <Grid
              container
              justify="space-between"
              style={tablet ? { marginTop: 40, marginBottom: 25 } : { marginTop: 60, marginBottom: 60 }}
              spacing={1}
            >
              <Grid item xs={12} md={4} lg={3}>
                <Row justify="flex-start" alignItems="center" spacing={2}>
                  <Grid item>
                    <Logo white={true} mini={true} />
                  </Grid>
                  <Grid item>
                    <StyledParagraph align="left" guideline="p1" dangerouslySetInnerHTML={{ __html: t("slogan") }} />
                  </Grid>
                </Row>
                <Row>
                  <StyledParagraph align="left" guideline="p4">
                    {t("slogan_description")}
                  </StyledParagraph>
                </Row>
              </Grid>

              <Grid container justify="space-between" item spacing={2} xs={12} md={8} lg={6}>
                {footer.navigation?.map((column) => (
                  <FooterColumn key={column.name} title={column.name} items={column.children} />
                ))}
              </Grid>
            </Grid>

            {/* copyright  */}
            <Grid container justify="space-between" alignItems="center" spacing={5}>
              <Grid item>
                <StyledParagraph align="left" guideline="p1">
                  <span className="bold">{t("support.part1")}</span>
                  {tablet ? <br /> : "  "}
                  <span dangerouslySetInnerHTML={{ __html: t("support.part2") }} />
                </StyledParagraph>
              </Grid>
              <Grid item>
                <StyledParagraph guideline="p4">{t("copyright")}</StyledParagraph>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridLayout>
    </footer>
  );
};

export default Footer;
