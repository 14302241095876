import * as ROUTE from "@resources/routeConst";

const config = {
  rootCategory: "Accessories",
  collectionUrl: ROUTE.ACCESSORIES,
  elasticsearch: {
    name: "accessories",
    url: `/api/search/`,
    indices: ["products"],
    baseQuery: {
      bool: {
        should: [
          {
            prefix: {
              "categories.raw": "Accessories"
            }
          }
        ]
      }
    },
    keywordSearch: true, // for display or not in keyword search
  },
  sortOptions: [
    { value: "-manual_weight", title: "sorter.sort_option.relevance" },
    { value: "price", title: "sorter.sort_option.price_low_high" },
    { value: "-price", title: "sorter.sort_option.price_high_low" },
    { value: "product_specs/brand", title: "sorter.sort_option.brand_az" },
    { value: "-product_specs/brand", title: "sorter.sort_option.brand_za" },
  ],
  filterOptions: [
    {
      component: `FreeShippingFilter`,
    },
    {
      component: `InStockFilter`,
    },
    {
      component: `CategoryFilter`,
      prefix: "Accessories",
      react: ["Brand", "Price"]
    },
    {
      component: `MultiSelectFilter`,
      id: "Brand",
      name: "Brand",
      showCount: true,
      dataField: "product_specs/brand",
      react: ["Category"]
    },
    {
      component: `RangeSliderFilter`,
      id: "Price",
      name: "Price",
      dynamic: true,
      dataField: "price",
      textBox: {
        textAlign: "left",
        startAdornment: "$",
      }
    },
  ],
  resultComponent: {
    component: `SearchResultCard`,
    showIcon: false,
    showSpecs: [],
  }
};

export default config;
