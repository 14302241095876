import { LinkButton, ParagraphButton, PrimaryButton, Row, SmartSpinner } from "@components/general";
import { Fade, Grid, Paper, Popover } from "@material-ui/core";
import IconAccount from "@resources/images/IconAccount.svg";
import * as ROUTE from "@resources/routeConst";
import { color } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import React, { useState } from "react";
import styled from "styled-components";

const ArrowUp = styled.div<any>`
  width: 20px;
  height: 20px;
  border-top: 1px solid ${color.greyBorderOpacity(0.6)};
  border-bottom: 0px solid ${color.greyBorderOpacity(0.6)};
  border-right: 0px solid ${color.greyBorderOpacity(0.6)};
  border-left: 1px solid ${color.greyBorderOpacity(0.6)};
  top: -10px;
  left: ${(props) => props.left}px;
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background: #fff;
`;

const MobileUserPopover = () => {
  const [visible, setVisible] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation(["components/navigation"]);

  const getOffset = () => {
    let _x = 100;
    if (anchorRef && anchorRef.current) {
      _x = anchorRef.current.offsetLeft - anchorRef.current.scrollLeft + 38;
    }
    return _x;
  };

  return (
    <div ref={anchorRef} style={{ padding: "10px 0" }}>
      <SmartSpinner size={20}>
        <IconAccount onClick={() => setVisible(!visible)} />
        <Grid container>
          <Popover
            open={visible}
            elevation={0}
            keepMounted
            anchorEl={anchorRef.current}
            onClose={() => setVisible(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transitionDuration={{
              enter: 0,
              exit: 0,
            }}
            PaperProps={{
              style: {
                overflowX: "visible",
                overflowY: "visible",
                backgroundColor: "transparent",
              },
            }}
          >
            <Fade in={visible} timeout={{ enter: 450, exit: 150 }}>
              <div style={{ marginTop: 10 }}>
                <div style={{ position: "fixed", width: "100%", left: 0 }}>
                  <div style={{ position: "relative" }}>
                    <ArrowUp left={getOffset()} />
                  </div>
                </div>
                <Paper style={{ width: 300, padding: "5px 20px" }} variant="outlined" elevation={4}>
                  <Row>
                    <Grid item xs={12}>
                      <PrimaryButton to={ROUTE.LOGIN} fullWidth>
                        {t("mobile.login_popover.login")}
                      </PrimaryButton>
                    </Grid>
                  </Row>
                  <Row>
                    <Grid item xs={12}>
                      <PrimaryButton to={ROUTE.REGISTRATION_SIGNUP} secondary fullWidth>
                        {t("mobile.login_popover.sign_up")}
                      </PrimaryButton>
                    </Grid>
                  </Row>
                </Paper>
              </div>
            </Fade>
          </Popover>
        </Grid>
      </SmartSpinner>
    </div>
  );
};

export default MobileUserPopover;
