import Grid from "@material-ui/core/Grid";
import { color } from "@resources/styles";
import React, { useContext } from "react";
import styled from "styled-components";
import { Row } from "./CannalogueGrid";
import WidthContext from "./WidthContext";

const Card = styled(({ children, ...props }) => (
  <Row {...props} justify="center">
    <Grid item xs={11} style={{ height: "100%" }} >
      {children}
    </Grid>
  </Row>
))`
  padding: ${(props) => props.padding};
  border-radius: 5px;
  background-color: ${color.realWhite};
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const StyledCard: React.FC<any> = ({ children, ...props }) => {
  const width = useContext(WidthContext);
  const mobile = width === "xs" || width === "sm";
  const padding = mobile ? "20px 0px" : "34px 0px";
  return (
    <Card {...props} padding={padding}>
      {children}
    </Card>
  );
};

export default StyledCard;
