import { StyledParagraph } from "@components/general";
import { color } from "@resources/styles";
import React from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";

interface ListProps {
  children?: React.ReactNode;
  list?: any[];
  style?: any;
  fade?: "left" | "right" | "clear" | "top" | "bottom" | null;
  delay?: number;
  orderView: boolean;
}

const StyledLi = styled.li<any>`
  padding-top: ${(props) => (props.index > 0 ? "10px" : "0px")};
`;

const StyledUl = styled.ul`
  padding-left: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  & p {
    padding-left: 10px;
  }
`;

const StyledOl = styled.ol`
  padding-left: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  & p {
    padding-left: 10px;
  }
`;

const List: React.FC<ListProps> = ({ children, list = [], orderView, fade, delay, style }) => {
  if (fade) {
    const direction = {};
    direction[fade] = true;
    return !orderView ? (
      <Fade {...direction} delay={delay ? delay : 300} duration={1000}>
        <StyledUl style={style}>
          {list.map((element, index) => (
            <StyledLi key={index} index={index}>
              <StyledParagraph align="left" size={16} lineHeight={24} color={color.black} dangerouslySetInnerHTML={{ __html: element }} />
              {children}
            </StyledLi>
          ))}
        </StyledUl>
      </Fade>
    ) : (
      <Fade {...direction} delay={delay ? delay : 300} duration={1000}>
        <StyledOl>
          {list.map((element, index) => (
            <StyledLi key={index} index={index}>
              <StyledParagraph align="left" size={16} lineHeight={24} color={color.black} dangerouslySetInnerHTML={{ __html: element }} />
              {children}
            </StyledLi>
          ))}
        </StyledOl>
      </Fade>
    );
  }
  return !orderView ? (
    <StyledUl>
      {list.map((element, index) => (
        <StyledLi key={index} index={index}>
          <StyledParagraph align="left" size={16} lineHeight={24} color={color.black} dangerouslySetInnerHTML={{ __html: element }} />
          {children}
        </StyledLi>
      ))}
    </StyledUl>
  ) : (
    <StyledOl>
      {list.map((element, index) => (
        <StyledLi key={index} index={index}>
          <StyledParagraph align="left" size={16} lineHeight={24} color={color.black} dangerouslySetInnerHTML={{ __html: element }} />
          {children}
        </StyledLi>
      ))}
    </StyledOl>
  );
};

export default List;
