import { LinkButton, Row, StyledParagraph, WidthContext } from "@components/general";
import Arrow from "@components/general/Arrow";
import { Avatar, Grid } from "@material-ui/core";
import { MenuItemType } from "@reducers/navigation";
import { StoreProps } from "@reducers/store";
import { logoutAction } from "@reducers/user";
import * as ROUTE from "@resources/routeConst";
import { color } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import { useLogout } from "@utils/services";
import { getInitial, getRoute } from "@utils/utils";
import _ from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const menuRowStyle = {
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 10,
  borderBottom: `1px solid ${color.greyBorderOpacity(0.4)}`,
};

interface NodeType extends MenuItemType {
  parent?: NodeType;
  namechain: string[];
  children?: NodeType[];
}

function sortMenu(menu) {
  const top = menu.filter((m) => m.position === "top");
  const left = menu.filter((m) => m.position === "left");
  const right = menu.filter((m) => m.position === "right");
  const rest = menu.filter((m) => !["top", "left", "right"].includes(m.position));
  return [...top, ...left, ...right, ...rest];
}

// BFS load menu as tree in memeory
const constructMenuTree = (menu) => {
  const clonedMenu: NodeType[] = _.cloneDeep(menu); // !IMPORTANT, to prevent overwrite redux store

  // add a top node in order to keep the first level sibling info
  const rootNode: any = {
    namechain: [],
    name: "",
    children: sortMenu(clonedMenu),
  };

  const traverseQueue = [rootNode];
  while (!_.isEmpty(traverseQueue)) {
    const node = traverseQueue.pop();
    if (node) {
      if (_.isEmpty(node.namechain)) {
        node.namechain = node.name ? [node.name] : [];
      }

      node.children?.forEach((child) => {
        child.parent = node;
        child.namechain = [...node.namechain, child.name];
        traverseQueue.push(child);
      });
    }
  }
  return [rootNode];
};

const findNodeByPath = (menuTree, path) => {
  const traverseQueue = [_.cloneDeep(menuTree)[0]];
  let currentNode = null;
  while (!_.isEmpty(traverseQueue)) {
    const node = traverseQueue.pop();
    if (node) {
      if (path === node?.link) {
        currentNode = node;
        break;
      }

      node.children?.forEach((child) => {
        if (path === child?.link) {
          currentNode = child;
          return;
        } else {
          traverseQueue.push(child);
        }
      });
    }
  }
  return currentNode;
};

const removeFromLastCharter = (s, char = "/") => {
  if (!s) return "";
  return s.slice(0, s.lastIndexOf(char));
};

const getSiblings = (node): any => {
  return node?.parent?.children;
};

const MobileMenu: React.FC = () => {
  const router = useRouter();
  const [__, logout] = useLogout();
  const { header, loaded } = useSelector((state: StoreProps) => state.navigation);
  const [menuList, setMenuList] = useState<NodeType[]>();

  const width = useContext(WidthContext);
  const mobile = width === "xs";
  const user = useSelector((state: StoreProps) => state.user);
  const dispatch = useDispatch();
  const { t, tOrNull } = useTranslation(["components/navigation"]);

  useEffect(() => {
    const menu = header.menu;
    if (menu) {
      const menuTree = constructMenuTree(menu);
      const currentNode = findNodeByPath(menuTree, router.asPath);
      if (currentNode) {
        setMenuList(getSiblings(currentNode));
      } else {
        setMenuList(getSiblings(menuTree[0].children[0]));
      }
    }
  }, [loaded]);

  const handleSignout = () => {
    dispatch(logoutAction());
    logout();
  };

  const shouldHighlight = (node) => {
    if (router.pathname.includes("[")) {
      if (node.link?.includes("?")) {
        return false;
      } else {
        return removeFromLastCharter(router.pathname) === removeFromLastCharter(node.link);
      }
    } else {
      return router.pathname === node.link;
    }
  };

  return (
    <Grid container style={{ width: mobile ? "75vw" : "50vw" }}>
      {/* menu header */}
      <Grid item xs={12} style={{ backgroundColor: color.blue, paddingLeft: 15 }}>
        <Row>
          {menuList?.[0] && menuList[0]?.parent?.parent ? (
            <>
              <Row item style={{ marginLeft: -10 }}>
                <StyledParagraph
                  align="left"
                  guideline="p2"
                  variant="body2"
                  onClick={() => {
                    setMenuList(getSiblings(menuList[0].parent));
                  }}
                >
                  <Arrow style={{ fill: color.realWhite }} direction="left" /> {t("common:common.button.back")}
                </StyledParagraph>
              </Row>
              <Grid container item>
                <StyledParagraph align="left" guideline="p2" variant="body2">
                  {tOrNull(`${menuList[0]?.parent?.namechain.join(".")}.title`) || menuList[0]?.parent?.name}
                </StyledParagraph>
              </Grid>
            </>
          ) : (
            <Row
              item
              alignItems="center"
              justify="space-between"
              style={{ flexWrap: "nowrap" }}
              onClick={() => {
                if (user.first_name) {
                  const myAccountNode = menuList?.filter((item) => item.name === "my_account")[0];
                  if (myAccountNode && myAccountNode.children) {
                    setMenuList(myAccountNode.children);
                  }
                }
              }}
            >
              <Grid item container spacing={3}>
                <Grid item>
                  <Avatar style={{ height: 50, width: 50 }}>{getInitial(user)}</Avatar>
                </Grid>
                <Grid item>
                  <StyledParagraph align="left" guideline="p1">
                    {t("mobile.welcome")}
                  </StyledParagraph>
                  <StyledParagraph align="left" guideline="p1">
                    {user.first_name ? (
                      <span className="bold">
                        {_.capitalize(user.first_name)} {_.capitalize(user.last_name)}
                      </span>
                    ) : (
                      <span>
                        <Link href={ROUTE.LOGIN}>
                          <a style={{ color: color.white, border: "none" }}>
                            <span className="bold">{t("login")}</span>
                          </a>
                        </Link>
                        &nbsp;or&nbsp;
                        <Link href={ROUTE.REGISTRATION_SIGNUP}>
                          <a style={{ color: color.white, border: "none" }}>
                            <span className="bold">{t("register")}</span>
                          </a>
                        </Link>
                      </span>
                    )}
                  </StyledParagraph>
                </Grid>
              </Grid>
              {user.first_name && (
                <Grid xs={2} item>
                  <Arrow style={{ fill: color.realWhite }} />
                </Grid>
              )}
            </Row>
          )}
        </Row>
      </Grid>

      {/* menu content list */}
      <Grid item xs={12}>
        <Row style={{ paddingLeft: 15 }}>
          {_.map(
            menuList?.filter((menuItem) => menuItem?.hide !== "mobile"),
            (item, index) => {
              let menuItemName = tOrNull([...item.namechain, "title"].join("."));
              if (!menuItemName) {
                menuItemName = tOrNull(item.namechain.join("."));
              }
              if (!menuItemName) {
                menuItemName = item.name;
              }
              if (item?.children && !_.isEmpty(item.children)) {
                return (
                  <Row
                    style={menuRowStyle}
                    item
                    key={`${item.name}_${index}`}
                    justify="space-between"
                    alignItems="center"
                    onClick={() => {
                      if (item.children) {
                        setMenuList(item.children);
                      }
                    }}
                  >
                    <Grid item>
                      <StyledParagraph guideline="p2" color={color.black} variant="body2">
                        {menuItemName}
                      </StyledParagraph>
                    </Grid>
                    <Grid item>
                      <Arrow style={{ fill: color.black }} />
                    </Grid>
                  </Row>
                );
              } else {
                return (
                  <Row item alignItems="center" style={menuRowStyle} key={`${item.name}_${index}`}>
                    <LinkButton
                      varients="button"
                      key={`${item.name}_${index}`}
                      {...getRoute(item?.link)}
                      paragraphProps={{
                        guideline: "p2",
                        color: shouldHighlight(item) ? color.blue : color.black,
                        align: "left",
                      }}
                    >
                      {menuItemName}
                    </LinkButton>
                  </Row>
                );
              }
            }
          )}

          {/* Custom addon items */}
          {menuList?.[0]?.parent?.name === "my_account" && (
            <Row item onClick={handleSignout} style={menuRowStyle}>
              <StyledParagraph guideline="p2" color={color.black} variant="body2">
                {t("my_account.sign_out")}
              </StyledParagraph>
            </Row>
          )}

          {menuList?.[0]?.parent?.button && (
            <Row item style={menuRowStyle}>
              <LinkButton varients="button" {...getRoute(menuList[0].parent.button.link)}>
                <StyledParagraph guideline="p2" color={color.black} variant="body2">
                  {t(menuList[0].parent.button.name)}
                </StyledParagraph>
              </LinkButton>
            </Row>
          )}
        </Row>
      </Grid>
    </Grid>
  );
};

export default MobileMenu;
