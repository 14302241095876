import { color } from "@resources/styles";
import React from "react";
import StyledOutlinedFormControl from "./StyledOutlinedFormControl";
import StyledSelect from "./StyledSelect";

const GeneralSelectField = ({ name, value, onChange, noErrors = false, children, ...custom }) => {
  return (
    <StyledOutlinedFormControl>
      <StyledSelect
        style={{ color: value ? color.black : color.greyDarker }}
        value={value}
        {...custom}
        onChange={(evt) => onChange(evt.target.value)}
        fullWidth
        displayEmpty
      >
        {children}
      </StyledSelect>
    </StyledOutlinedFormControl>
  );
};

export default GeneralSelectField;
