import { Row, StyledParagraph } from "@components/general";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid } from "@material-ui/core";
import IconMinus from "@resources/images/IconMinus.svg";
import { color } from "@resources/styles";
import _ from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

const StyledIconMinus = styled(IconMinus)`
  fill: ${color.grey};
`;

const AnswerPanel = styled(({ faq, category, index, lastIndex, setSelection, ...others }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <ExpansionPanel {...others} square onChange={(evt, expanded) => setIsExpanded(expanded)}>
      <ExpansionPanelSummary style={{ padding: 0 }} aria-controls="panel1a-content" id={category}>
        <Grid container justify="center">
          <Row item xs={10} sm={12} justify="space-between" alignItems="center">
            <Grid item>
              <StyledParagraph guideline="h3" align="left" color={color.black}>
                {category}
              </StyledParagraph>
            </Grid>
            <Grid style={{ marginRight: 15 }} item>
              <StyledParagraph size={40} align="left" color={color.grey}>
                {isExpanded ? <StyledIconMinus /> : "+"}
              </StyledParagraph>
            </Grid>
          </Row>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: 0 }}>
        <Grid container justify="center">
          <Row item xs={10} sm={12}>
            {faq[category].map((data, i) => (
              <Row
                style={{
                  paddingBottom: 50,
                  paddingTop: i === 0 ? 20 : 50,
                  borderBottom: faq[category].length - 1 === i ? "unset" : `0.5px solid ${color.greyBorder}`,
                }}
                key={i}
                justify="space-between"
              >
                <Grid style={{ marginBottom: 10 }} item xs={12} sm={5}>
                  <StyledParagraph guideline="h3" align="left" color={color.blue}>
                    {data.question}
                  </StyledParagraph>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                </Grid>
              </Row>
            ))}
          </Row>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
})`
  border-top: 0.5px solid ${color.greyBorder};
  border-bottom: ${(props) => (props.lastIndex === props.index ? `0.5px solid ${color.greyBorder}` : "unset")};
  box-shadow: none;
  background-color: unset;
`;

export default AnswerPanel;
