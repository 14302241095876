import config from "@config/index";

const cannalogueColor = {
  primary: "#36C0CC",
  primaryDarker: "#30B4C0",
  secondary: "#D6D6EC",
  secondaryOpacity: (opacity: number) => `rgba(214, 214, 236, ${opacity})`,
  blue: "#5E69B1",
  red: "#E02020",
  gold: "#FDDFB1",
  teal: "#e6fffb",
  error: "#833471",
  purple: "#d3adf7",
  darkPurple: "#722ed1",
  blueOpcity: (opacity: number) => `rgba(214, 214, 236, ${opacity})`,
  realWhite: "#FFFFFF",
  white: "#FCFCFE",
  whiteOpcity: (opacity: number) => `rgba(255, 255, 255, ${opacity})`,
  semiWhite: "#F4F4FA",
  grey: "#d2d2d2",
  greyOpacity: (opacity: number) => `rgba(205,203,203, ${opacity})`,
  greyBorder: "#CDCBCB",
  greyBorderOpacity: (opacity: number) => `rgba(205,203,203, ${opacity})`,
  greyDarker: "grey",
  grayLighter: "rgba(210, 210, 210, 0.4)",
  black: "#000000",
  semiBlack: "#6C6A6A",
  yellow: "#F9B846",
  yellowOpacity: (opacity: number) => `rgba(249,184,70, ${opacity})`,
  blueGradient: "linear-gradient(139.17deg, #5E69B1 0%, #797FBD 100%)",
  blueGradientForFooter: "linear-gradient(133.63deg, #5E69B1 0%, #4f88bb 100%)",
  blueGradientForReg: "linear-gradient(133.63deg, #5E69B1 0%, #36C0CC 100%)",
  blueGradientFroAgeVerification: "linear-gradient(129.93deg, #5E69B1 0%, #797FBD 100%)"
};

const drupColor = {
  primary: "#FDB501",
  primaryDarker: "#c58600",
  secondary: "#3850A2",
  secondaryOpacity: (opacity: number) => `rgba(56, 80, 162, ${opacity})`,
  blue: "#000000",
  red: "#D52525",
  gold: "#FDDFB1",
  teal: "#e6fffb",
  error: "#833471",
  purple: "#d3adf7",
  darkPurple: "#722ed1",
  blueOpcity: (opacity: number) => `rgba(0, 129, 251, ${opacity})`,
  realWhite: "#FFFFFF",
  white: "#FCFCFE",
  whiteOpcity: (opacity: number) => `rgba(255, 255, 255, ${opacity})`,
  semiWhite: "#F4F4FA",
  grey: "#d2d2d2",
  greyOpacity: (opacity: number) => `rgba(205,203,203, ${opacity})`,
  greyBorder: "#CDCBCB",
  greyBorderOpacity: (opacity: number) => `rgba(205,203,203, ${opacity})`,
  greyDarker: "grey",
  grayLighter: "rgba(210, 210, 210, 0.4)",
  black: "#050505",
  semiBlack: "#6C6A6A",
  yellow: "#F9B846",
  yellowOpacity: (opacity: number) => `rgba(249,184,70, ${opacity})`,
  blueGradient: "linear-gradient(139.17deg, #000000 0%, #000000 100%)",
  blueGradientForFooter: "linear-gradient(133.63deg, #000000 0%, #000000 100%)",
  blueGradientForReg: "linear-gradient(133.63deg, #000000 0%, #000000 100%)",
  blueGradientFroAgeVerification: "linear-gradient(129.93deg, #000000 0%, #000000 100%)"
};

const color = config.drup ? drupColor : cannalogueColor;
export default color;
