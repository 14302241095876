import { cannalogueAlert } from "@reducers/alerts";
import { i18n } from "@utils/i18n";
import { withCancelToken } from "@utils/services";
import Axios, { CancelTokenSource } from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";

export const useHttpRequest = (apiCallFunc, show = false) => {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const request = async (...args) => {
    const genericErrMsg = i18n.t("common:common.validation_error.general");
    try {
      setLoading(true);
      const res = await apiCallFunc(...args);
      setResponse(res);
      setError(null);
      setLoading(false);
      if (show) {
        cannalogueAlert.success(res.message);
      }
      return res;
    } catch (err: any) {
      setResponse(null);
      setLoading(false);
      setError(err);
      if (err.response && err.response.status >= 500) {
        cannalogueAlert.error(genericErrMsg);
      } else {
        // @ts-ignore
        nextLogger.error(err);
      }
      throw err;
    }
  };

  return { response, error, loading, request };
};

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
interface FetchReturnType<T extends (...args: any) => any> {
  response?: ThenArg<ReturnType<T>>;
  error: any;
  loading: boolean;
  reload: (...args: Parameters<T>) => void;
}

interface Options<T extends (...args: any) => any> {
  show?: boolean;
  initialValue?: ThenArg<ReturnType<T>>;
}

export const useFetchData = <F extends (...args: any) => any>(apiCallFunc: F, options: Options<F> = { show: false }, ...params: Parameters<F>):
  FetchReturnType<F> => {
  const [response, setResponse] = useState<ThenArg<ReturnType<F>> | undefined>(options.initialValue);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (...reloadArgs: Parameters<F>) => {
    try {
      setLoading(true);
      const res = await apiCallFunc(...(_.isEmpty(reloadArgs) ? params : reloadArgs));
      setResponse(res);
      setError(null);
      setLoading(false);
      if (options.show) { cannalogueAlert.success(res.message); }
      return res;
    } catch (err: any) {
      setResponse(undefined);
      setLoading(false);
      setError(err);
      if (err.response && err.response.status >= 500) {
        cannalogueAlert.error("System error! Please try later");
      } else {
        // @ts-ignore
        nextLogger.error(err);
      }
    }
  };

  useEffect(() => {
    let request: CancelTokenSource | null = null;
    if (!options.initialValue && (params.length === 0 || _.compact(params).length > 0)) {
      request = withCancelToken(fetchData);
    }

    return () => {
      request?.cancel();
    };
  }, []);

  const reload: (...args: Parameters<F>) => void = (...args) => {
    // update timestamp to trigger reload
    setLoading(true);
    fetchData(...args);
  };

  return { response, error, loading, reload };
};
