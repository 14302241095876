import { GeneralCheckBoxField } from "@components/form";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import React from "react";

export interface ItemOptions {
  value: string | boolean | number;
  label: string;
}

export interface MultiSelectViewProps {
  options?: ItemOptions[];
  value?: string[];
  singleSelect?: boolean;
  onChange: (values: any) => void;
}

const MultiSelectView: React.FC<MultiSelectViewProps> = ({ options = [], value = [], onChange, singleSelect = false }) => {
  const handleChange = (val: string, selected: boolean) => {
    if (singleSelect && onChange) {
      return onChange(selected ? [val] : []);
    }

    let newValue = [...value];
    if (selected && value.includes(val)) {
      newValue = [...value];
    }

    if (selected && !value.includes(val)) {
      newValue = [...value, val];
    }

    if (!selected && value.includes(val)) {
      newValue = value.filter((e) => e !== val);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  const isSelected = (val: string) => {
    return value.includes(val);
  };

  const getLabel = (label) => {
    const splited = label.split("/");
    if (splited.length > 1) {
      return splited[splited.length - 1];
    }
    return label;
  };

  return (
    <Grid container>
      {options.map((opt) => (
        <GeneralCheckBoxField
          key={opt.value as string}
          name={opt.value as string}
          value={isSelected(opt.value as string)}
          onChange={(selected) => handleChange(opt.value as string, selected)}
          label={getLabel(opt.label)}
        />
      ))}
    </Grid>
  );
};

export default React.memo(MultiSelectView, _.isEqual);
