import * as ROUTE from "@resources/routeConst";
import _ from "lodash";

const conditons = [
  {
    name: "Anxiety or Stress",
    Dried: { thc: [12, 22], cbd: [0, 6] },
    Oil: { thc: [0, 20], cbd: [11, 65] },
    Capsules: { thc: [0, 20], cbd: [9, 65] }
  },
  {
    name: "Arthritis or Stiffness",
    Dried: { thc: [12, 21], cbd: [0, 4] },
    Oil: { thc: [0, 23], cbd: [11, 25] },
    Capsules: { thc: [0, 23], cbd: [9, 25] }
  },
  {
    name: "Difficulty Concentrating",
    Oil: { thc: [0, 2], cbd: [1, 25] },
    Capsules: { thc: [0, 2], cbd: [1, 25] }
  },
  {
    name: "Difficulty Sleeping",
    Dried: { thc: [11, 26], cbd: [0, 1] },
    Oil: { thc: [14, 29], cbd: [0, 1] },
    Capsules: { thc: [1, 29], cbd: [0, 1] }
  },
  {
    name: "Fatigue or Weakness",
    Dried: { thc: [9, 21], cbd: [0, 6] },
    Oil: { thc: [0, 1], cbd: [1, 27] },
    Capsules: { thc: [0, 1], cbd: [1, 27] }
  },
  {
    name: "Feeling Depressed",
    Oil: { thc: [11, 25], cbd: [0, 27] },
    Capsules: { thc: [1, 25], cbd: [0, 27] }
  },
  {
    name: "Headaches or Migraines",
    Dried: { thc: [14, 24], cbd: [0, 5] },
    Oil: { thc: [0, 10], cbd: [11, 26] },
    Capsules: { thc: [0, 10], cbd: [9, 26] }
  },
  {
    name: "Lack of Appetite",
    Dried: { thc: [9, 24], cbd: [0, 2] },
    Oil: { thc: [0, 8], cbd: [18, 28] },
    Capsules: { thc: [0, 10], cbd: [0, 8] }
  },
  {
    name: "Low Sex Drive",
    Dried: { thc: [8, 23], cbd: [0, 1] },
  },
  {
    name: "Menstrual Cramps",
    Dried: { thc: [14, 23], cbd: [0, 16] },
    Oil: { thc: [0, 2], cbd: [18, 25] },
    Capsules: { thc: [0, 2], cbd: [9, 25] }
  },
  {
    name: "Muscle Spasms",
    Dried: { thc: [0, 7], cbd: [9, 22] },
  },
  {
    name: "Nausea or Vomiting",
    Dried: { thc: [0, 10], cbd: [6, 19] },
    Oil: { thc: [0, 4], cbd: [20, 65] },
    Capsules: { thc: [0, 4], cbd: [9, 65] }
  },
  {
    name: "Pain - Abdominal",
    Dried: { thc: [8, 20], cbd: [0, 4] },
    Oil: { thc: [0, 6], cbd: [7, 26] },
    Capsules: { thc: [0, 6], cbd: [7, 26] }
  },
  {
    name: "Pain - General",
    Dried: { thc: [8, 24], cbd: [0, 3] },
    Oil: { thc: [0, 3], cbd: [16, 28] },
    Capsules: { thc: [0, 3], cbd: [9, 28] }
  },
  {
    name: "Pain - Lower Back",
    Dried: { thc: [0, 6], cbd: [9, 20] },
    Oil: { thc: [0, 5], cbd: [10, 31] },
    Capsules: { thc: [0, 5], cbd: [9, 31] }
  },
  {
    name: "Pain - Musculoskeletal",
    Dried: { thc: [11, 24], cbd: [0, 1] },
    Oil: { thc: [11, 22], cbd: [0, 19] },
    Capsules: { thc: [9, 22], cbd: [0, 19] }
  },
  {
    name: "Pain - Neck",
    Dried: { thc: [5, 22], cbd: [0, 9] },
    Oil: { thc: [0, 1], cbd: [10, 64] },
    Capsules: { thc: [0, 1], cbd: [9, 64] }
  },
  {
    name: "Pain - Nerve",
    Dried: { thc: [14, 24], cbd: [0, 4] },
    Oil: { thc: [12, 26], cbd: [0, 14] },
    Capsules: { thc: [9, 26], cbd: [0, 14] }
  },
  {
    name: "Pain - Pelvic",
    Dried: { thc: [13, 19], cbd: [0, 4] },
    Oil: { thc: [0, 3], cbd: [13, 30] },
    Capsules: { thc: [0, 3], cbd: [9, 30] }
  },
  {
    name: "Pain - Upper Back",
    Dried: { thc: [5, 24], cbd: [0, 9] },
    Oil: { thc: [0, 1], cbd: [10, 30] },
    Capsules: { thc: [0, 1], cbd: [9, 30] }
  },
  {
    name: "Numbness or Tingling Sensation",
    Dried: { thc: [15, 26], cbd: [0, 1] },
    Oil: { thc: [0, 5], cbd: [13, 30] },
    Capsules: { thc: [0, 5], cbd: [9, 30] }
  },
  {
    name: "Withdrawal",
    Dried: { thc: [20, 30], cbd: [0, 1] },
  }
];

const range = (key, from, to) => {
  return {
    nested: {
      path: "product_specs",
      query: {
        bool: {
          must: [
            {
              match: {
                "product_specs.key": key,
              },
            },
            {
              range: {
                "product_specs.number_value": {
                  gte: from,
                  lte: to,
                },
              },
            },
          ],
        },
      },
      score_mode: "avg",
    },
  };
};

const category = (cat) => {
  return {
    prefix: {
      "categories.raw": `Cannabis/${cat}`,
    },
  };
};

const buildConditionOption = (condition) => {
  const { name, ...categories } = condition;
  return {
    label: name,
    description: name,
    query: {
      bool: {
        should: _.map(categories, (v, k) => ({ bool: { must: [category(k), range("thc", v.thc[0], v.thc[1]), range("cbd", v.cbd[0], v.cbd[1])] } }))
      },
    },
  };
};

const presetOptions = conditons.map(buildConditionOption);

const config = {
  rootCategory: "Cannabis",
  collectionUrl: ROUTE.PRODUCTS,
  elasticsearch: {
    name: "cannabis",
    url: `/api/search/`,
    indices: ["products"],
    baseQuery: {
      bool: {
        should: [
          {
            prefix: {
              "categories.raw": "Cannabis"
            }
          }
        ]
      }
    },
    keywordSearch: true,  // for display or not in keyword search
  },
  sortOptions: [
    { value: "-manual_weight", title: "sorter.sort_option.relevance" },
    { value: "price", title: "sorter.sort_option.price_low_high" },
    { value: "-price", title: "sorter.sort_option.price_high_low" },
    { value: "product_specs/thc[number]", title: "sorter.sort_option.thc_low_high" },
    { value: "-product_specs/thc[number]", title: "sorter.sort_option.thc_high_low" },
    { value: "product_specs/cbd[number]", title: "sorter.sort_option.cbd_low_high" },
    { value: "-product_specs/cbd[number]", title: "sorter.sort_option.cbd_high_low" },
    { value: "producer.name.raw", title: "sorter.sort_option.producer_az" },
    { value: "-producer.name.raw", title: "sorter.sort_option.producer_za" },
    { value: "name.raw", title: "sorter.sort_option.strain_az" },
    { value: "-name.raw", title: "sorter.sort_option.strain_za" },
  ],
  filterOptions: [
    {
      component: `FreeShippingFilter`,
    },
    {
      component: `InStockFilter`,
    },
    {
      component: `PresetFilter`,
      id: "Condition",
      name: "Condition",
      dataField: "Condition",
      presetOptions,
      hideFiltersOnSet: ["Category", "THC", "CBD"],
      loginRequired: true,
    },
    {
      component: `DiscountFilter`,
      showCount: true,
      react: ["Condition", "Category", "Producer", "Type"]
    },
    {
      component: `CategoryFilter`,
      prefix: "Cannabis",
      react: ["Producer", "Price", "Product Composition", "Type", "Keyword"]
    },
    {
      component: `MultiSelectFilter`,
      showCount: true,
      id: "Product Composition",
      name: "Product Composition",
      dataField: "product_specs/product_composition",
      react: ["Producer", "Price", "Condition", "Type", "Keyword", "Category"]
    },
    {
      component: `MultiSelectFilter`,
      name: "Type",
      id: "Type",
      showCount: true,
      dataField: "product_specs/cannabis_type",
      react: ["Producer", "Price", "Condition", "Product Composition", "Keyword", "Category"]
    },
    {
      component: `SearchableMultipleSelectFilter`,
      name: "Producer",
      label: "search.filters.producer_label",
      id: "Producer",
      showCount: true,
      enableSelectAll: true,
      size: 100,
      dataField: "producer.name.raw",
      react: ["Type", "Price", "Condition", "Product Composition", "Keyword", "Category"]
    },
    {
      component: `RangeSliderFilter`,
      name: "Price",
      id: "Price",
      dynamic: true,
      dataField: "price",
      textBox: {
        textAlign: "left",
        startAdornment: "$",
      },
      react: ["Producer", "Type", "Condition", "Product Composition", "Keyword", "Category"]
    },
    {
      component: `TerpenesFilter`,
      showCount: true,
      name: "Terpenes",
    }
  ],
  resultComponent: {
    component: `SearchResultCard`,
    showSpecs: [
      { key: "cannabis_type", format: "${value}" },
      { key: "thc", format: "THC: ${value}" },
      { key: "cbd", format: "CBD: ${value}" },
      { key: "cbg", format: "CBG: ${value}" },
    ],
  }
};

export default config;
