import { StyledParagraph } from "@components/general";
import { Checkbox, FormControlLabel, Grid, Radio } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import IconCheckmarkChecked from "@resources/images/IconCheckmarkChecked.svg";
import IconCheckmarkUnchecked from "@resources/images/IconCheckmarkUnchecked.svg";
import { color } from "@resources/styles";
import React from "react";
import styled from "styled-components";

const CheckboxOutline = styled(IconCheckmarkUnchecked)`
  height: 18px;
  width: 18px;
  stroke-width: 3px;
  & rect {
    fill: ${color.realWhite};
  }
`;

const CheckboxChecked = styled(IconCheckmarkChecked)`
  height: 18px;
  width: 18px;
  stroke-width: 2px;
  & g {
    fill: ${color.realWhite};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 5px;
`;

interface RadioFieldProps {
  type?: string;
  label: React.ReactNode;
  value?: boolean;
  name: string;
  onChange: any;
  disabled?: boolean;
}

// { input, placeholder, label, meta: { touched, error }, children, ...custom }
const RadioField: React.FC<RadioFieldProps & CSSProperties> = ({ label, type, value, name, onChange, disabled }) => {
  return (
    <Grid container>
      <FormControlLabel
        label={
          <StyledParagraph hoverAction align="left" guideline="p2" color={color.black}>
            {label}
          </StyledParagraph>
        }
        control={
          type && type === "radio" ? (
            <Radio checked={(value && value) || false} color="primary" />
          ) : (
            <StyledCheckbox checked={value && value} color="primary" icon={<CheckboxOutline />} checkedIcon={<CheckboxChecked />} />
          )
        }
        onChange={(event, v) => onChange(v)}
        labelPlacement="end"
        disabled={disabled}
      />
    </Grid>
  );
};

export default RadioField;
