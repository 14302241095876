import _ from "lodash";
import pathToRegexp from "path-to-regexp";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import uniqid from "uniqid";
import SpinProgress from "./Spinner";

interface SmartSpinnerProps {
  showOnActions?: string[];
  children: any;
  size?: number | string;
  fullSize?: boolean;
}

const actionSubsribers: any = {};
const urlPatthenSubscribers: any = {};

function isConfigMatchUrlPattern(config, pattern) {
  let [requestUrl, ..._rest] = config.url.split("?");
  let [method, url] = pattern.split(" ");
  if (!url) {
    url = method;
    method = null;
  }

  let urlRegex = pathToRegexp(url);
  if (!method) {
    return urlRegex.test(requestUrl);
  } else {
    let _method = method.toLowerCase();
    return _method.indexOf(config.method.toLowerCase()) !== -1 && urlRegex.test(requestUrl);
  }
}

/**.
 * spinner redux plugin
 *
 * @param {*} store
 */
export function spinnerReduxPlugin(store: any) {
  return (next: any) => (action: any) => {
    Object.keys(actionSubsribers).forEach((key) => {
      if (actionSubsribers[key] && typeof actionSubsribers[key] === "function") {
        actionSubsribers[key](action);
      }
    });
    next(action);
  };
}

export function axiosRequestSpinnerPlugin(config) {
  Object.keys(urlPatthenSubscribers).forEach((key) => {
    if (urlPatthenSubscribers[key]) {
      let shouldCall = urlPatthenSubscribers[key].urlPatterns.some((pattern) => {
        /**
         * if pattern is a function, then run the function
         */
        if (typeof pattern === "string") {
          return isConfigMatchUrlPattern(config, pattern);
          // return pattern(config.url, config.method, config.data);
        }
        return false;
      });

      if (shouldCall) {
        urlPatthenSubscribers[key].handler(true, config.url);
      }
    }
  });
  return config;
}

export function axiosResponseSpinnerPlugin(response) {
  Object.keys(urlPatthenSubscribers).forEach((key) => {
    if (urlPatthenSubscribers[key]) {
      let shouldCall = urlPatthenSubscribers[key].urlPatterns.some((pattern) => {
        /**
         * if pattern is a function, then run the function
         */
        if (typeof pattern === "string") {
          return isConfigMatchUrlPattern(response.config, pattern);
        }
        return false;
      });

      if (shouldCall) {
        urlPatthenSubscribers[key].handler(false, response.config.url);
      }
    }
  });
  return response;
}

export function axiosResponseErrorSpinnerPlugin(error) {
  Object.keys(urlPatthenSubscribers).forEach((key) => {
    if (urlPatthenSubscribers[key]) {
      let shouldCall = urlPatthenSubscribers[key].urlPatterns.some((pattern) => {
        /**
         * if pattern is a function, then run the function
         */
        if (typeof pattern === "string") {
          return isConfigMatchUrlPattern(error.config, pattern);
        }
        return false;
      });

      if (shouldCall) {
        urlPatthenSubscribers[key].handler(false, error.config.url);
      }
    }
  });
  return Promise.reject(error);
}

export const useSmartSpinner = (showOnActions: string[] = []): [boolean, () => void] => {
  const spinnerId = useMemo<string>(() => uniqid("spinner_"), []);
  const [pendingCounter, setPendingCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleUrlPatternMatching = (started, url) => {
    let nextCounter = pendingCounter;
    if (started) {
      nextCounter = pendingCounter + 1;
    } else {
      if (pendingCounter > 0) {
        nextCounter = pendingCounter - 1;
      }
    }
    setPendingCounter(nextCounter);
  };

  const handleReduxAction = (action: any) => {
    let nextCounter = pendingCounter;
    for (let showOnAction of showOnActions) {
      const actionName = showOnAction.toString();
      if (action.type === `${actionName}/PENDING`) {
        nextCounter = pendingCounter + 1;
      } else if (action.type === `${actionName}/FULFILLED` || action.type === `${actionName}/REJECTED`) {
        if (pendingCounter > 0) {
          nextCounter = pendingCounter - 1;
        }
      }
    }
    setPendingCounter(nextCounter);
  };

  useEffect(() => {
    if (pendingCounter > 0 && !loading) {
      setLoading(true);
    } else if (pendingCounter === 0 && loading) {
      setLoading(false);
    }
  }, [pendingCounter]);

  useEffect(() => {
    actionSubsribers[spinnerId] = handleReduxAction;
    urlPatthenSubscribers[spinnerId] = {
      handler: handleUrlPatternMatching,
      urlPatterns: showOnActions,
    };
    return () => {
      delete actionSubsribers[spinnerId];
      delete urlPatthenSubscribers[spinnerId];
    };
  }, []);

  const cancelSpinner = () => {
    setLoading(false);
    setPendingCounter(0);
  };

  return [loading, cancelSpinner];
};

const SmartSpinner: React.FC<SmartSpinnerProps> = ({ showOnActions = [], children, size, fullSize }) => {
  const [loading, _0] = useSmartSpinner(showOnActions);

  const renderChildren = () => {
    if (children) {
      return children;
    }
    return <div style={{ width: "100%", height: "60" }}>&nbsp;</div>;
  };

  if (showOnActions.length > 0) {
    return (
      <SpinProgress display={loading} size={size} fullSize={fullSize}>
        {renderChildren()}
      </SpinProgress>
    );
  } else {
    return <>{renderChildren()}</>;
  }
};

const mapStateToProps = (state: any) => ({
  state,
});

export default React.memo(connect(mapStateToProps)(SmartSpinner), _.isEqual);
