import { Modal, WidthContext } from "@components/general";
import { Grid } from "@material-ui/core";
import { updateAddressAction } from "@reducers/address";
import { StoreProps } from "@reducers/store";
import ArrowDropDown from "@resources/images/ArrowDropDown.svg";
import IconLocation from "@resources/images/IconLocation.svg";
import IconLocationBig from "@resources/images/IconLocationBig.svg";
import * as ROUTE from "@resources/routeConst";
import { useLocation } from "@utils/hooks";
import { useTranslation } from "@utils/i18n";
import _ from "lodash";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressSuggestModal from "./AddressSuggestModal";

const AddressSelector: React.FC = () => {
  const { t } = useTranslation("components/address-modal");
  const [localStoredAddress] = useLocation();
  const [showModal, setShowModal] = useState(false);
  const router = useRouter();
  const address = useSelector((store: StoreProps) => store.address);
  const dispatch = useDispatch();
  const width = useContext(WidthContext);
  const mobile = width === "xs" || width === "sm";

  useEffect(() => {
    if (_.isEmpty(localStoredAddress) && router.pathname.includes(ROUTE.LOCAL_DELIVERY)) {
      setShowModal(true);
    }
    if (_.isEmpty(address) && !_.isEmpty(localStoredAddress)) {
      dispatch(updateAddressAction(localStoredAddress));
    }
  }, []);

  return (<>
    <a style={{ paddingBottom: 12, cursor: "pointer", textDecoration: "none" }} onClick={() => setShowModal(true)}>
      {mobile ? <IconLocationBig /> :
        <>
          <div style={{ fontSize: 12 }}>{t("address_selector.label")}</div>
          <Grid container justify="center" alignItems="center" spacing={1} style={{ flexWrap: "nowrap" }}>
            <Grid item><div style={{ display: "flex" }}><IconLocation /></div></Grid>
            <Grid item>
              {
                !_.isEmpty(address) ? <Grid container alignItems="center" style={{ flexWrap: "nowrap" }}>
                  <Grid item>{address.street}</Grid>
                  <Grid item style={{ display: "flex" }}>
                    <ArrowDropDown height={22} />
                  </Grid>
                </Grid>
                  :
                  <div>{t("address_selector.placholder")}</div>
              }
            </Grid>
          </Grid>
        </>
      }
    </a>
    <Modal
      show={showModal}
      toggleModal={() => { setShowModal(!showModal) }}
      closeOnBackDropClick={false}
      destroyOnClose={true}
    >
      <AddressSuggestModal closeModal={() => setShowModal(false)} />
    </Modal>
  </>
  );
};

export default AddressSelector;
