import { GeneralCheckBoxField, StyledAutoComplete } from "@components/form";
import { Row, Tag } from "@components/general";
import { Grid } from "@material-ui/core";
import IconSearch from "@resources/images/IconSearch.svg";
import { color } from "@resources/styles";
import produce from "immer";
import _ from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

export interface ItemOptions {
  value: any;
  label: any;
}

interface SearchableMultipleSelectViewProps {
  checkAll?: boolean;
  options?: ItemOptions[];
  value?: string[];
  placeholder?: string;
  selectAllLabel?: string;
  selectAllValue?: string;
  onChange: (value: string[]) => void;
}

const defaultProps = {
  placeholder: "Search",
  selectAllLabel: "Select All",
  selectAllValue: "select_all_values",
};

const SearchIcon = styled(IconSearch)`
  fill: ${color.grey};
  cursor: pointer;
  width: 25px;
  height: 25px;
  &:hover {
    fill: ${color.black};
  }
`;

const CheckboxContainer = styled.div`
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  overflow-x: hidden;
  background-color: ${color.realWhite};
  border: 1px solid ${color.grey};
  padding: 10px 15px;
  border-radius: 5px;
`;

const SearchableMultipleSelectView: React.FC<SearchableMultipleSelectViewProps> = (props) => {
  const { checkAll = false, options = [], value = [], onChange, placeholder, selectAllLabel, selectAllValue } = { ...props, ...defaultProps };

  const [search, setSearch] = useState<string>("");

  const buildOptions = (opts: ItemOptions[] = []) => {
    let filteredOptions = opts.filter((opt) => opt.value.toLowerCase().trim().includes(search.toLowerCase()));
    // Add select all option to the top of the list
    if (checkAll) {
      filteredOptions = [{ value: selectAllValue, label: selectAllLabel }, ...filteredOptions];
    }
    return filteredOptions;
  };

  // check if current val is selected
  const isSelected = (val: string) => {
    // mark value as selected if value has 'selectAllValue'
    return value.includes(selectAllValue) || value.includes(val);
  };

  const handleItemSelected = (val: string, selected: boolean) => {
    let newValue = [...value];

    if (val === selectAllValue) {
      newValue = selected ? [selectAllValue] : [];
    } else {
      if (selected) {
        newValue = produce(newValue, (d) => {
          d.push(val);
        });
        if (checkAll && newValue.length === options.length) {
          newValue = [selectAllValue];
        }
      } else {
        if (newValue.includes(selectAllValue)) {
          newValue = options.map((opt) => opt.value).filter((v) => ![val, selectAllValue].includes(v));
        } else {
          newValue = produce(newValue, (d) => d.filter((e) => e !== val));
        }
      }
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <>
      <Row container>
        <StyledAutoComplete
          placeholder={placeholder}
          inputProps={{ "aria-label": placeholder }}
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
          endAdornment={
            search === "" ? (
              <SearchIcon style={{ height: 30, width: 30, marginRight: 10 }} />
            ) : (
              <span style={{ cursor: "pointer", marginRight: 10 }} onClick={() => setSearch("")}>
                X
              </span>
            )
          }
          style={{ width: "100%" }}
          fullWidth
        />
      </Row>
      <Grid container>
        <CheckboxContainer>
          {buildOptions(options).map((opt) => (
            <GeneralCheckBoxField
              key={opt.value.trim()}
              name={opt.value.trim()}
              value={isSelected(opt.value)}
              onChange={(selected) => handleItemSelected(opt.value, selected)}
              label={opt.label}
            />
          ))}
        </CheckboxContainer>
      </Grid>
      <Row container>
        {value.map(
          (v, index) =>
            v !== "select_all_values" && (
              <Tag
                key={index}
                style={{ marginRight: 10, marginBottom: 5, cursor: "pointer" }}
                type="blue"
                onClick={() => handleItemSelected(v, false)}
              >
                {v.trim()} &nbsp; <span>X</span>
              </Tag>
            )
        )}
      </Row>
    </>
  );
};

export default React.memo(SearchableMultipleSelectView, _.isEqual);
