import IconBaseBack from "@resources/images/IconBaseBack.svg";
import IconBaseFront from "@resources/images/IconBaseFront.svg";
import React, { ReactNode } from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";

interface IconBaseProps {
  /**
   * Set the size, default as 100px
   */
  size?: number;

  /**
   * Fade animation
   */
  fade?: "left" | "right" | "clear" | "top" | "bottom" | null;

  /**
   * The number of the hexagon image base. default as `true`
   * - false: display only `one` base image behind (`behind base` only)
   * - true: display `double` base image behind (`front base` and `behind base`)
   */
  doubleBased?: boolean;

  /**
   * Set the color of the hexagon
   * - for the `behind base`, it'll apply the stroke(line) color only. But this can be __overwritten__ by the `strokeColor` property
   * - for the `front base`, it'll apply the fill(background) color only
   */
  baseColor?: string;

  /**
   * Set the `hehind base` stroke(line) color.
   *
   * this has a high priority
   */
  strokeColor?: string;

  /**
   * Set the opacity of the `front base` fill(background) color.
   */
  opacity?: number;

  /**
   * It can receive not only `svg` component but also `any other` component but must be `single`
   */
  icon: ReactNode;
}

const Container = styled.div<any>`
  position: relative;
  height: ${(props) => props.size + 10}px;
  width: ${(props) => props.size}px;
`;

const Base = styled(({ baseColor, strokeColor, children, ...props }) => <IconBaseBack {...props}>{children}</IconBaseBack>)`
  height: 100%;
  width: 100%;
  position: absolute;
  & path {
    ${(props) =>
      props.baseColor && !props.strokeColor ? `stroke: ${props.baseColor}` : `${props.strokeColor ? `stroke: ${props.strokeColor}` : ""}`};
    fill: none;
  }
`;

const FrontBase = styled(({ baseColor, children, opacity, ...props }) => <IconBaseFront {...props}>{children}</IconBaseFront>)`
  height: 86%;
  width: 86%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
  & path {
    ${(props) => (props.baseColor ? `fill: ${props.baseColor}` : "")};
    ${(props) => (props.baseColor ? `opacity: ${props.opacity ? props.opacity : 1}` : "")}
  }
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  & svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
`;

/**
 * ### Cannalogue Hexagon container
 * @param size
 * Set the size, default as 100px
 * @param Fade Fade animation
 * @param doubleBased
 * The number of the hexagon image base. default as `true`
 * - false: display only `one` base image behind (`behind base` only)
 * - true: display `double` base image behind (`front base` and `behind base`)
 * @param baseColor
 * Set the color of the hexagon
 * - for the `behind base`, it'll apply the stroke(line) color only. But this can be __overwritten__ by the `strokeColor` property
 * - for the `front base`, it'll apply the fill(background) color only
 * @param strokeColor
 * Set the `hehind base` stroke(line) color.
 *
 * this has a high priority
 * @param opacity
 * Set the opacity of the `front base` fill(background) color.
 * @param icon
 * It can receive not only `svg` component but also `any other` component but must be `single`
 */
const IconBase: React.FC<IconBaseProps> = ({ size = 100, fade, baseColor, strokeColor, opacity, doubleBased = true, icon }) => {
  const singleBasedRender = (
    <Container size={size}>
      <Base baseColor={baseColor} strokeColor={strokeColor} opacity={opacity} />
      <IconContainer>{icon}</IconContainer>
    </Container>
  );

  const doubleBasedRender = (
    <Container size={size}>
      <Base baseColor={baseColor} strokeColor={strokeColor} />
      <FrontBase baseColor={baseColor} opacity={opacity} />
      <IconContainer>{icon}</IconContainer>
    </Container>
  );

  if (fade) {
    const direction = {};
    direction[fade] = true;
    return (
      <Fade {...direction} delay={300} duration={1000}>
        <div style={{ justifyContent: "center", display: "flex" }}>{doubleBased ? doubleBasedRender : singleBasedRender}</div>
      </Fade>
    );
  }

  return doubleBased ? doubleBasedRender : singleBasedRender;
};

export default IconBase;
