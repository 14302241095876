import Router from "next/router";

interface RedirectProps {
  router?: any;
  ctx?: any;
  location: string;
  status?: number;
}

const redirect = (params: RedirectProps) => {
  const { ctx, location, status = 302 } = params;
  if (ctx && ctx.res) {
    // Seems to be the version used by zeit
    ctx.res.writeHead(status, {
      Location: location,
      // Add the content-type for SEO considerations
      "Content-Type": "text/html; charset=utf-8",
    });
    ctx.res.end();
  } else {
    Router.replace(location);
  }
};

export default redirect;
