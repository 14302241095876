import { CircularProgress } from "@material-ui/core";
import OutlinedInput, { OutlinedInputProps } from "@material-ui/core/OutlinedInput";
import IconSearch from "@resources/images/IconSearch.svg";
import { color } from "@resources/styles";
import React from "react";
import styled from "styled-components";

export interface StyledAutoCompleteProps extends OutlinedInputProps {
  divRef?: any;
  showSearchIcon?: boolean;
  loading?: boolean;
  iconAction?: (...args: any[]) => any;
}

const SearchIcon = styled(IconSearch)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const StyledInput = styled(({ className, ref, ...props }) => (
  <OutlinedInput ref={ref} {...props} autoComplete="off" classes={{ input: className }} />
))``;

const StyledAutoComplete = React.forwardRef<any, StyledAutoCompleteProps>(
  ({ showSearchIcon = true, divRef, fullWidth, iconAction, loading = false, ...props }, ref) => {
    const getEndAdornment = () => {
      if (loading) {
        return <CircularProgress size={16} />;
      } else if (props.endAdornment) {
        return props.endAdornment;
      } else if (showSearchIcon) {
        return <SearchIcon stroke={color.grey} {...{ onClick: iconAction }} />;
      } else {
        return null;
      }
    }
    return (
      // FIXME: fullWidth
      <div ref={divRef} style={fullWidth ? { width: "100%" } : {}}>
        <StyledInput
          ref={ref}
          {...{ fullWidth, ...props }}
          disabled={props.disabled || loading}
          endAdornment={getEndAdornment()}
        />
      </div>
    );
  }
);

export default StyledAutoComplete;
