import { GeneralSelectField } from "@components/form";
import { Row } from "@components/general";
import { Grid, MenuItem } from "@material-ui/core";
import { getCurrentESName, useSort } from "@utils/elasticsearch/hooks";
import { useTranslation } from "@utils/i18n";
import _ from "lodash";
import React from "react";
import { SortFilterProps } from "./FilterConfig";

const SortFilter: React.FC<SortFilterProps> = ({ ...config }) => {
  const { value, options, updateValue } = useSort(getCurrentESName(), { ...config });
  const { tOrNull: t } = useTranslation(["components/search"]);

  return (
    <Row>
      <Grid item xs={12}>
        <GeneralSelectField noErrors name="Sort" value={value} onChange={updateValue}>
          {options?.map(({ value: v, title }, index) => (
            <MenuItem key={index} value={v}>
              {t(title) || title}
            </MenuItem>
          ))}
        </GeneralSelectField>
      </Grid>
    </Row>
  );
};

export default React.memo(SortFilter, _.isEqual);
