import { color } from "@resources/styles";
import React from "react";
import styled from "styled-components";

interface ThumbProps {
  selected: boolean;
}

const StyledImage = styled.img<any>`
  border: 1px solid ${(props) => (props.selected ? color.purple : color.grey)};
  border-radius: 4px;
  margin-right: 15px;
  height: 90px;
  width: 90px;
  object-fit: contain;
  box-shadow: ${(props) => (props.selected ? "0 5px 10px 0 rgba(0,0,0,0.1)" : "none")};
  cursor: pointer;
  &:focus {
    background: unset;
  }
`;

const ThumbImage: React.FC<Partial<HTMLImageElement> & ThumbProps> = React.forwardRef((props, ref) => {
  return <StyledImage {...props} ref={ref} />;
});

export default ThumbImage;
