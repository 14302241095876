import { PaymentMethodFormValues } from "@components/application-forms/PaymentMethodForm";
import axios from "@utils/axios";
import { getStore } from "@utils/elasticsearch/config";
import { alertRequestError, goToLogInOn401 } from "@utils/utils";
import _ from "lodash";
import { createAction, handleActions } from "redux-actions";
import { Checkout, ITxData, MedicalAddress, PaymentMethod } from "./types";

const INITIAL_STATE = {
  id: "empty",
}
export const checkoutPath = "/api/checkouts/current";

export const loadCheckout = async () => {
  await getStore().dispatch(getCheckoutAction()).catch(alertRequestError()).catch(goToLogInOn401);
};

export const getCheckoutByNumber = async (checkoutNumber: string): Promise<Checkout> => {
  const { data } = await axios.get(`/api/checkouts/${checkoutNumber}`).catch(alertRequestError()).catch(goToLogInOn401);
  return data;
};

export const addShippingAddress = async (newAddress): Promise<MedicalAddress> => {
  const { data } = await axios.post(`/api/users/current/addresses`, newAddress);
  return data;
};

export const updateShippingAddress = async (address: MedicalAddress) => {
  await axios.put(`/api/users/current/addresses/${address.id}`, address);
};

export const deleteShippingAddress = async (id: string) => {
  await axios.delete(`/api/users/current/addresses/${id}`);
};

export const addCreditCard = async (card: PaymentMethodFormValues, recaptchaToken: string): Promise<PaymentMethod> => {
  const { data } = await axios.post(`${checkoutPath}/credit_card?recaptcha_v3_token=${recaptchaToken}`, {
    ...card,
  });
  return data;
};

export const updateCreditCard = async (card: PaymentMethodFormValues) => {
  await axios.put(`/api/users/current/payments/credit_cards/${card.id}`, {
    ...card,
  });
};

export const deleteCreditCard = async (id: string) => {
  await axios.delete(`/api/users/current/payments/credit_cards/${id}`);
};

export const placeOrderAction = async (): Promise<Checkout> => {
  const { data } = await axios.post(`${checkoutPath}/proceed`);
  return data;
};

// update prepared order to submitted status
export const submitCheckout = async (transaction_id: string): Promise<ITxData> => {
  const { data } = await axios.post(`${checkoutPath}/submit`, { transaction_id });
  return data;
};

export const prepareOrderAction = createAction("CHECKOUT_PREPARE", async (account_address: string) => {
  const { data } = await axios.post(`${checkoutPath}/proceed`, { account_address });
  return data;
});

export const unprepareAction = createAction("CHECKOUT_UNPREPARE", async () => {
  const { data } = await axios.post(`${checkoutPath}/unprepare`);
  return data;
});

export const validateCheckoutAction = createAction("CHECKOUT_VALIDATE", async () => {
  const { data } = await axios.get(`${checkoutPath}/validate`);
  return data;
});

export const updateShippingRatesAction = createAction("UPDATE_SHIPPING_RATES", async (selected_rates) => {
  const { data } = await axios.post(`${checkoutPath}/rates`, {
    selected_rates,
  });
  return data;
});

export const chooseShippingAddressAction = createAction("CHOOSE_DEFAULT_SHIPPING_ADDRESS", async (id: string) => {
  const { data } = await axios.post(`${checkoutPath}/choose_shipping`, { address_id: id });
  return data;
});

export const getCheckoutAction = createAction("GET_CHECKOUT", async () => {
  const { data } = await axios.get(checkoutPath);
  return data;
});

export const updateItemAction = createAction("UPDATE_CHECKOUT_ITEM", async (id: string, quantity: string) => {
  const { data } = await axios.post(`${checkoutPath}/checkout_items/${id}`, { quantity });
  return data;
});

export const addItemAction = createAction("ADD_CHECKOUT_ITEM", async (item: { id: string; quantity: number }) => {
  const { data } = await axios.post(`${checkoutPath}/checkout_items`, item);
  return data;
});

export const applyCouponAction = createAction("APPLY_COUPON_CODE", async (coupon: string) => {
  const { data } = await axios.post(`${checkoutPath}/apply_coupon`, { coupon_code: coupon });
  return data;
});

export const removeCouponAction = createAction("REMOVE_COUPON_CODE", async (coupon: string) => {
  const { data } = await axios.post(`${checkoutPath}/remove_coupon`, { coupon_code: coupon });
  return data;
});

export const removeItemAction = createAction("REMOVE_CHECKOUT_ITEM", async (id: string) => {
  const { data } = await axios.delete(`${checkoutPath}/checkout_items/${id}`);
  return data;
});

export const chooseCreditCardAction = createAction("CHOOSE_CREDIT_CARD", async (id: string) => {
  const { data } = await axios.post(`${checkoutPath}/choose_card`, {
    card_id: id,
  });
  return data;
});

export const chooseCryptoAction = createAction("CHOOSE_CRYPTO", async (id: string) => {
  const { data } = await axios.post(`${checkoutPath}/choose_crypto`);
  return data;
});

export const resetStoreAction = createAction("RESET_CHECKOUT_STORE", async () => {
  return {};
});

const reducers = handleActions(
  {
    [getCheckoutAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [chooseShippingAddressAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [updateShippingRatesAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [updateItemAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [removeItemAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [addItemAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [applyCouponAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [chooseCreditCardAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [chooseCryptoAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [validateCheckoutAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [prepareOrderAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [unprepareAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
    [resetStoreAction]: {
      FULFILLED: () => INITIAL_STATE,
    },
  },
  INITIAL_STATE
);

export default reducers;
