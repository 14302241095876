import { StyledParagraph } from "@components/general";
import { Grid } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import HelpSectionIcon from "@resources/images/HelpSectionIcon.svg";
import { color } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import React, { useContext } from "react";
import { Fade } from "react-reveal";
import WidthContext from "./WidthContext";

interface HelpSectionProps {
  style?: CSSProperties;
}

const HelpSection: React.FC<HelpSectionProps> = ({ style }) => {
  const width = useContext(WidthContext);
  const mobile = width === "xs" || width === "sm";
  const { tOrNull: t } = useTranslation("components/help-section");
  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      justify={mobile ? "center" : "flex-start"}
      style={{ background: "rgb(205,203,203, 0.1)", padding: "30px 25px", flexWrap: mobile ? "wrap" : "nowrap", ...style }}
    >
      <Grid item>
        <Fade clear delay={300} duration={1000}>
          <HelpSectionIcon />
        </Fade>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: mobile ? "center" : "left" }}>
            <StyledParagraph fade="clear" color={color.blue} guideline="h3">
              {t("client_care.title")}
            </StyledParagraph>
          </Grid>
          <Grid item xs={12} style={{ textAlign: mobile ? "center" : "left" }}>
            <StyledParagraph fade="clear" guideline="p2" color={color.black} dangerouslySetInnerHTML={{ __html: t("client_care.content") }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HelpSection;
