import { ParagraphButton, PrimaryButton, Row, StyledParagraph, ThumbImage, WidthContext } from "@components/general";
import config from "@config/index";
import { Badge, Fade, Grid, Paper, Popover } from "@material-ui/core";
import { cannalogueAlert } from "@reducers/alerts";
import { getCheckoutAction, validateCheckoutAction } from "@reducers/checkout";
import { removeTopErrorMessages, topMessage } from "@reducers/messages";
import { StoreProps } from "@reducers/store";
import { Checkout, Order, OrderItem } from "@reducers/types";
import IconShoppingBag from "@resources/images/IconShoppingBag.svg";
import IconShoppingBagMobile from "@resources/images/IconShoppingBagMobile.svg";
import * as ROUTE from "@resources/routeConst";
import { color } from "@resources/styles";
import { trackCheckoutStart } from "@utils/gtagHelpers";
import { useTranslation } from "@utils/i18n";
import { alertRequestError, formatPrice, IMG_NOT_AVAILABLE_URL, onStatusCode, requestErrorMessage, serverErrorCode } from "@utils/utils";
import _ from "lodash";
import Link from "next/link";
import Router from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const ArrowUp = styled.div<any>`
  width: 20px;
  height: 20px;
  border-top: 1px solid ${color.greyBorderOpacity(0.6)};
  border-bottom: 0px solid ${color.greyBorderOpacity(0.6)};
  border-right: 0px solid ${color.greyBorderOpacity(0.6)};
  border-left: 1px solid ${color.greyBorderOpacity(0.6)};
  top: -10px;
  left: ${(props) => props.left}px;
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background: #fff;
`;

const getLastThree = (orders: Order[] = []): OrderItem[] => {
  const flattenOrderItems = _.flatten(orders.map((o) => o.order_items));
  const sortedOrderItems = _.orderBy(flattenOrderItems, (o: any) => new Date(o.updated_at).getTime(), ["desc"]) || [];
  const last_items = sortedOrderItems.length > 3 ? _.slice(sortedOrderItems, 0, 3) : _.slice(sortedOrderItems, 0, sortedOrderItems.length);
  return last_items;
};

const OrderItemDisplay: React.FC<{ data: OrderItem }> = ({ data }) => {
  const picture = data.pictures?.[0];
  const width = useContext(WidthContext);

  return (
    <Row justify="space-between" spacing={1} style={{ flexWrap: "nowrap" }}>
      <Grid item container style={{ flexWrap: "nowrap" }}>
        <Grid item>
          <Link
            href={`${ROUTE.PRODUCTS_DETAIL_HREF}?variant=${encodeURIComponent(data.variant_name)}`}
            as={`${ROUTE.PRODUCTS_DETAIL(data.product_paralink)}?variant=${encodeURIComponent(data.variant_name)}`}
            passHref={true}
          >
            <ThumbImage
              selected={false}
              src={picture?.thumb_url || IMG_NOT_AVAILABLE_URL}
              alt={picture?.thumb_url ? data?.product_name : "Image Not Available"}
            />
          </Link>
        </Grid>
        <Grid item>
          <StyledParagraph style={{ marginBottom: 8 }} guideline="p2" color={color.black}>
            {data?.product_name}
          </StyledParagraph>
          <StyledParagraph guideline="p2" color={color.black}>{`Qty: ${data?.quantity} / ${data?.variant_short_name}`}</StyledParagraph>
          {width === "xs" && (
            <StyledParagraph align="left" guideline="p2" color={color.black} style={{ marginTop: 8 }}>
              {formatPrice(data?.item_total)}
            </StyledParagraph>
          )}
        </Grid>
      </Grid>
      {width !== "xs" && (
        <Grid item>
          <StyledParagraph align="right" guideline="p2" color={color.black}>
            {formatPrice(data?.item_total)}
          </StyledParagraph>
        </Grid>
      )}
    </Row>
  );
};

const PopoverContent: React.FC<{ data: Checkout; onCancel: () => void }> = ({ data, onCancel }) => {
  const { tOrNull: t } = useTranslation("components/checkout-popover");
  const width = useContext(WidthContext);
  const mobile = width === "xs";
  const dispatch = useDispatch();
  const last_three_items = getLastThree(data?.orders);

  const quantity = data?.quantity || 0;
  const item_total = data?.item_total || 0;

  const validateCheckout = async () => {
    removeTopErrorMessages();
    try {
      await dispatch(validateCheckoutAction());
      trackCheckoutStart(data);
      dispatch(getCheckoutAction()); // reset cart
      Router.push(ROUTE.CHECKOUT_SHIPPING_INFO);
    } catch (err) {
      alertRequestError({ excepts: [401] })(err);
      onStatusCode(401, () => {
        Router.push(ROUTE.LOGIN);
      })(err);
      switch (serverErrorCode(err)) {
        case "PDT002":
          topMessage.error(requestErrorMessage(err), { priority: 10 });
          break;
        case "PDT003":
          topMessage.error(requestErrorMessage(err), { priority: 10 });
          break;
        case "PDT004":
          topMessage.error(requestErrorMessage(err), { priority: 10 });
          break;
        case "PSC005":
          topMessage.error(
            `Incomplete profile. Please complete your profile in <a href="${ROUTE.MY_ACCOUNT_ACCOUNT_INFO}" style="text-decoration: underline">account info</a>`,
            { priority: 10 }
          );
          break;
        default:
          cannalogueAlert.error(requestErrorMessage(err));
      }
      dispatch(getCheckoutAction());
    } finally {
      onCancel();
    }
  };

  return (
    <>
      {!_.isEmpty(last_three_items) ? (
        <Row
          style={{
            padding: "10px 20px",
            width: mobile ? "90vw" : 400,
          }}
        >
          <Row justify="space-between">
            <Grid item>
              <StyledParagraph guideline="p2" color={color.black}>
                <span className="bold"> {t("subtotal", { count: quantity })}</span>
              </StyledParagraph>
            </Grid>
            <Grid item>
              <StyledParagraph guideline="p2" color={color.black} variant="body2">{formatPrice(item_total)}</StyledParagraph>
            </Grid>
          </Row>
          <Row>
            <PrimaryButton secondary fullWidth onClick={validateCheckout}>
              {t("button")}
            </PrimaryButton>
          </Row>
          {last_three_items.map((item, index) => (
            <OrderItemDisplay key={index} data={item} />
          ))}
          <Row>
            <Link href={ROUTE.SHOPPING_CART}>
              <a>
                <StyledParagraph guideline="p2" color={color.black}>
                  <span className="link-blue">{t("view_cart")}</span>
                </StyledParagraph>
              </a>
            </Link>
          </Row>
        </Row>
      ) : (
        <Row justify="center" style={{ padding: 20, width: mobile ? "90vw" : 350 }}>
          <StyledParagraph guideline="p2" color={color.black} style={{ marginRight: 5 }}>
            {t("empty_cart_message.part1")}
          </StyledParagraph>
          <Link href={ROUTE.PRODUCTS_HREF} as={config.country === "US" ? ROUTE.HEMP : ROUTE.PRODUCTS}>
            <a>
              <StyledParagraph guideline="p2" color={color.black}>
                <span className="link-blue">{t("empty_cart_message.part2")}</span>
              </StyledParagraph>
            </a>
          </Link>
        </Row>
      )}
    </>
  );
};

const CheckoutPopover = () => {
  const checkout = useSelector((state: StoreProps) => state.checkout);
  const { id: userId } = useSelector((state: StoreProps) => state.user || {}, _.isEqual);
  const [visible, setVisible] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const width = useContext(WidthContext);
  const mobile = width === "xs" || width === "sm";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCheckoutAction());
  }, []);

  const getOffset = () => {
    let _x = 100;
    if (anchorRef && anchorRef.current) {
      _x = anchorRef.current.offsetLeft - anchorRef.current.scrollLeft + (mobile ? 20 : 6);
    }
    return _x;
  };

  return (
    <div ref={anchorRef}>
      {mobile ? <IconShoppingBagMobile style={{ width: 50, height: 33 }} onClick={() => userId && setVisible(!visible)} /> :
        <ParagraphButton onClick={() => userId && setVisible(!visible)}>
          <Badge badgeContent={checkout?.quantity} color="secondary">
            <IconShoppingBag style={{ height: 39, width: 32 }} />
          </Badge>
        </ParagraphButton>
      }
      <Grid container>
        <Popover
          open={visible}
          elevation={0}
          keepMounted
          anchorEl={anchorRef.current}
          onClose={() => setVisible(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transitionDuration={{
            enter: 0,
            exit: 0,
          }}
          PaperProps={{
            style: {
              overflowX: "visible",
              overflowY: "visible",
              backgroundColor: "transparent",
            },
          }}
        >
          <Fade in={visible} timeout={{ enter: 450, exit: 150 }}>
            <div style={{ marginTop: 10 }}>
              <div style={{ position: "fixed", width: "100%", left: 0 }}>
                <div style={{ position: "relative" }}>
                  <ArrowUp left={getOffset()} />
                </div>
              </div>
              <Paper variant="outlined" elevation={4}>
                <PopoverContent data={checkout} onCancel={() => setVisible(false)} />
              </Paper>
            </div>
          </Fade>
        </Popover>
      </Grid>
    </div>
  );
};

export default CheckoutPopover;
