import { getStore } from "@utils/elasticsearch/config";
import useWindow from "@utils/useWindow";
import produce from "immer";
import _ from "lodash";
import { Reducer } from "redux";
import { createAction, handleActions } from "redux-actions";
import uniqid from "uniqid";

enum MessageType {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

interface MessageOption {
  priority: number;
  exceptRoutes?: string[];
}

export interface MessageDataProps {
  id: string;
  message: string;
  type: MessageType;
  options: MessageOption;
  createdAt: number;
}

const restoreTopMessagesAction = createAction("RESTORE_MESSAGES_ON_TOP", (payload) => {
  return payload;
});

// export const loadMessageInitialState = () => {
//   const storeInSessionStorage = useWindow.sessionStorage.getItem("topMessageState");
//   getStore().dispatch(restoreTopMessagesAction(storeInSessionStorage ? JSON.parse(storeInSessionStorage) : []));
// };

// export const writeMessageToSessionStorageSubscriber = () => {
//   let currentValue;
//   function handleStateChange() {
//     let previousValue = currentValue;
//     currentValue = getStore().getState().messages;

//     if (previousValue !== currentValue) {
//       useWindow.sessionStorage.setItem("topMessageState", JSON.stringify(currentValue));
//     }
//   }

//   return getStore().subscribe(handleStateChange);
// };

const addTopMessageAction = createAction("ADD_MESSAGE_ON_TOP", (message, type: MessageType, options: MessageOption, messageID?: string) => {
  const message_id = messageID || uniqid("message_");
  return { id: message_id, message, type, options, createdAt: new Date().getTime() };
});

const removeTopMessageAction = createAction("REMOVE_MESSAGE_ON_TOP", (id) => {
  return { id };
});

const removeTopErrorMessagesAction = createAction("REMOVE_ERROR_MESSAGES_ON_TOP");

export const topMessage = {
  info: (msg: string, options: MessageOption): string => {
    const messageId = uniqid("message_");
    getStore().dispatch(addTopMessageAction(msg, MessageType.INFO, options, messageId));
    return messageId;
  },
  warn: (msg: string, options: MessageOption): string => {
    const messageId = uniqid("message_");
    getStore().dispatch(addTopMessageAction(msg, MessageType.WARN, options, messageId));
    return messageId;
  },
  error: (msg: string, options: MessageOption): string => {
    const messageId = uniqid("message_");
    getStore().dispatch(addTopMessageAction(msg, MessageType.ERROR, options, messageId));
    return messageId;
  },
};

export const removeTopMessage = (id: string) => {
  getStore().dispatch(removeTopMessageAction(id));
};

export const removeTopErrorMessages = () => {
  getStore().dispatch(removeTopErrorMessagesAction());
};

const reducer: Reducer<MessageDataProps[]> = handleActions(
  {
    [addTopMessageAction]: (state, { payload }) =>
      produce(state, (draft) => {
        if (!draft.find((e) => e.message === payload.message && e.options.priority === payload.options.priority)) {
          draft.push(payload);
        }
        return draft;
      }),
    [removeTopMessageAction]: (state, { payload: { id } }) => state.filter((m) => m.id !== id),
    [removeTopErrorMessagesAction]: (state) => state.filter((m) => m.type !== MessageType.ERROR),
    [restoreTopMessagesAction]: (state, { payload }) => payload,
  },
  []
);

export default reducer;
