import axios from "@utils/axios";
import {
  buildAggsQuery,
  buildFilterQueries,
  buildMaxMinAggregation,
  buildResultQuery,
  buildSortQuery,
  buildTermsAggregation,
  getRangeAggs,
  getResult,
  getTermsAggs,
  hasValue, provinceQuery, queryType
} from "@utils/elasticsearch/querySyntaxHelper";
import { trackFilterUsage } from "@utils/gtagHelpers";
import isServer from "@utils/isServer";
import produce from "immer";
import _ from "lodash";
import qs from "qs";
import { createAction, handleActions } from "redux-actions";
import { getESConfigs, getSearchAPI, getSearchFilters, getStore } from "./config";
import { ESState, MultiESState } from "./types";

type ES = MultiESState<any>;
type SES = ESState<any>;

const getSearchEndpoint = (esName) => {
  let endpoint = getSearchAPI(esName);
  const address = getStore().getState().address;
  if (!isServer && !_.isEmpty(address) && address?.coordinates) {
    const coordinates = address?.coordinates;
    endpoint = `${endpoint}?coordinates=${coordinates[0]},${coordinates[1]}`;
  }
  return endpoint;
}

// inject province queries as base query wherever possible
const getBaseQuery = (esName: string) => {
  const address = getStore().getState().address;
  const baseQuery = getESConfigs(esName).baseQuery;
  if (address?.province) {
    return [baseQuery, provinceQuery(address.province)];
  } else {
    return [baseQuery];
  }
}

// block until the latest update progress has done
let progressCount = 0;

const initialESState: SES = {
  filters: {},
  pagination: {
    current: 1,
    size: 12
  },
  result: {
    data: undefined,
    total: 0,
    loading: false,
  },
};

const initialState: ES = {
  // cannabis: initialESState,
  // accessories: initialESState
};

const unFrozenObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getESState = (state, esName: string): SES => {
  return state.elasticsearch[esName] || { ...initialESState };
};

/**
 * Clear filter values
 */
export const clearFiltersAction = createAction("CLEAR_FILTERS", (esName) => {
  return { esName };
});

export const clearFiltersExceptKeywordAction = createAction("CLEAR_FILTERS_KEEP_KEYWORD", (esName) => {
  return { esName };
});

/**
 * Update filter options
 */
export const loadFilterAction = createAction("RELOAD_SEARCH_FILTER", async (esName, id, baseQuery?) => {
  try {
    const filterValues = getESState(getStore().getState(), esName).filters || {};
    const filterConfig = getSearchFilters(esName)[id] || {};
    const reactingToFilters = filterConfig.react || [];
    const type: queryType = filterConfig.type || "terms";
    const query = getBaseQuery(esName);
    const baseQueryVar = baseQuery ? [...baseQuery, ...query] : query;

    if (filterConfig?.defaultOptions) {
      return { esName, type, id, options: filterConfig?.defaultOptions };
    }

    // getCustomAggs, buildCustomAggregation, buildCustomQuery
    const getCustomAggs = _.get(getSearchFilters(esName), `${id}.getCustomAggs`, null);
    const buildCustomAggregation = _.get(getSearchFilters(esName), `${id}.buildCustomAggregation`, null);

    // build filter queries
    const filterQueries = buildFilterQueries(getSearchFilters(esName), filterValues, reactingToFilters, baseQueryVar);
    let aggsQuery = {};
    let options: any = {};

    // build aggrigation queries
    if (buildCustomAggregation) {
      aggsQuery = buildAggsQuery(filterQueries, buildCustomAggregation(filterConfig.dataField));
    } else if (type === "range") {
      aggsQuery = buildAggsQuery(filterQueries, buildMaxMinAggregation(filterConfig.dataField));
    } else if (type === "terms") {
      aggsQuery = buildAggsQuery(filterQueries, buildTermsAggregation(filterConfig.dataField, filterConfig.size));
    }

    const { data } = await axios.post(getSearchEndpoint(esName), aggsQuery);

    // get aggrigation data from the axios response
    if (getCustomAggs) {
      options = getCustomAggs(data).map((d) => ({ value: d.key, label: d.key, count: d.doc_count }));
    } else if (type === "range") {
      options = getRangeAggs(filterConfig.dataField, data);
    } else if (type === "terms") {
      options = getTermsAggs(filterConfig.dataField, data).map((d) => ({ value: d.key, label: d.key, count: d.doc_count }));
    }

    // keeps selected options
    const selectedValues = filterValues[id]?.value;
    if (!_.isEmpty(selectedValues) && Array.isArray(selectedValues)) {
      const newValues = options?.map(o => o.value);
      const optionsToKeep = filterValues[id].options?.filter(option =>
        selectedValues.includes(option.value) && !newValues.includes(option.value)
      )?.map(o => ({ ...o, count: 0 })) || [];
      options = [...optionsToKeep, ...options];
    }

    return { esName, type, id, options: filterConfig.tranformOptions ? filterConfig.tranformOptions(options) : options };

  } catch (err) {
    // @ts-ignore
    nextLogger.info(`Aggs Query Failed[${id}]`, err);
    throw err;
  }
});

/**
 * Reload options of the filters that react to the given one
 * @param id the id of the filter
 */
export const reloadFiltersThatReactsToThis = (esName, id, baseQuery?) => {
  _(getSearchFilters(esName)).each((val, key) => {
    const react = val.react || [];
    if (react.includes(id)) {
      getStore().dispatch(loadFilterAction(esName, key, baseQuery));
    }
  });
};

/**
 * Load search results and update store
 */
export const loadSearchResultAction = createAction("RELOAD_SEARCH_RESULT", async (esName, { append = false, resetPage = true, baseScope = null }) => {
  try {
    // progress increment
    // progressCount++;
    const esState = getESState(getStore().getState(), esName);
    const query = getBaseQuery(esName);
    const baseQuery = baseScope ? [baseScope, ...query] : query;
    const filterValues = esState.filters || {};
    const { current = 1, size = 12 } = esState.pagination || {};
    const currentData = esState.result?.data || [];

    const filterQueries = buildFilterQueries(getSearchFilters(esName), filterValues, null, baseQuery);
    // console.debug("getSearchFilters(esName)", getSearchFilters(esName));
    // console.debug("filterValues", filterValues);

    const sortQuery = buildSortQuery(getSearchFilters(esName), filterValues);
    const searchQuery = buildResultQuery(filterQueries, sortQuery, (current - 1) * size, size);

    const res = await axios.post(getSearchEndpoint(esName), searchQuery);
    const { data, total } = getResult(res.data);
    // indicate of only this progress has done
    // progressCount--;
    return { esName, data: append ? [...currentData, ...data] : data, total, current: resetPage ? 1 : current };

  } catch (err) {
    // @ts-ignore
    nextLogger.info("Failed to query!");
    throw err;
  }
});

/**
 * Reset filters values. This function first set filters to an empty object and reload all filter options
 */
export const resetFilters = async (esName) => {
  // clear filter values
  await getStore().dispatch(clearFiltersAction(esName));
  // reload all filter options
  _(getSearchFilters(esName)).each((val, key) => {
    getStore().dispatch(loadFilterAction(esName, key));
  });
  // reload search results
  getStore().dispatch(loadSearchResultAction(esName, { resetPage: true }));
};

export const loadSuggestion = async (esName, id) => {
  try {
    const filterValues = getESState(getStore().getState(), esName).filters || {};
    const filterConfig = getSearchFilters(esName)[id] || {};
    const reactingToFilters = filterConfig.react || null;
    const baseQuery = getBaseQuery(esName);
    const filterQueries = buildFilterQueries(getSearchFilters(esName), { Keyword: filterValues.Keyword }, reactingToFilters, baseQuery);

    const query = buildResultQuery(filterQueries, {}, 0, filterConfig.size);
    const { data } = await axios.post(getSearchEndpoint(esName), query);
    return getResult(data).data;
  } catch (err) {
    // @ts-ignore
    nextLogger.info("Failed to load suggestion keywords!");
    throw err;
  }
};

export const createSearchURL = (esName, omitList: any = []) => {
  const values: any = {};
  const filters = _.omit(getESState(getStore().getState(), esName).filters, omitList) || {};
  _(filters).each((val: any, key) => {
    if (!_.isEmpty(val)) {
      if (hasValue(val.value, val.type)) {
        values[key] = val.value;
      }
    }
  });
  return _.isEmpty(values) ? "" : qs.stringify(values);
};

let filterVluesChangeUnsubscribe = {};
let currentFilters = {};

export const subscribeFilterValueChangesAndUpdateUrl = (esName) => {
  filterVluesChangeUnsubscribe[esName] = getStore().subscribe(() => {
    let previousValue: any = currentFilters[esName];
    currentFilters[esName] = getESState(getStore().getState(), esName).filters || {};
    if (previousValue !== currentFilters[esName]) {
      const newSearchURL = createSearchURL(esName);
      const query = window.location.search.substring(1);
      if (`${newSearchURL}` !== query) {
        // sessionStorage.setItem(window.location.pathname, newSearchURL || "");
        window.history.replaceState({}, "", `${window.location.pathname}${newSearchURL ? `?${newSearchURL}` : ""}`);
      }
    }
  });
};

export const unSubscribeFilterValueChanges = (esName) => {
  if (filterVluesChangeUnsubscribe[esName]) {
    filterVluesChangeUnsubscribe[esName]();
  }
};

/**
 * Update filter value
 */
export const updateFitlerValueAction = createAction("UPDATE_SEARCH_FILTER_VALUE", (esName, id, value) => {
  trackFilterUsage({
    label: id,
    value
  });
  return { esName, id, value };
});

/**
 * Update page numberupdateFitlerValueAction
 */
export const setPageAction = createAction("SET_PAGINATION", async (esName, pageNum: number) => {
  return { esName, pageNum };
});
/**
 * Clear one filter value
 */
export const clearOneFilterAction = createAction("CLEAR_ONE_FILTER_VALUE", (esName, filterName) => {
  return { esName, filterName };
});

const reducer = handleActions({
  [updateFitlerValueAction]: (state: ES, { payload: { esName, id, value } }) => produce(state, (draft) => {
    draft[esName] = draft[esName] || unFrozenObject(initialESState);
    draft[esName].filters[id] = draft[esName]?.filters[id] || {};
    draft[esName].filters[id].value = value;
    draft[esName].pagination.current = 1;

    const filterConfig = getSearchFilters(esName)[id] || {};
    const filtersToHide = filterConfig.hideFiltersOnSet || [];

    // Hide/Show filters when condition filter is set/unset
    if (!_.isEmpty(filtersToHide)) {
      filtersToHide.forEach((filterId) => {
        draft[esName].filters[filterId] = draft[esName]?.filters[filterId] || {};
        draft[esName].filters[filterId].hide = _.isEmpty(value) ? false : true;
        draft[esName].filters[filterId].value = undefined;
      });
    }

    return draft;
  }),
  [loadFilterAction]: {
    FULFILLED: (state: ES, { payload: { esName, id, type, options } }) => produce(state, (draft) => {
      draft[esName] = draft[esName] || unFrozenObject(initialESState);
      draft[esName].filters[id] = draft[esName].filters[id] || {};
      draft[esName].filters[id].options = options;
      draft[esName].filters[id].type = type;
      draft[esName].filters[id].name = id;
      return draft;
    })
  },
  [loadSearchResultAction]: {
    PENDING: (state: ES) => {
      const newState = {};
      _(getESConfigs()).each((val, key) => {
        let esState = state[key] || unFrozenObject(initialESState);
        esState = { ...esState, result: { ...esState.result, loading: true, data: undefined } };
        newState[key] = esState;
      });
      return newState;
    },
    FULFILLED: (state: ES, { payload: { esName, data, total, current } }) => produce(state, (draft) => {
      // block when the load result function is in progress
      // if (progressCount === 0) {
      draft[esName] = draft[esName] || unFrozenObject(initialESState);
      draft[esName].result = { data, total, loading: false };
      draft[esName].pagination.current = current;

      _(getESConfigs()).each((val, key) => {
        draft[key].result.loading = false;
      });
      // }
      return draft;
    })
  },
  [setPageAction]: {
    FULFILLED: (state: ES, { payload: { esName, pageNum } }) => produce(state, (draft) => {
      draft[esName] = draft[esName] || unFrozenObject(initialESState);
      draft[esName].pagination.current = pageNum;
      return draft;
    }),
  },
  [clearFiltersAction]: (state: ES, { payload: { esName } }) => produce(state, (draft) => {
    draft[esName] = draft[esName] || unFrozenObject(initialESState);
    draft[esName].filters = {};
    return draft;
  }),
  [clearFiltersExceptKeywordAction]: (state: ES, { payload: { esName } }) => produce(state, (draft) => {
    draft[esName] = draft[esName] || unFrozenObject(initialESState);
    draft[esName].filters = { Keyword: draft[esName].filters.Keyword };
    return draft;
  }),
  [clearOneFilterAction]: (state: ES, { payload: { esName, filterName } }) => produce(state, (draft) => {
    delete draft[esName].filters[filterName];
    return draft;
  }),
}, initialState);

export default reducer;
