import { Backdrop } from "@material-ui/core";
import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";
import { color } from "@resources/styles";
import _ from "lodash";
import React, { ReactNode } from "react";
import styled from "styled-components";

interface SpinProgressProps extends CircularProgressProps {
  display: boolean;
  fullSize?: boolean;
  children: ReactNode;
  size?: number | string;
}

const RelativeDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const GreyBackground = styled.div<any>`
  position: absolute;
  height: 100%;
  width: 100%;
  display: ${(props) => (props.isLoading ? "block" : "none")};
  background-color: ${color.grey};
  opacity: 0.5;
  z-index: 4;
`;

const CircularProgressInCenter = styled(({ isLoading, ...others }) => {
  return (
    <GreyBackground isLoading={isLoading}>
      <CircularProgress size={24} style={{ display: isLoading ? "inline-block" : "none" }} {...others} />
    </GreyBackground>
  );
})`
  position: absolute;
  color: ${color.primaryDarker};
  top: 50%;
  left: 50%;
  z-index: 6;
  margin-top: -12px;
  margin-left: -12px;
`;

const SpinProgress: React.FC<SpinProgressProps> = ({ display, children, fullSize, size, style, ...props }) => {
  const fullSizeProgressStyle: any = fullSize
    ? {
      top: "50vh",
      position: "fixed",
    }
    : { position: "absolute" };

  return (
    <RelativeDiv>
      <Backdrop style={{ zIndex: 100, width: "100%", position: "absolute", backgroundColor: "#FFF6", ...style }} open={display}>
        <CircularProgress size={size} style={{ color: color.primary, ...fullSizeProgressStyle }} />
      </Backdrop>
      {children}
    </RelativeDiv>
  );
};

export default React.memo(SpinProgress, _.isEqual);
