import config from "@config/index";
import { FooterProps } from "@reducers/navigation";
import * as ROUTE from "@resources/routeConst";

const footerConfig: FooterProps = {
  navigation: [
    {
      name: "navigation.company.title",
      children: [
        { name: "navigation.company.about", link: ROUTE.ABOUT_CANNALOGUE_STORY },
        { name: "navigation.company.contact_us", link: ROUTE.CONTACT_US },
        { name: "navigation.company.terms_and_conditions", link: ROUTE.TERMSANDCONDITIONS },
        { name: "navigation.company.privacy_policy", link: ROUTE.PRIVACYPOLICY }
      ]
    },
    {
      name: "navigation.process.title",
      children: [
        { name: "navigation.process.faqs", link: ROUTE.FAQ },
        { name: "navigation.process.insurance_coverage", link: ROUTE.INSURANCE_COVERAGE },
      ]
    },
    {
      name: "navigation.resources.title",
      children: [
        { name: "navigation.resources.affiliate_program", link: ROUTE.AFFILIATE_PROGRAM },
        { name: "navigation.resources.cbd_helpline", link: ROUTE.CBD_HELP },
        { name: "navigation.resources.united_program", link: ROUTE.UNITED_PROGRAM },
        { name: "navigation.resources.cannalogue_column", link: ROUTE.EDUCATION },
        { name: "navigation.resources.medical_cannabis_101", link: ROUTE.CANNABIS_101 },
        { name: "navigation.resources.partners", link: ROUTE.PARTNERS },
        { name: "navigation.resources.clinical_trials_research", link: ROUTE.CLINICAL_TRIALS_RESEARCH },
      ]
    },
    {
      name: "navigation.connect.title",
      children: [
        { name: "navigation.connect.facebook", link: config.social_link.facebook },
        { name: "navigation.connect.twitter", link: config.social_link.twitter },
        { name: "navigation.connect.instagram", link: config.social_link.instagram },
        { name: "navigation.connect.linkedIn", link: config.social_link.linkedin },
      ]
    },
  ]
};

export default footerConfig;
