import Fab, { FabProps } from "@material-ui/core/Fab";
import { color } from "@resources/styles";
import React from "react";
import styled from "styled-components";

const CircleButton = styled(({ disabled, children, ...props }) => <Fab {...props}>{children}</Fab>)`
  color: ${color.black};
  width: 37.5px;
  height: 37.5px;
  background-color: ${color.white};
  box-shadow: 0 2px 4px 0 rgba(82, 82, 83, 0.3);
  & svg {
    fill: ${color.black};
  }
  &:hover {
    background-color: ${color.primary};
    color: ${color.white};
    box-shadow: 0 2px 4px 0 rgba(82, 82, 83, 0.3);
    & svg {
      fill: ${color.white};
    }
  }
  ${(props) =>
    props.disabled
      ? `
    opacity: 0.6;
    background-color: ${color.grayLighter};
    cursor: unset;
    box-shadow: 0 2px 4px 0 rgba(82,82,83,0.3);
    &:hover {
      background-color: ${color.grayLighter};
      & svg {
        fill: ${color.black};
      }
    }
  `
      : ""}
`;

export default CircleButton;
