import { Row, StyledParagraph } from "@components/general";
import useFormField from "@hooks/useFormField";
import { Collapse, FormHelperText, Grid, IconButton, InputAdornment, Paper, Popper } from "@material-ui/core";
import IconClear from "@resources/images/IconClear.svg";
import IconDone from "@resources/images/IconDone.svg";
import Visibility from "@resources/images/visibility.svg";
import VisibilityOff from "@resources/images/visibilityOff.svg";
import { color } from "@resources/styles";
import React from "react";
import styled from "styled-components";
import StyledOutlinedInput from "./StyledOutlinedInput";

const StyledIconButton = styled(IconButton)`
  & svg {
    fill: ${color.grey};

    &:hover {
      fill: ${color.black};
    }
  }
`;

const PasswordRule: React.FC<any> = ({ isGood, label }) => {
  return (
    <Grid container alignItems="center">
      <Grid item>{isGood ? <IconDone style={{ fill: "green" }} /> : <IconClear style={{ fill: "red" }} />}</Grid>
      <Grid item style={{ fontSize: 14, lineHeight: "20px" }}>
        {label}
      </Grid>
    </Grid>
  );
};

// { input, placeholder, label, meta: { touched, error }, children, ...custom }
const FormikPasswordField: React.FC<any> = ({ label, placeholder, autoComplete, disabled, showHint = false, name, children, ...custom }) => {
  const [show, setShow] = React.useState(false);
  const { field, isSubmitting, hasError, error, setFieldValue } = useFormField({ name });
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const passwordRuleLength = (val) => val && val.length >= 8;
  const passwordRuleUpperLetter = (val) => val && /[A-Z]/.test(val);
  const passwordRuleLowerLetter = (val) => val && /[a-z]/.test(val);
  const passwordRuleNumber = (val) => val && /[0-9]/.test(val);

  const handleOnChange = (e) => {
    setFieldValue(name, e.target.value);
    if (e.target.value === "") {
      setOpen(false);
    } else {
      if (
        passwordRuleLength(e.target.value) &&
        passwordRuleUpperLetter(e.target.value) &&
        passwordRuleLowerLetter(e.target.value) &&
        passwordRuleNumber(e.target.value)
      ) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  };

  const handleOnBlur = (e) => {
    field.onBlur(e);
    setOpen(false);
  };

  const handleOnclick = (e) => {
    if (anchorRef.current !== null) {
      if (!anchorRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOnclick, false);
    return () => document.removeEventListener("mousedown", handleOnclick, false);
  }, []);

  return (
    <>
      <Row>
        <StyledParagraph
          align="left"
          style={{ fontWeight: 600 }}
          lineHeight={20}
          size={17}
          color={color.black}
          textEllipsis={true}
          component="label"
          variant="body1"
        >
          <label htmlFor={name}>{label}</label>
        </StyledParagraph>
      </Row>

      <div ref={anchorRef}>
        <StyledOutlinedInput
          error={hasError}
          placeholder={placeholder}
          disabled={isSubmitting || disabled}
          {...field}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          {...custom}
          autoComplete={autoComplete ? autoComplete : "current-password"}
          type={show ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <StyledIconButton aria-label="toggle password visibility" onClick={() => setShow(!show)} edge="end">
                {show ? <VisibilityOff /> : <Visibility />}
              </StyledIconButton>
            </InputAdornment>
          }
          fullWidth
        >
          {children}
        </StyledOutlinedInput>
      </div>

      {showHint && (
        <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start">
          <Paper style={{ padding: 20, zIndex: 1 }}>
            <PasswordRule isGood={passwordRuleLength(field.value)} label={"8 characters at least."} />
            <PasswordRule isGood={passwordRuleUpperLetter(field.value)} label={"One Uppercase letter at least."} />
            <PasswordRule isGood={passwordRuleLowerLetter(field.value)} label={"One Lowercase letter at least."} />
            <PasswordRule isGood={passwordRuleNumber(field.value)} label={"One Number at least."} />
          </Paper>
        </Popper>
      )}
      <Collapse in={hasError}>
        <Grid item style={{ display: "block" }} className="invalid-feedback">
          <FormHelperText error={hasError}>{error}</FormHelperText>
        </Grid>
      </Collapse>
    </>
  );
};

export default FormikPasswordField;
