import config from "@config/index";
import { cannalogueAlert } from "@reducers/alerts";
import { SignupProfile, User } from "@reducers/types";
import axios from "@utils/axios";
import { CANNALOGUE_USER, clearUserInfoInCookie, getCookie, saveUserInfoToCookie } from "@utils/cookie";
import useWindow from "@utils/useWindow";
import produce from "immer";
import { Reducer } from "redux";
import { createAction, handleActions } from "redux-actions";

const connection = config.authentication.connection;
const userPath = "/api/users/current";
const cardPath = `${userPath}/payments/credit_cards`;

export type UserState = User & {
  loading: boolean;
  loggingOut?: boolean;
  failed?: boolean;
};

export const getUserPaymentAction = createAction("GET_USER_PAYMENT", async () => {
  const { data } = await axios.get(cardPath);
  return { payment: { credit_cards: data } };
});

const initialState: UserState = {
  loading: false,
  loggingOut: false,
  ...({} as User),
};

export const loadUserProfile = async () => {
  try {
    const { data } = await axios.get("/api/profile");
    saveUserInfoToCookie(data);
    return { data };
  } catch (err) {
    clearUserInfoInCookie();
    throw err;
  }
};

export function loadSignupProfile({ token, type }): Promise<{ data: SignupProfile }> {
  return axios.get(`/api/profile/signup_profile?type=${type}&token=${token}`);
}

export const signUpCall = async (values, recaptchaToken) => {
  return axios.post(`/api/profile/fast_signup?recaptcha_v3_token=${recaptchaToken}`, values);
};

export const sendVerifyEmail = async (email, returnUrl) => {
  return axios
    .post("/auth/public/send-verification-email", { connection, returnUrl, email })
    .catch(() => cannalogueAlert.error("System error. Sending verification email failed"));
};

export const loginCall = async ({ email, password }, recaptchaToken) => {
  let loginEndpoint = `/auth/signin?recaptcha_v3_token=${recaptchaToken}`;
  if (useWindow.location.hostname.startsWith("local")) {
    loginEndpoint = "/auth/signin";
  }
  const { data } = await axios.post(loginEndpoint, { username: email, password, connection });
  return data;
};

export const loginWithToken = async (token) => {
  const { data } = await axios.get(`/auth/signin_with_token?token=${token}`);
  return data;
};

export const resetPasswordCall = async (email, recaptchaToken) => {
  const { data } = await axios.post(`/api/profile/send_reset_password_user_email?recaptcha_v3_token=${recaptchaToken}`, { email, connection });
  return data;
};

export const updateProfile = async (data) => {
  return await axios.post("/api/profile", data);
};

export const updateProfilePwd = async (data) => {
  return await axios.post("/api/profile/password", data);
};

export const completeProfile = async (data) => {
  return await axios.post("/api/profile/complete_profile", data);
};

export const acquireVisitorId = async (rememberMe) => {
  return axios.post("/auth/ageVerified", { rememberMe });
};

export const loadUserProfileAction: any = createAction("LOAD_USER_PROFILE", async () => {
  const { data } = await loadUserProfile();
  return data;
});

export const logoutAction: any = createAction("SIGNOUT_ACTION");

export const updateHealthProfile: any = createAction("UPDATE", async (value) => {
  const { data } = await axios.post("/api/health_profile", value);
  return { data };
});

const reducer: Reducer<UserState> = handleActions(
  {
    [logoutAction]: (state: any) => ({ loading: false, loggingOut: true }),
    [loadUserProfileAction]: {
      PENDING: (state: any) => ({ ...state, loading: true }),
      FULFILLED: (state: any, { payload }) =>
        produce(state, (draft) => {
          // tslint:disable-next-line:no-parameter-reassignment
          draft = Object.assign(draft, payload, { loading: false, loggingOut: false });
        }),
      REJECTED: (state: any) => ({ loading: false, loggingOut: false, failed: true }),
    },
    [getUserPaymentAction]: {
      PENDING: (state: any) =>
        produce(state, (draft: { loading: boolean }) => {
          draft.loading = true;
        }),
      FULFILLED: (state, { payload }) => {
        return { ...state, ...payload };
      },
      REJECTED: (state: any) =>
        produce(state, (draft: { loading: boolean }) => {
          draft.loading = false;
        }),
    },
  },
  { ...initialState, ...getCookie(CANNALOGUE_USER) }
);

export default reducer;
