import { createContext } from "react";

interface ServerRouteProps {
  pathname: string;
  query: any;
  asPath: string;
  history: HistoryProps[];
}

interface HistoryProps {
  pathname: string;
  asPath: string;
  searchParams: any;
}

const ServerRouteContext = createContext<ServerRouteProps>({ pathname: "/", query: {}, asPath: "/", history: [] });

export default ServerRouteContext;
