import config from "@config/index";
import { Checkout, OrderItem, ProductVariant } from "@reducers/types";
import { checkout2GApayload } from "./utils";

export const trackUserLogin = (userId: string) => {
  try {
    // @ts-ignore
    gtag("set", {
      user_id: userId,
    });
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'user login' ", error);
  }
};

export const trackPageView = (url: string) => {
  try {
    // @ts-ignore
    gtag("config", config.gaCode, {
      page_location: url,
    });
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'page_location' ", error);
  }
};

export const trackProductView = (productVariant: ProductVariant & { category: string }) => {
  try {
    // @ts-ignore
    gtag("event", "view_item", {
      items: [
        {
          id: productVariant.id,
          name: productVariant.product_name,
          brand: productVariant.producer_name,
          variant: productVariant.short_name,
          category: productVariant.category,
          quantity: 1,
          price: productVariant.price
        }
      ]
    });
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'view_item' ", error);
  }
};

interface Variant {
  id: string;
  name: string;
  producer_name: string;
  product_name: string;
  combined_options?: string;
}

interface TrackAddToCartProps {
  variant: Variant;
  list_name: string;
  quantity: number;
  price: number;
}

export const trackAddToCart: (props: TrackAddToCartProps) => void = (props) => {
  const { variant, list_name, quantity, price } = props;
  try {
    // @ts-ignore
    gtag("event", "add_to_cart", {
      items: [
        {
          id: variant.id,
          name: variant.product_name,
          brand: variant.producer_name,
          list_name,
          variant: variant.combined_options,
          quantity,
          price,
        },
      ],
    });
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'add_to_cart' ", error);
  }
};

interface TrackFilterUsageProps {
  label: string;
  value: string;
}

export const trackFilterUsage: (props: TrackFilterUsageProps) => void = (props) => {
  const { label, value } = props;
  try {
    // @ts-ignore
    gtag("event", "filter_usage", {
      "event_category": "filter",
      "event_label": label,
      "value": value
    });
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'filter_usage' ", error);
  }
};

export const trackRemoveFromCart = (orderItem: OrderItem) => {
  try {
    // @ts-ignore
    gtag("event", "remove_from_cart", {
      items: [
        {
          id: orderItem.variant_id,
          name: orderItem.product_name,
          brand: orderItem.producer_name,
          list_name: "Shopping Cart",
          variant: orderItem.variant_combined_options,
          quantity: orderItem.quantity,
          price: orderItem.price,
        },
      ],
    });
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'remove_from_cart' ", error);
  }
};

export const trackCheckoutStart = (checkout: Checkout) => {
  try {
    // @ts-ignore
    gtag("event", "begin_checkout", checkout2GApayload(checkout));
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'begin_checkout' ", error);
  }
};

export const trackCheckoutProgress = (record: any, checkout_step: number) => {
  try {
    // @ts-ignore
    gtag("event", "checkout_progress", { ...record, checkout_step });
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'checkout_progress' ", error);
  }
};

export const gaTrackPurchase = (checkout: Checkout) => {
  try {
    // @ts-ignore
    gtag("event", "purchase", checkout2GApayload(checkout));
  } catch (error) {
    // @ts-ignore
    nextLogger.error("Failed to track 'purchase' ", error);
  }
};
