import { createGlobalStyle } from "styled-components";
import color from "./color";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Proxima Nova W01 Regular;
    font-size: 16px;
    color: ${color.black};
  }

  /** hide recaptcha icon from the bottom right corner.
   *  only display recaptcha where it is used
   *  it can be added as a property (showRecaptcha) in Page component
   */
  .grecaptcha-badge { visibility: hidden; }

  .inherit {
    text-decoration: none;
    width: inherit;
    height: inherit;
    display: inherit;
    position: inherit;
    align-items: inherit;
    justify-content: inherit;
    align-content: inherit;
  }

  .MuiAlert-message {
    padding: 5px 0px 5px 0px;
  }

  .MuiAlert-standardSuccess {
    background-color: ${color.blue};
    color: ${color.white};
    & svg {
      fill: ${color.white};
    }
  }

  .MuiAlert-standardError {
    background-color: ${color.error};
    color: ${color.white};
    & svg {
      fill: ${color.white};
    }
  }

  .MuiFormHelperText-contained {
    margin: 0;
  }

  p {
    margin: 0;
  }

  .MuiInputBase-root.Mui-disabled {
    background-color: ${color.greyBorderOpacity(0.3)} !important;
  }

  .MuiFormControlLabel-label.Mui-disabled {
    * {
      color: rgba(0, 0, 0, 0.38) !important;
      cursor: default;
    }
  }

  .MuiButton-outlined.Mui-disabled {
    background-color: ${color.greyBorderOpacity(0.3)} !important;
  }

  .MuiSelect-icon.Mui-disabled {
    background-color: unset !important;
  }

  input {
    font-size: 17px !important;
    font-family: Proxima Nova W01 Light !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
  }

  .MuiSelect-root {
    font-size: 17px !important;
    font-family: Proxima Nova W01 Light !important;
    background-color: ${color.realWhite};
    & .Mui-disabled {
      background-color: ${color.greyBorderOpacity(0.25)};
    }
  }

  .MuiInputBase-root {
    background-color: ${color.realWhite};
    & .Mui-disabled {
      background-color: ${color.greyBorderOpacity(0.25)};
    }
    &:focus-within {
      & svg {
        fill: ${color.black};
      }
    }
  }
  .this-is-image {
    width: auto !important;
  }

  .MuiOutlinedInput-inputAdornedEnd {
    padding-right: 6px;
  }
  .MuiSelect-iconOpen {
    fill: ${color.blue};
  }
  .Mui-focused {
    border: unset;
  }

  .MuiFormControlLabel-root {
    margin-left: -8px !important;
  }


  .sq-creditcard {
    background: ${color.primary};
    &:hover {
      background: ${color.primaryDarker};
    }
  }

  .coupon-tag {
    position: relative;
    background: #F9B846;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    padding: 2px 5px 3px;
    margin-right: 8px;
  }

  .coupon-tag:after {
    content: "";
    position: absolute;
    right: -5px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-top: 9px solid #F9B846;
    border-bottom: 10px solid #F9B846;
  }
`;

export default GlobalStyle;
