import AddressSelector from "@components/address-selector";
import { SuggestionFilter } from "@components/filter";
import { CheckoutPopover, GridLayout, LinkButton, ParagraphButton, Row, StyledParagraph, WidthContext } from "@components/general";
import Arrow from "@components/general/Arrow";
import Logo from "@components/general/Logo";
import config from "@config/index";
import { Fade as MatFade, Grid, SwipeableDrawer } from "@material-ui/core";
import { StoreProps } from "@reducers/store";
import IconMenu from "@resources/images/IconMenu.svg";
import IconSearch from "@resources/images/IconSearch.svg";
import IconShoppingBag from "@resources/images/IconShoppingBag.svg";
import { color } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import { formatPrice } from "@utils/utils";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import MobileMenu from "./MobileMenu";
import MobileUserPopover from "./MobileUserPopover";

const MobileHeader = () => {
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const width = useContext(WidthContext);
  const mobile = width === "xs";
  const { t } = useTranslation("components/navigation");
  const checkout = useSelector((state: StoreProps) => state.checkout);
  const user = useSelector((state: StoreProps) => state.user);
  const item_total = checkout.item_total || 0;
  const total_grams_in_cart = checkout.total_medical_grams || 0;
  const used_quantity = user?.prescription?.current_period?.used_quantity || 0;
  const period_total = user.prescription?.current_period?.total || 0;

  return (
    <div style={{ height: 88 }}>
      <GridLayout
        style={{ position: "sticky", paddingTop: 8, paddingBottom: 8 }}
        outterStyle={{ position: "fixed", zIndex: 10, backgroundColor: "#fff", boxShadow: "0px 5px 5px #8885" }}
      >
        <Row alignItems="center">
          <MatFade in={searchOpen} style={{ display: searchOpen ? "" : "none" }}>
            <Grid container alignItems="center">
              <Grid item onClick={() => setSearchOpen(false)} xs={1}>
                <Arrow direction="top" style={{ fill: color.black }} />
              </Grid>
              <Grid item xs={11}>
                <SuggestionFilter
                  id="Keyword"
                  name="Keyword"
                  placeholder={t("suggestion_input_placeholder")}
                  dataField={["name", "producer.name", "categories", "cannabis_type", "spec.brand", "supplier.name"]}
                  fieldWeights={[3, 2, 1, 1, 1, 1, 1]}
                  size={20}
                />
              </Grid>
            </Grid>
          </MatFade>
          {!searchOpen && (
            <Grid item xs={12}>
              <Grid container style={{ flexWrap: "nowrap" }} justify="space-between" alignItems="center" spacing={1}>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <ParagraphButton style={{ marginRight: 5 }} onClick={() => setOpen(true)}>
                        <IconMenu />
                      </ParagraphButton>
                    </Grid>
                    <Grid item>
                      <Logo />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" style={{ flexWrap: "nowrap" }} spacing={2}>
                    {
                      config.drup && <Grid item>
                        <AddressSelector />
                      </Grid>
                    }
                    {!user.first_name && (
                      <Grid item>
                        <MobileUserPopover />
                      </Grid>
                    )}
                    <Grid item>
                      <IconSearch stroke={color.black} fill="none" onClick={() => setSearchOpen(true)} style={{ paddingTop: 5, height: 35 }} />
                    </Grid>
                    {!user.first_name ? (
                      <Grid item>
                        <IconShoppingBag style={{ height: 35 }} />
                      </Grid>
                    ) : (
                      <>
                        <Grid item style={{ padding: 0 }}>
                          <CheckoutPopover />
                        </Grid>
                        {config.medicalFlow && (
                          <Grid item>
                            <Grid container style={{ minWidth: 60, maxWidth: 80 }} direction="column" item>
                              <Grid item>
                                <StyledParagraph textEllipsis align="right" size={19} color={color.black}>
                                  {formatPrice(item_total)}
                                </StyledParagraph>
                              </Grid>
                              <Grid item>
                                <StyledParagraph textEllipsis align="right" size={15} color={color.black}>
                                  {used_quantity + total_grams_in_cart}/{period_total}g
                                </StyledParagraph>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <SwipeableDrawer
                  anchor="left"
                  transitionDuration={{ enter: 450, exit: 200 }}
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  PaperProps={{
                    style: { backgroundColor: color.semiWhite },
                  }}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  SwipeAreaProps={{
                    style: { height: "100vh", width: 20 },
                  }}
                >
                  <MobileMenu />
                </SwipeableDrawer>
              </Grid>
            </Grid>
          )}
        </Row>
      </GridLayout>
    </div>
  );
};

export default MobileHeader;
