import _ from "lodash";
import getConfig from "next/config";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig() || {};

const hasEnv = process && process.env;
const version =
  hasEnv && process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "";

const currencyMap = {
  CA: "CAD",
  US: "USD",
  JM: "USD",
};

const birthdayRequirementInSignup = {
  CA: true,
  US: true,
  JM: true,
};

const ageVerificationMap = {
  CA: true,
  US: true,
  JM: true,
};

const combinedConfig = {
  authorizationHeader: "Authorization",
  version,
  square: {
    applicationId: "sandbox-sq0idb-EFfVXQLQiAw73ZgcbSh8OQ",
    locationId: "ZFX7HN9WRJ07T",
    sandbox: true,
  },
  social_link: {
    facebook: "https://www.facebook.com/cannalogue/",
    twitter: "https://twitter.com/cannalogue",
    instagram: "https://www.instagram.com/cannaloguecares",
    linkedin: "https://www.linkedin.com/company/cannalogue/",
  },
  cryptoEnabled: true,
  cryptoChainId: 5,    // 1 is mainnet for Prod, 5 is goerli test network for testing env
  ...publicRuntimeConfig,
};

export default {
  cryptoEnabled: combinedConfig.cryptoEnabled,
  cryptoChainId: combinedConfig.cryptoChainId,
  currency: currencyMap[combinedConfig.country],
  requireBirthday: birthdayRequirementInSignup[combinedConfig.country],
  ageVerification: ageVerificationMap[combinedConfig.country],
  isCountry: (cty) => {
    return combinedConfig.country === cty;
  },
  ...combinedConfig,
};
