import { Row, StyledParagraph } from "@components/general";
import { Grid, Paper } from "@material-ui/core";
import { color } from "@resources/styles";
import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import styled from "styled-components";
import StyledAutoComplete, { StyledAutoCompleteProps } from "./StyledAutoComplete";

// controlled component
/**
 * @param getSuggestionValue Use this to pick a key from suggestion object to string for display, when suggestion is an object
 */
export interface GeneralAutoSuggestFieldProps extends Omit<StyledAutoCompleteProps, "onChange" | "onSelect"> {
  value: any;
  onChange: (event: any, value: string) => void;
  getSuggestion: (inputValue: string) => any;
  onSelect: (selected: any) => void;
  getSuggestionValue?: (suggestion: any) => string;
  loading?: boolean;
}

const StyledAutosuggest = styled(({ className, ...others }) => (
  <Autosuggest theme={{ container: className, suggestionsContainer: "suggestion-container", suggestionsList: "suggestion-list" }} {...others} />
))`
  .suggestion-container {
    z-index: 1;
  }

  .suggestion-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const GeneralAutoSuggestField: React.FC<GeneralAutoSuggestFieldProps> = ({
  value,
  onChange,
  getSuggestionValue,
  getSuggestion,
  onSelect,
  loading = false,
  ...extra
}) => {
  const [suggestions, setSuggestions] = useState<any>([]);

  const anchor = React.useRef<HTMLDivElement>();

  const handleChange = (event, { newValue }) => {
    onChange(event, newValue);
  };

  const clearSuggestion = () => {
    setSuggestions([]);
  };

  const fetchSuggestion = async ({ value: v }) => {
    const data = v.length === 0 ? [] : await getSuggestion(v);
    setSuggestions(data);
  };

  const onSuggestionSelected = (event?, data?) => {
    if (event) {
      event.preventDefault();
    }
    onSelect(data?.suggestion);
    clearSuggestion();
  };

  const getAttachmentLocation = (type: "top" | "width") => {
    if (type === "top") {
      const top = anchor && anchor.current && anchor.current.offsetTop + anchor.current.getBoundingClientRect().height;
      return top;
    }
    const width = anchor && anchor.current && anchor.current.clientWidth + 1.5;
    return width || 0;
  };

  return (
    <StyledAutosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={fetchSuggestion}
      onSuggestionsClearRequested={clearSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      focusInputOnSuggestionClick={false}
      getSuggestionValue={(suggestion: any) => (getSuggestionValue ? getSuggestionValue(suggestion) : suggestion)}
      renderSuggestion={(suggestion: any, { query, isHighlighted }) => {
        const background: any = () => {
          if (isHighlighted) {
            return color.blueOpcity(0.4);
          }
          if (suggestion.name === value) {
            return color.blueOpcity(0.6);
          }
          return "unset";
        };

        if (!getSuggestionValue && typeof suggestion !== "string") {
          // @ts-ignore
          nextLogger.error("Suggestion must be a string");
          return "";
        }

        return (
          <Row style={{ padding: "15px", backgroundColor: background() }}>
            <Grid item xs={12}>
              <StyledParagraph align="left" guideline="p3" color={color.black}>
                {getSuggestionValue ? getSuggestionValue(suggestion) : suggestion}
              </StyledParagraph>
            </Grid>
          </Row>
        );
      }}
      // tslint:disable-next-line:no-shadowed-variable
      renderInputComponent={({ ref, getSuggestionValue, getSuggestion, onSelect, ...props }: any) => (
        <StyledAutoComplete
          {...props}
          loading={loading}
          value={props.value}
          onChange={(e) => props.onChange(e, { newValue: e.target.value, method: "type" })}
          onBlur={(e) => props.onBlur && props.onBlur(e)}
          fullWidth
          divRef={anchor}
          inputRef={ref}
        />
      )}
      renderSuggestionsContainer={(props) => (
        <Paper
          elevation={2}
          variant="outlined"
          style={{
            display: props.children ? "block" : "none",
            position: "absolute",
            top: getAttachmentLocation("top"),
            width: getAttachmentLocation("width"),
            maxHeight: "40vh",
            overflow: "auto",
            border: `2px solid ${color.blue}`,
            boxShadow: `0px 6px 5px 6px ${color.blueOpcity(0.5)}`,
          }}
          {...props.containerProps}
        >
          {props.children}
        </Paper>
      )}
      inputProps={{
        ...extra,
        value,
        type: "search",
        onChange: handleChange,
        autoComplete: "off",
      }}
    />
  );
};

export default GeneralAutoSuggestField;
