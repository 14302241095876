import _ from "lodash";
import NextI18Next from "next-i18next";
import isServer from "./isServer";

// preload all namespaces
let ns;
if (isServer) {
  // tslint:disable-next-line:no-eval
  const fs = eval("require(`fs`)");
  ns = JSON.parse(fs.readFileSync(`public/static/locales/namespaces.json`));
}

const languages = ["en", "fr"];
const nextI18NextInstance = new NextI18Next({
  defaultLanguage: "en",
  // somehow this fix react-i18next:: i18n.languages were undefined or empty undefined,
  // https://github.com/isaachinman/next-i18next/issues/374
  // lng: "en",
  fallbackLng: "en",
  otherLanguages: ["fr"],
  shallowRender: true,
  parseMissingKeyHandler: (key) => {
    return key;
  },
  ns,
});

nextI18NextInstance.i18n.languages = languages;
const useTranslationHook = nextI18NextInstance.useTranslation;

export const useTranslation = (...args) => {
  const i18nTrans = useTranslationHook(...args);
  const namespace = Array.isArray(args[0]) ? args[0][0] : (args[0] || "common");

  return {
    ...i18nTrans,
    tOrNull(key, options: any = {}) {
      if (!key) return "";
      options.ns = options?.ns || namespace;
      if (i18nTrans.i18n.exists(key, options)) {
        return i18nTrans.t(key, options);
      } else {
        return "";
      }
    },
    getObject(key, defaultPhrases = []) {
      const options = { returnObjects: true } as any;
      let phrase = key;
      if (key.includes(":")) {
        options.ns = key.split(":")[0];
        phrase = key.split(":")[1];
      }

      const result = i18nTrans.t(phrase, options);
      if (_.isString(result) || _.isEmpty(result)) {
        return defaultPhrases as any;
      } else {
        return result as any;
      }
    }
  };
};

export const appWithTranslation = nextI18NextInstance.appWithTranslation;
export const i18n = nextI18NextInstance.i18n;
export const withTranslation = nextI18NextInstance.withTranslation;

export default nextI18NextInstance;
