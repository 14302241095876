import { AddressObject } from "@reducers/types";
import { Reducer } from "redux";
import { createAction, handleActions } from "redux-actions";

// This address reducer is used to track customer selected address in address selector (drup)
const INITIAL_STATE = {}

export const updateAddressAction = createAction("UPDATE_ADDRESS", async (address: AddressObject) => {
  return address;
});

const reducer: Reducer<AddressObject> = handleActions(
  {
    [updateAddressAction]: {
      FULFILLED: (state, { payload }) => ({ ...state, ...payload }),
    },
  },
  INITIAL_STATE
);

export default reducer;
