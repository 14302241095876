import { MenuItemType, NavigationProps } from "@reducers/navigation";
import * as ROUTE from "@resources/routeConst";
import qs from "qs";

const FixedCategories = [
  "Topicals", "Oil", "Capsules", "Edibles", "for Pets"
];

const CategoryMenus: MenuItemType[] = FixedCategories.map((c) => (
  {
    name: `Shop ${c}`,
    label: `Shop ${c}`,
    type: "link",
    position: "right",
    link: `${ROUTE.HEMP}?${qs.stringify({ Category: [`Cannabis/CBD ${c}`] })}`
  })
);

const menus: NavigationProps = {
  menu: [
    {
      name: "my_account",
      type: "list",
      hide: "mobile",
      position: "top",
      children: [
        {
          name: "account_info",
          link: ROUTE.MY_ACCOUNT_ACCOUNT_INFO,
        }, {
          name: "order_returns",
          link: ROUTE.MY_ACCOUNT_ORDER_RETURNS,
        }, {
          name: "recurring_orders",
          link: ROUTE.MY_ACCOUNT_RECURRING_ORDERS,
        }, {
          name: "invoices",
          link: ROUTE.MY_ACCOUNT_INVOICES
        }, {
          name: "shipping_address",
          link: ROUTE.MY_ACCOUNT_ADDRESSES
        }, {
          name: "payment_info",
          link: ROUTE.MY_ACCOUNT_PAYMENT_INFO
        }, {
          name: "newsletter_preference",
          link: ROUTE.MY_ACCOUNT_NEWSLETTER
        },
        {
          name: "promotions",
          link: ROUTE.MY_ACCOUNT_PROMOTIONS
        }
      ]
    }, {
      name: "home",
      position: "top",
      link: ROUTE.LANDING
    },
    ...CategoryMenus,
    { name: "Shop All CBD", type: "link", position: "right", link: ROUTE.HEMP },
    // {
    //   name: "suppliers",
    //   link: ROUTE.PARTNERS,
    //   position: "right",
    //   type: "link"
    // },
    {
      name: "cannalogue_column",
      link: ROUTE.EDUCATION,
      type: "link",
      position: "right",
    }
  ]
};

export default menus;
