import { spinnerReduxPlugin } from "@components/general/SmartSpinner";
import { smartErrorReduxPlugin } from "@hooks/useSmartError";
import isServer from "@utils/isServer";
import { useSelector } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createPromise } from "redux-promise-middleware";
import elasticsearch from "../utils/elasticsearch/reducer";
import alerts, { AlertDataProps } from "./alerts";
import checkout from "./checkout";
import crypto, { CryptoProps } from "./crypto";
import messages, { MessageDataProps } from "./messages";
import navigation, { NavigationStoreProps } from "./navigation";
import { AddressObject, Checkout } from "./types";
import user, { UserState } from "./user";
import address from "./address";

// import * as testReducer from './test'
export interface StoreProps {
  checkout: Checkout;
  navigation: NavigationStoreProps;
  user: UserState;
  alerts: AlertDataProps;
  messages: MessageDataProps[];
  crypto: CryptoProps;
  address: AddressObject;
}

export const useCurrentUser = (): UserState => {
  return useSelector((state: StoreProps) => state.user);
};

function configureStore(initialState = {}) {
  const reducers = combineReducers({
    navigation,
    user,
    elasticsearch,
    alerts,
    messages,
    checkout,
    crypto,
    address,
  });

  const middlewares = applyMiddleware(createPromise({ promiseTypeDelimiter: "/" }), spinnerReduxPlugin, smartErrorReduxPlugin);

  const devTool = process.env.NODE_ENV === "production" ? middlewares : composeWithDevTools(middlewares);

  const store = createStore(reducers, initialState, devTool);

  if (isServer) {
    return store;
  }

  // @ts-ignore-line
  if (!isServer && !window.reduxStore) {
    // @ts-ignore-line
    window.reduxStore = store;
  }
  // @ts-ignore-line
  return window.reduxStore;
}

export default configureStore;
