import { Grid } from "@material-ui/core";
import PurpleBanner from "@resources/images/banners/PurpleBanner-min.jpg";
import React, { useContext } from "react";
import { GridLayout, Row, StyledParagraph, WidthContext } from ".";
import config from "@config/index";

interface PageBannerProps {
  title?: string;
  description?: string;
  backgroundImage?: any;
  outterStyle?: React.CSSProperties;
}

const PageBanner: React.FC<PageBannerProps> = ({ title = "", description = "", backgroundImage, outterStyle }) => {
  const width = useContext(WidthContext);
  const mobile = width === "xs" || width === "sm";
  if (backgroundImage) {
    return (
      <GridLayout
        justify={mobile ? "flex-start" : "flex-end"}
        style={{ padding: mobile ? "90px 0" : "130px 0 170px" }}
        section={true}
        backgroundImage={backgroundImage}
        backgroudOverlay={true}
        outterStyle={{ backgroundPositionX: mobile ? "40%" : "0", ...outterStyle }}
      >
        <Grid item xs={11} md={7}>
          <div style={{ marginBottom: 25 }}>
            <StyledParagraph fade="bottom" align="left" guideline="h1">
              {title}
            </StyledParagraph>
          </div>
          <div>
            <StyledParagraph fade="bottom" align="left" guideline="p1" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </Grid>
      </GridLayout>
    );
  }

  const defaultBannerStyle = config.drup ? { outterStyle: { background: "black", ...outterStyle } } : { backgroundImage: PurpleBanner, outterStyle };
  return (
    <GridLayout section={true} {...defaultBannerStyle}>
      <Row>
        <Grid item xs={10} md={12}>
          <StyledParagraph fade="bottom" guideline="h1" align="left">
            {title}
          </StyledParagraph>
        </Grid>
      </Row>
      {description && (
        <Row>
          <Grid item xs={10} md={9}>
            <StyledParagraph fade="bottom" align="left" guideline="p1" dangerouslySetInnerHTML={{ __html: description }} />
          </Grid>
        </Row>
      )}
    </GridLayout>
  );
};

export default PageBanner;
