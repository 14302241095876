import Grid, { GridProps } from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";

const StyledRow = styled(({ children, ...props }) => (
  <Grid container {...props}>
    {children}
  </Grid>
))`
  justify-content: space-between;
  -webkit-justify-content: space-evenly;
`;

const IESupportEvenlyContainer: React.FC<GridProps> = ({ children, ...props }) => {
  return <StyledRow {...props}>{children}</StyledRow>;
};

export default IESupportEvenlyContainer;
