import { ShippoTrackingResponse } from "@components/my-account/ShippingTracker";
import config from "@config/index";
import { StoreProps } from "@reducers/store";
import {
  AddressObject,
  Coupon, InvoiceData, MedicalAddress, OrderDetail, PaymentMethod, ProductSearchResult,
  ProductVariant, RecurringOrder, RecurringOrderDetail, ReferralProgram, ShippingAddress, TMRSession, Tracking, WpArticle
} from "@reducers/types";
import http, { RequestTokenHolder } from "@utils/axios";
import Axios, { CancelTokenSource } from "axios";
import { useSelector } from "react-redux";
import { CANNALOGUE_USER, setCookie } from "./cookie";
import { buildFilterQueries, buildResultQuery, getResult, provinceQuery } from "./elasticsearch/querySyntaxHelper";
import { alertRequestError, goToLogInOn401 } from "./utils";
import isServer from "@utils/isServer";
import { getStore } from "./elasticsearch/config";
import _ from "lodash";
import { OrderTemplate } from "@components/application-forms/RecurringOrderForm";
import qs from "qs";

export const withCancelToken = (callback: (...args: any[]) => any): CancelTokenSource => {
  const request = Axios.CancelToken.source();
  RequestTokenHolder.request = request
  callback();
  RequestTokenHolder.request = null;
  return request;
}

export const logout = () => {
  setCookie(CANNALOGUE_USER, JSON.stringify({}), { httpOnly: false, path: "/" });
  let form = document.createElement("form");
  form.style.visibility = "hidden"; // no user interaction is necessary
  form.method = "POST"; // forms by default use GET query strings
  form.action = `/auth/signout?type=user`;
  document.body.appendChild(form); // forms cannot be submitted outside of body
  form.submit();
};

export const useLogout = (): [boolean, () => void] => {
  const loggingOut = useSelector((state: StoreProps) => state.user?.loggingOut || false);
  return [loggingOut, logout];
};

export const loadCsrfToken = async () => {
  try {
    // @ts-ignore
    nextLogger.debug("Loading CSRF Token ....");
    window.sessionStorage.setItem("csrf-token", "loading");
    const { data } = await http.get("/gateway/csrf/token");
    // @ts-ignore
    nextLogger.debug("CSRF Token loaded!");
    window.sessionStorage.setItem("csrf-token", data);
    return data;
  } catch (err: any) {
    nextLogger.error(`Failed to load csrf token, ${err.message}`);
  }
};

export const clearCsrfToken = () => {
  let csrfToken = window.sessionStorage.getItem("csrf-token");
  if (csrfToken && csrfToken !== "loading") {
    window.sessionStorage.removeItem("csrf-token");
  }
};

export const readCsrfToken = () => {
  return new Promise((resolve) => {
    let csrfToken = window.sessionStorage.getItem("csrf-token");

    if (csrfToken && csrfToken !== "loading") {
      return resolve(csrfToken);
    }

    let timer: number | null = null;
    let retrier: number | null = null;
    const interval = setInterval(() => {
      csrfToken = window.sessionStorage.getItem("csrf-token");
      if (csrfToken && csrfToken !== "loading") {
        if (interval) { window.clearInterval(interval); }
        if (timer) { window.clearTimeout(timer); }
        if (retrier) { window.clearTimeout(retrier); }
        resolve(csrfToken);
      }
    }, 200);

    retrier = setTimeout(() => {
      loadCsrfToken();
    }, 1000);

    timer = setTimeout(() => {
      if (interval) { window.clearInterval(interval); }
      if (retrier) { window.clearTimeout(retrier); }
      resolve(null);
    }, 2000);
  });
};
const cardPath = `/api/users/current/payments/credit_cards`;

export const requestModifyTMRSession = async (id: string) => {
  const { data } = await http.post(`/api/tmr_sessions/${id}/ticket`);
  return data;
};

export const getCategoryFilters = async (category) => {
  const { data } = await http.get(`/api/search_filters?category=${encodeURIComponent(category)}`);
  return data;
};

export const createTMRSession = async (session) => {
  const { data } = await http.post(`/api/tmr_sessions`, session);
  return data;
};

export const updateTMRSession = async (id, payload) => {
  const { data } = await http.put(`/api/tmr_sessions/${id}`, payload);
  return data;
};

export const deleteTMRSession = async (id: string) => {
  const { data } = await http.delete(`/api/tmr_sessions/${id}`);
  return data;
};

export const getInvoices = async (query: any = {}): Promise<{ data: InvoiceData[], headers: any }> => {
  const { data, headers } = await http.get(`/api/invoices`, { params: query });
  return { data, headers };
};

export const getCheckoutAddressOptions = async (): Promise<MedicalAddress[]> => {
  const { data } = await http.get(`/api/checkouts/current/addresses`);
  return data;
};

export const getCheckoutPaymentOptions = async (): Promise<PaymentMethod[]> => {
  const { data } = await http.get(`/api/checkouts/current/payment_options`);
  return data;
};

// export const applyCoupon = async (): Promise<PaymentMethod[]> => {
//   const { data } = await http.post(`/api/checkouts/current/payment_options`);
//   return data;
// };

export const getCheckoutCouponOptions = async (): Promise<Coupon[]> => {
  const { data } = await http.get(`/api/checkouts/current/coupons`);
  return data;
};

export const getTMRSessions = async (query = {}): Promise<{ data: TMRSession[], headers: any }> => {
  const { data, headers } = await http.get(`/api/tmr_sessions`, { params: query });
  return { data, headers };
};

export const getSymptomList = async () => {
  const { data } = await http.get(`${config.staticContent}/cms/resource/symptom_list.json`);
  return data;
};

export const getTMRSessionOptions = async () => {
  const { data } = await http.get(`/api/tmr_session_options/session_options`);
  return data;
};

export const getTMRSessionDetails = async (id: string): Promise<TMRSession> => {
  const { data } = await http.get(`/api/tmr_sessions/${id}`);
  return data;
};

export const getOrders = async (query = {}): Promise<{ data: OrderDetail[], headers: any }> => {
  const { data, headers } = await http.get(`/api/orders`, { params: query });
  return { data, headers };
};

export const getRecurringOrders = async (query = {}): Promise<{ data: RecurringOrder[], headers: any }> => {
  const { data, headers } = await http.get(`/api/recurring_order_templates`, { params: query });
  return { data, headers };
};

export const getRecurringOrderDetails = async (id): Promise<RecurringOrderDetail> => {
  const { data } = await http.get(`/api/recurring_order_templates/${id}`);
  return data;
};

export const calculateCheckout = async (values) => {
  const { data } = await http.get(
    `/api/checkout/calculate_checkout?${qs.stringify(values)}`
  );
  return data;
};

export const createRecurringOrder = async (values: OrderTemplate & { total: number }): Promise<RecurringOrder> => {
  const { data } = await http.post("/api/recurring_order_templates", values);
  return data;
}
export const cancelRecurringOrder = async (id: string) => {
  const { data } = await http.post(`/api/recurring_order_templates/${id}/cancel`);
  return data;
}
export const updateRecurringOrder = async (values: OrderTemplate & { total: number }, id: string) => {
  const { data } = await http.put(`/api/recurring_order_templates/${id}`, values);
  return data;
}

export const getOrderDetails = async (id): Promise<OrderDetail> => {
  const { data } = await http.get(`/api/orders/${id}`);
  return data;
};

export const getOrderPaymentOptions = async (id): Promise<OrderDetail> => {
  const { data } = await http.get(`/api/orders/${id}/payment_options`);
  return data;
};

export const getOrderShippingRates = async (id): Promise<OrderDetail> => {
  const { data } = await http.get(`/api/orders/${id}/shipping_rates`);
  return data;
};

export const getTrackingDetails = async (tracking: Tracking): Promise<ShippoTrackingResponse> => {
  const { data } = await http.get(`/shipping-service/v1/tracking?carrier=${tracking?.carrier_name}&tracking_number=${tracking?.number}`);
  return data;
};

export const getCarrierToken = async (carrierName: string): Promise<{ token: string }> => {
  const { data } = await http.get(`/shipping-service/v1/carriers?name=${carrierName}`);
  return data;
};

export const getPayment = async () => {
  const { data } = await http.get(cardPath).catch(alertRequestError()).catch(goToLogInOn401);
  return data;
};

export const getCoupons = async (): Promise<{ data: Coupon[], headers: any }> => {
  const { data, headers } = await http.get(`/api/coupons`);
  return { data, headers };
};

export const getCurrentReferralProgram = async (): Promise<ReferralProgram> => {
  const { data } = await http.get(`/api/coupons/current_referral_program`);
  return data;
};

export const getAddresses = async (): Promise<ShippingAddress[]> => {
  const { data } = await http.get(`/api/users/current/addresses`);
  return data;
};

export const deleteAddress = async (id) => {
  const { data } = await http.delete(`/api/users/current/addresses/${id}`);
  return data;
};

export const addNewCreditCard = async (card, recaptchaToken) => {
  const { data } = await http.post(`${cardPath}?recaptcha_v3_token=${recaptchaToken}`, card);
  return data;
};

export const updateCreditCard = async (card_id, updateData) => {
  const { data } = await http.put(`${cardPath}/${card_id}`, updateData);
  return data;
};

export const updateIdentityImages = async (payload) => {
  const { data } = await http.post("/api/profile/upload_identity_images", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
  return data;
};

// Manage user newsletter preferences
export const subscribeEmailSubscription = async (email, type, context?) => {
  try {
    const { data } = await http.post(`/api/email_subscriptions`, { email, type, context });
    // nextLogger.info(data);
    return data;
  } catch (error: any) {
    throw error.response;
  }
};

export const unSubscribeEmailSubscription = async (id) => {
  try {
    const { data } = await http.delete(`/api/email_subscriptions/${id}`);
    return data;
  } catch (error: any) {
    throw error.response;
  }
};

export const fetchSubscription = async (token) => {
  try {
    const { data } = await http.get(`/api/email_subscriptions?token=${token}`);
    return data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getProductDetails = async (id) => {
  const { data } = await http.get(`/api/products/${id}`);
  return data;
};

export const getVariantDetails = async (id): Promise<ProductVariant> => {
  const { data } = await http.get(`/api/product_variants/${id}`);
  return data;
};

export const getRecentOrderItems = async (count: number): Promise<ProductVariant[]> => {
  const { data } = await http.get(`/api/profile/recent_order_items?count=${count}`);
  return data;
};

export const applyCompassionateCare = async (values, recaptchaToken) => {
  const { data } = await http.post("/api/marketing/tickets", { ...values, type: "compassionate_care" });
  return data;
};

export const applyPartner = async (values, recaptchaToken) => {
  const { data } = await http.post(`/api/marketing/tickets?recaptcha_v3_token=${recaptchaToken}`, { ...values, type: "partner" });
  return data;
};

export const applyAffiliateProgram = async (values, recaptchaToken) => {
  const { data } = await http.post("/api/marketing/tickets", { ...values, type: "affiliate_program" });
  return data;
};

export const applyClinicalTrialsResearch = async (values, recaptchaToken) => {
  const { data } = await http.post("/api/marketing/tickets", { ...values, type: "clinical_trials_research" });
  return data;
};

export const applyClinicalTrial = async (values, recaptchaToken) => {
  const { data } = await http.post(`/api/marketing/tickets?recaptcha_v3_token=${recaptchaToken}`, { ...values, type: "covid19_clinical_trial" });
  return data;
};

export const applyArthritisClinicalTrial = async (values, recaptchaToken) => {
  const { data } = await http.post(`/api/marketing/tickets?recaptcha_v3_token=${recaptchaToken}`, { ...values, type: "arthritis_clinical_trial" });
  return data;
};

export const applyCBDHelp = async (values, recaptchaToken) => {
  const { data } = await http.post(`/api/marketing/tickets?recaptcha_v3_token=${recaptchaToken}`, { ...values, type: "cbd_help" });
  return data;
};

export const applyDiscovery = async (values, recaptchaToken) => {
  const { data } = await http.post(`/api/marketing/tickets?recaptcha_v3_token=${recaptchaToken}`, { ...values, type: "discovery" });
  return data;
};

export const applyUnitedProgram = async (values, recaptchaToken) => {
  const { data } = await http.post(`/api/marketing/tickets?recaptcha_v3_token=${recaptchaToken}`, { ...values, type: "united_program" });
  return data;
};

export const applyTransfer = async (values, recaptchaToken) => {
  const { data } = await http.post(`/api/marketing/tickets?recaptcha_v3_token=${recaptchaToken}`, { ...values, type: "request_transfer" });
  return data;
};

export const createContactUsTicket = async (values, recaptchaToken) => {
  const { data } = await http.post(`/api/marketing/tickets?recaptcha_v3_token=${recaptchaToken}`, { ...values, type: "contact_us" });
  return data;
};

export const createOmTicket = async (values) => {
  const { data } = await http.post("/api/marketing/tickets", { ...values, type: "om_assessment" });
  return data;
};

export const createOnlineRegWithDocsTicket = async (values) => {
  const { data } = await http.post("/api/marketing/tickets", { ...values, type: "online_reg_with_valid_med_doc" });
  return data;
};

export const createOnlineRegWithRegistrationCertificateTicket = async (values) => {
  const { data } = await http.post("/api/marketing/tickets", { ...values, type: "online_reg_with_registration_certificate" });
  return data;
};

export const getTopRecommendedProducts = async (ids: string[] = []): Promise<ProductSearchResult[]> => {
  const { data: recommendationQuery } = await http.post(
    "/api/search/products/_search",
    {
      "query": {
        "bool": {
          "must": [
            {
              "terms": {
                "_id": ids
              }
            }
          ]
        }
      }
    }
  );
  const recommendation = getResult(recommendationQuery).data as ProductSearchResult[];
  return recommendation;
};

export const getPartners = async () => {
  if (config.isCountry("US")) {
    const { data } = await http.get("/api/producers");
    return data;
  } else {
    const { data } = await http.get("/api/producers?per=1000&sort=-approval_date&q=approval_date > time(1960-01-01)");
    return data;
  }
};

export const getArticles = async (): Promise<WpArticle[]> => {
  const { data } = await Axios.get(`${config.cannalogueColumn}/wp-json/wp/v2/posts?_embed&categories=2`);
  return data.filter((article) => article.link.includes("/column/column/")).slice(0, 5);
};

export const retrieveGeocode = async (coordinates: [number, number]): Promise<AddressObject> => {
  const { data } = await http.post(`/api/address/retrieve_geocode`, { coordinates });
  return data;
}

export const getElasticSearchProducts = async (baseQuery = {}, sortQuery = {}): Promise<ProductSearchResult[]> => {
  const query = buildFilterQueries({}, {}, null, baseQuery);

  let endpoint = "/api/search/products/_search";
  const address = getStore().getState().address;
  if (!isServer && !_.isEmpty(address)) {
    // patch endpoint
    if (address?.coordinates) {
      const coordinates = address?.coordinates;
      endpoint = `${endpoint}?coordinates=${coordinates[0]},${coordinates[1]}`;
    }
    // patch query
    if (address?.province && Array.isArray(query)) {
      query.push(provinceQuery(address.province));
    }
  }

  const { data } = await http.post(
    endpoint,
    buildResultQuery(query, sortQuery, 0, 100)
  );
  return getResult(data).data as ProductSearchResult[];
};
