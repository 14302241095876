import { color } from "@resources/styles";
import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import StyledOutlinedInput, { StyledOutlinedInputProps } from "./StyledOutlinedInput";

interface GeneralTextFieldProps extends StyledOutlinedInputProps {
  inputStyle?: any;
  type: string;
  startAdornment?: any;
  endAdornment?: any;
  value?: any;
  defaultValue?: any;
  name: string;
  onChange: any;
  min?: number;
  max?: number;
  paddingTopBottom?: number;
  paddingLeftRight?: number;
}

// { input, placeholder, label, meta: { touched, error }, children, ...custom }
const GeneralTextField: React.FC<GeneralTextFieldProps> = ({
  type,
  inputStyle,
  startAdornment,
  error,
  endAdornment,
  value,
  defaultValue,
  name,
  onChange,
  min = 0,
  max = 100,
  ...others
}) => {
  const [displayValue, setDisplayValue] = useState(defaultValue ? defaultValue : value);

  const handleTelChange = (event) => {
    const {
      target: { value: v, nane: n },
    } = event;
    const onlyNum = v.replace(/[^\d]/g, "");
    let format = onlyNum;
    if (onlyNum.length > 3 && onlyNum.length < 7) {
      format = `${onlyNum.slice(0, 3)}-${onlyNum.slice(3)}`;
    }
    if (onlyNum.length >= 7) {
      format = `${onlyNum.slice(0, 3)}-${onlyNum.slice(3, 6)}-${onlyNum.slice(6, 10)}`;
    }
    setDisplayValue(format);
    onChange(format, n);
  };

  const handleChange = (event) => {
    const {
      target: { value: v, nane: n },
    } = event;
    if (!defaultValue) {
      setDisplayValue(v);
    }
    onChange(v, n);
  };

  useEffect(() => {
    if (value === "") {
      setDisplayValue("");
    }
  }, [value]);

  return (
    <StyledOutlinedInput
      inputProps={{
        style: inputStyle,
        min,
        max,
      }}
      style={{ backgroundColor: color.realWhite }}
      name={name}
      type={type}
      error={error}
      defaultValue={defaultValue && defaultValue}
      value={type === "tel" && !value ? displayValue : value}
      startAdornment={startAdornment && startAdornment}
      endAdornment={endAdornment && endAdornment}
      onChange={type === "tel" ? handleTelChange : handleChange}
      fullWidth
      {...others}
    />
  );
};

export default GeneralTextField;
