import { CustomTooltip, Row, StyledParagraph } from "@components/general";
import { StyledInfoIcon } from "@components/general/StyledIcons";
import useFormField from "@hooks/useFormField";
import { Collapse, FormHelperText, Grid } from "@material-ui/core";
import { color } from "@resources/styles";
import { getFormikFirstErrorKey } from "@utils/utils";
import React, { useEffect, useRef } from "react";
import StyledOutlinedInput from "./StyledOutlinedInput";

const FormikTextField: React.FC<any> = ({ label, tooltip, placeholder, name, disabled, children, optional, ...custom }) => {
  const { field, submitCount, isSubmitting, hasError, error, touched, errors } = useFormField({ name });
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hasError && submitCount > 0 && getFormikFirstErrorKey(errors) === name) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  return (
    <>
      {label &&
        <Row ref={ref}>
          <StyledParagraph
            style={{ fontWeight: 600 }}
            lineHeight={20}
            size={17}
            color={color.black}
            textEllipsis={true}
            component="label"
            variant="body1"
            htmlFor={name}
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {tooltip && (
            <>
              &nbsp;
              <CustomTooltip tip={tooltip}>
                <StyledInfoIcon />
              </CustomTooltip>
            </>
          )}
          {optional && (
            <StyledParagraph
              style={{ marginLeft: 5 }}
              align="left"
              guideline="p4"
              color={color.black}
              textEllipsis={true}
              variant="caption"
              dangerouslySetInnerHTML={{ __html: optional }}
            />
          )}
        </Row>
      }
      <StyledOutlinedInput
        error={hasError}
        placeholder={placeholder}
        disabled={isSubmitting || disabled}
        {...field}
        {...custom}
        autoComplete={name}
        fullWidth
      >
        {children}
      </StyledOutlinedInput>
      <Collapse in={hasError}>
        <Grid item style={{ display: "block" }} className="invalid-feedback">
          <FormHelperText error={hasError}>{error}</FormHelperText>
        </Grid>
      </Collapse>
    </>
  );
};

export default FormikTextField;
