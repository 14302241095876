import { Grid } from "@material-ui/core";
import { MessageDataProps, removeTopErrorMessages, removeTopMessage } from "@reducers/messages";
import { StoreProps } from "@reducers/store";
import color from "@resources/styles/color";
import useWindow from "@utils/useWindow";
import { isTablet } from "@utils/utils";
import _ from "lodash";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton, Row } from ".";
import StyledParagraph from "./StyledParagraph";
import WidthContext from "./WidthContext";

const TopMessage = ({ children }) => {
  const [highPriorityMessages, setHighPriorityMessages] = useState<MessageDataProps[]>([]);
  const messageOnTop: MessageDataProps[] = useSelector((state: StoreProps) => state.messages);
  const width = useContext(WidthContext);
  const router = useRouter();

  useEffect(() => {
    if (!_.isEmpty(messageOnTop)) {
      const currentRoute = router.pathname;
      const msgs = _.filter(messageOnTop, (m) => _.isEmpty(m.options?.exceptRoutes?.filter((er) => new RegExp(er).test(currentRoute))));
      if (!_.isEmpty(msgs)) {
        const sortedMessages = _.orderBy(msgs, ["options.priority", "createdAt"], ["desc", "desc"]);
        setHighPriorityMessages(sortedMessages.slice(0, 3));
      }
      useWindow.scrollTo(0, 0);
    }
  }, [messageOnTop]);

  const handleClearMessage = () => {
    if (!_.isEmpty(highPriorityMessages)) {
      setHighPriorityMessages([]);
      removeTopErrorMessages();
    }
  };
  router?.events?.on("routeChangeComplete", handleClearMessage);

  return (
    <div>
      {!_.isEmpty(messageOnTop) &&
        highPriorityMessages.map((highPriorityMessage) => (
          <Row
            key={highPriorityMessage.id}
            justify="center"
            alignItems="center"
            style={{ backgroundColor: highPriorityMessage.type === "error" ? color.error : color.blue }}
          >
            <Grid item xs={10}>
              <StyledParagraph guideline="p3" align="center" dangerouslySetInnerHTML={{ __html: highPriorityMessage.message }} />
            </Grid>
            {isTablet(width) ? (
              <Row justify="center">
                <Grid item xs={3}>
                  <PrimaryButton style={{ padding: 2 }} secondary onClick={() => removeTopMessage(highPriorityMessage.id)} fullWidth>
                    Close
                  </PrimaryButton>
                </Grid>
              </Row>
            ) : (
              <div style={{ position: "absolute", right: 20 }}>
                <StyledParagraph hoverAction guideline="p3" onClick={() => removeTopMessage(highPriorityMessage.id)}>
                  X
                </StyledParagraph>
              </div>
            )}
          </Row>
        ))}
      {children}
    </div>
  );
};

export default TopMessage;
