import _ from "lodash";
import { ESTermsAggsResult, RangeOption, SearchResult } from "./types";

export const hasValue = (value, type) => {
  if (type === "term") {
    if (_.isObject(value) || _.isArrayLike(value)) {
      return !_.isEmpty(value);
    } else {
      return value !== null;
    }
  } else {
    return !_.isEmpty(value);
  }
};

const idNestedKey = (key: string) => {
  return key.includes("/");
};

const nestedFields = (key: string) => {
  const [nested, field] = key.split("/");
  if (field.endsWith("[number]")) {
    return [nested, field.replace("[number]", ""), "number"];
  }
  return [nested, field];
};

export type queryType = "term" | "terms" | "range" | "search";

export const buildNestedTermsAggregation = (nestedField: string, field: string, size: number = 5) => {
  return {
    aggs: {
      filtered_results: {
        nested: {
          path: nestedField
        },
        aggs: {
          inner: {
            filter: {
              bool: {
                must: [{ match: { [`${nestedField}.key`]: field } }]
              }
            },
            aggs: {
              [`${field}_terms`]: {
                terms: {
                  field: `${nestedField}.value.raw`,
                  size
                }
              }
            }
          }
        }
      }
    }
  };
};

export const buildTermsAggregation = (field: string, size: number = 5) => {
  if (idNestedKey(field)) {
    const [nestedPath, fieldName] = nestedFields(field);
    return buildNestedTermsAggregation(nestedPath, fieldName, size);
  }

  return {
    aggs: {
      [`${field}_terms`]: {
        terms: { field, size }
      }
    }
  };
};

export const buildNestedMaxMinAggregation = (nestedField: string, field: string) => {
  return {
    aggs: {
      filtered_results: {
        nested: {
          path: nestedField
        },
        aggs: {
          inner: {
            filter: {
              bool: {
                must: [{ match: { [`${nestedField}.key`]: field } }]
              }
            },
            aggs: {
              [`${field}_min`]: {
                min: {
                  field: `${nestedField}.number_value`
                }
              },
              [`${field}_max`]: {
                max: {
                  field: `${nestedField}.number_value`
                }
              }
            }
          }
        }
      }
    }
  };
};

export const buildMaxMinAggregation = (field: string) => {
  if (idNestedKey(field)) {
    const [nestedPath, fieldName] = nestedFields(field);
    return buildNestedMaxMinAggregation(nestedPath, fieldName);
  }

  return {
    aggs: {
      [`${field}_min`]: {
        min: {
          field
        }
      },
      [`${field}_max`]: {
        max: {
          field
        }
      }
    }
  };
};

export const buildSearchQuery = (mapDataFieldWithWeight: string[], value: string) => {
  return {
    bool: {
      should: [
        {
          multi_match: {
            query: value,
            fields: [
              ...mapDataFieldWithWeight
            ],
            type: "best_fields",
            operator: "or",
            fuzziness: 0
          }
        },
        {
          multi_match: {
            query: value,
            fields: [
              ...mapDataFieldWithWeight
            ],
            type: "phrase_prefix",
            operator: "or"
          }
        }
      ]
    }
  };
};

export const buildNestedTermsQuery = (nestedField: string, field: string, values: string[]) => {
  return {
    nested: {
      path: nestedField,
      query: {
        bool: {
          must: [
            { match: { [`${nestedField}.key`]: field } },
            { terms: { [`${nestedField}.value.raw`]: [...values] } },
          ]
        }
      },
    }
  };
};

export const buildNestedTermQuery = (nestedField: string, field: string, value: boolean | string | number) => {
  return {
    nested: {
      path: nestedField,
      query: {
        bool: {
          must: [
            { match: { [`${nestedField}.key`]: field } },
            { term: { [`${nestedField}.value.raw`]: value } },
          ]
        }
      },
    }
  };
};

export const buildTermsQuery = (field: string, values: string[]) => {
  if (idNestedKey(field)) {
    const [nestedPath, fieldName] = nestedFields(field);
    return buildNestedTermsQuery(nestedPath, fieldName, values);
  }

  return {
    terms: {
      [field]: [...values]
    }
  };
};

export const buildTermQuery = (field: string, value: boolean | string | number) => {
  if (idNestedKey(field)) {
    const [nestedPath, fieldName] = nestedFields(field);
    return buildNestedTermQuery(nestedPath, fieldName, value);
  }

  return {
    term: {
      [field]: value,
    }
  };
};

export const buildNestedRangeQuery = (nestedField: string, field: string, values: { min: number, max: number }) => {
  return {
    nested: {
      path: nestedField,
      query: {
        bool: {
          must: [
            { match: { [`${nestedField}.key`]: field } },
            { range: { [`${nestedField}.number_value`]: { gte: values.min, lte: values.max } } }
          ]
        }
      },
      score_mode: "avg"
    }
  };
};

export const buildRangeQuery = (field: string, values: { min: number, max: number }) => {
  if (idNestedKey(field)) {
    const [nestedPath, fieldName] = nestedFields(field);
    return buildNestedRangeQuery(nestedPath, fieldName, values);
  }

  return {
    range: {
      [field]: { gte: values.min, lte: values.max }
    }
  };
};

// if it's empty array do nothing, but it's null includes everythings
export const buildFilterQueries = (filterConfigs, filterValues, usedFilters: string[] | null, baseQuery: any | null) => {
  let willUseFilters = usedFilters;
  if (!usedFilters) {
    willUseFilters = Object.keys(filterConfigs);
  }
  let queries: any[] = [];
  if (Array.isArray(baseQuery)) {
    queries = baseQuery;
  } else if (baseQuery) {
    queries = [baseQuery]
  }

  if (willUseFilters) {
    willUseFilters.forEach((filterName) => {
      const filterValue = _.get(filterValues, `${filterName}.value`, {});
      const dataField = _.get(filterConfigs, `${filterName}.dataField`, null);
      const buildCustomQuery = _.get(filterConfigs, `${filterName}.buildCustomQuery`, null);
      const type: queryType = _.get(filterConfigs, `${filterName}.type`, "terms");

      if (hasValue(filterValue, type) && !_.isEmpty(dataField)) {
        if (buildCustomQuery) {
          queries.push(buildCustomQuery(dataField, filterValue));
        } else if (type === "term") {
          if (filterValue) {
            queries.push(buildTermQuery(dataField, filterValue));
          }
        } else if (type === "terms") {
          if (Array.isArray(filterValue) && !filterValue.includes("select_all_values")) {
            queries.push(buildTermsQuery(dataField, filterValue));
          }
        } else if (type === "range") {
          queries.push(buildRangeQuery(dataField, filterValue));
        } else if (type === "search") {
          const mapDataFieldWithWeight = dataField.map((d, index) => {
            const fieldWeight = _.get(filterConfigs, `${filterName}.fieldWeights[${index}]`, null);
            if (fieldWeight) {
              return [d, fieldWeight].join("^");
            }
            return d;
          });
          queries.push(buildSearchQuery(mapDataFieldWithWeight, filterValue));
        }
      }
    });
  }

  if (_.isEmpty(queries)) {
    return { query: { match_all: {} } };
  }

  return { query: { bool: { must: queries } } };
};

export const buildSortQuery = (filterConfigs, filterValues) => {
  const sortFilter = filterValues.Sort || { value: _.get(filterConfigs, `Sort.defaultValue`, null) };
  if (sortFilter && sortFilter.value) {
    const sortValue = sortFilter.value;
    const direction = sortValue.charAt(0) === "-" ? "desc" : "asc";
    const value = sortValue.charAt(0) === "-" ? sortValue.substring(1) : sortValue;

    if (idNestedKey(value)) {
      const [nestedPath, fieldName, type] = nestedFields(value);
      return {
        sort: [
          {
            [`${nestedPath}.${type === "number" ? "number_value" : "value.raw"}`]: {
              mode: "max",
              order: direction,
              nested_path: nestedPath,
              nested_filter: { term: { [`${nestedPath}.key`]: fieldName } }
            }
          }
        ]
      };
    } else {
      return {
        sort: [
          { [value]: { order: direction } }
        ]
      };
    }
  }
  return {};
};

export const buildAggsQuery = (matchQueries, aggs) => {
  return { ...matchQueries, ...aggs, size: 0 };
};

export const buildResultQuery = (matchQueries, sortQuery = {}, from = 0, size = 10) => {
  return { ...matchQueries, ...sortQuery, from, size };
};

export const getTermsAggs = (field: string, data: any = {}): ESTermsAggsResult[] => {
  if (idNestedKey(field)) {
    const [nestedPath, fieldName] = nestedFields(field);
    return data.aggregations.filtered_results.inner[`${fieldName}_terms`].buckets;
  }

  return data.aggregations[`${field}_terms`].buckets;
};

export const getRangeAggs = (field: string, data: any = {}): RangeOption => {
  if (idNestedKey(field)) {
    const [nestedPath, fieldName] = nestedFields(field);
    const _min = data.aggregations.filtered_results.inner[`${fieldName}_min`].value;
    const _max = data.aggregations.filtered_results.inner[`${fieldName}_max`].value;
    return { min: _min, max: _max };
  }

  const min = data.aggregations[`${field}_min`].value;
  const max = data.aggregations[`${field}_max`].value;
  return { min, max };
};

export const getResult = <T extends any>(data: any = {}): SearchResult<T> => {
  return { data: data.hits.hits.map((p) => ({ id: p._id, ...p._source, ...p.fields, discount: p.discount })), total: data.hits.total };
};

export const provinceQuery = (province) => ({
  bool: {
    should: [
      {
        match: {
          "available_provinces.raw": province
        }
      },
      {
        bool: {
          must_not: [
            {
              exists: {
                field: "available_provinces.raw"
              }
            }
          ]
        }
      },
    ],
  }
});
