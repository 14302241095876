import Footer from "@components/footer/Footer";
import { CircularProgress } from "@material-ui/core";
import { color } from "@resources/styles";
import { trackPageView } from "@utils/gtagHelpers";
import redirect from "@utils/redirect";
import { useLogout } from "@utils/services";
import { detectIE, getUserAgent } from "@utils/userAgent";
import _ from "lodash";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { Spinner } from ".";
import MobileHeader from "../navigation/MobileHeader";
import WideScreenHeader from "../navigation/WideScreenHeader";
import AlertWrapper from "./AlertWrapper";
import DelegateWarning from "./DelegateWarning";
import SEO, { SEOoptionsProps } from "./SEO";
import { useSmartSpinner } from "./SmartSpinner";
import TopMessage from "./TopMessage";
import WidthContext from "./WidthContext";

const redirectInIE = (pathname = "") => {
  // Disable IE
  if (detectIE(getUserAgent(null)) && !pathname.includes("/not-supported")) {
    redirect({ ctx: null, location: "/not-supported" });
  }
};

export interface PageProps {
  showSpinnerOnActions?: string[];
  extraFooterComponent?: React.FC;
  showHeader?: boolean;
  showFooter?: boolean;
  showRecaptcha?: boolean;
  SEOoptions?: SEOoptionsProps;
  headerComponent?: React.FC;
  children?: React.ReactNode;

  /**
   * due to nested children is not working on nextjs dynamic, 'isLoading' is required to display circular progress.
   */
  isLoading?: boolean;
}

const DefaultHeader: React.FC = () => {
  const width = useContext(WidthContext);
  if (width === "sm" || width === "xs") {
    // use Wrapper div to take mobile menu position fixed space
    return <MobileHeader />;
  } else {
    return <WideScreenHeader />;
  }
};

const Page: React.FC<PageProps & React.HTMLAttributes<any>> = ({
  SEOoptions,
  showSpinnerOnActions = [],
  extraFooterComponent,
  showHeader = true,
  showFooter = true,
  headerComponent: CustomHeader,
  children,
  isLoading,
  showRecaptcha = false,
  ...props
}) => {
  const router = useRouter();
  const [networkLoading] = useSmartSpinner(showSpinnerOnActions);
  const [loggingOut, __] = useLogout();
  const ref = useRef(null);
  const [pageLoading, setPageLoading] = useState(false);

  const onLoad = () => {
    setPageLoading(true);
    // @ts-ignore
    ref?.current?.continuousStart();
  };

  const onDone = (url) => {
    trackPageView(url);
    setPageLoading(false);
    // @ts-ignore
    ref?.current?.complete();
  };

  const routerEventMount = () => {
    router.events.on("routeChangeStart", onLoad);
    router.events.on("routeChangeComplete", onDone);
    router.events.on("routeChangeError", onDone);
  };

  const routerEventUnmount = () => {
    router.events.off("routeChangeStart", onLoad);
    router.events.off("routeChangeComplete", onDone);
    router.events.off("routeChangeError", onDone);
  };

  useEffect(() => {
    redirectInIE(window?.location?.pathname);
    routerEventMount();
    return () => {
      setPageLoading(false);
      routerEventUnmount();
    };
  }, []);

  // due to nested children is not working on nextjs dynamic, 'isLoading' is required to display circular progress.
  const loadingDisplay = () => {
    return (
      <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </div>
    );
  };

  return (
    <>
      <LoadingBar color={color.blue} height={4} shadow={true} ref={ref} />
      <Head>
        {showRecaptcha && (
          <style global jsx>{`
            .grecaptcha-badge {
              visibility: visible !important;
            }
          `}</style>
        )}
      </Head>
      <Spinner display={loggingOut || (!pageLoading && networkLoading)} fullSize>
        <TopMessage>
          <AlertWrapper>
            <SEO options={SEOoptions} />
            {showHeader && <header>{CustomHeader ? <CustomHeader /> : <DefaultHeader />}</header>}
            <main style={{ position: "relative" }}>{children}</main>
            {!(isLoading || pageLoading) && showFooter && <Footer extraFooterComponent={extraFooterComponent} />}
          </AlertWrapper>
        </TopMessage>
      </Spinner>
      <DelegateWarning />
    </>
  );
};
export default React.memo(Page, _.isEqual);
