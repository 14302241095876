import { Button } from "@material-ui/core";
import { color } from "@resources/styles";
import Link from "next/link";
import styled, { css } from "styled-components";
import StyledParagraph, { StyledParagraphProps } from "./StyledParagraph";

// interface LinkButtonProps extends React.HTMLAttributes<any> {
//   className?: string;
//   to?: string;
//   asPath?: string;
//   paragraphProps?: StyledParagraphProps;
//   varients?: "link" | "button";
// }

const LinkButtonCss = css`
  color: ${color.black};
  padding: 23.5px 5px !important;
  border-radius: unset;
  &:hover {
    background-color: unset;
  }
  transition: border 0ms;
  text-transform: none;
`;

const ButtonCss = css`
  color: ${color.black};
  padding: 0;
  border-radius: unset;
  text-transform: none;
  &:hover {
    background-color: unset;
  }
  transition: border 0ms;
`;

const LinkTypography = styled(StyledParagraph) <any>`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${color.black};
  font-size: 15px;
  justify-content: center;
`;

const Comp = ({ children, style, varients, to, asPath, className, onMouseOver, onTouchStart, paragraphProps, disabled = false, ...props }) => {
  const P = varients === "link" ? LinkTypography : StyledParagraph;
  return (
    <>
      {to ? (
        disabled ? (
          <P
            variant="body2"
            onClick={onTouchStart || onMouseOver || undefined}
            onMouseOver={onMouseOver}
            onTouchStart={onTouchStart}
            {...paragraphProps}
            {...props}
            style={{ lineHeight: 1, ...style }}
            component="span"
          >
            {children}
          </P>
        ) : to.match(/^http/g) ? (
          <a className="inherit" style={style} href={to} target="_blank">
            {children}
          </a>
        ) : (
          <Link href={to} as={asPath}>
            <a className="inherit">
              <P
                variant="body2"
                onClick={onTouchStart || onMouseOver || undefined}
                onMouseOver={onMouseOver}
                onTouchStart={onTouchStart}
                style={style}
                {...paragraphProps}
                {...props}
                component="span"
              >
                {children}
              </P>
            </a>
          </Link>
        )
      ) : (
        <Button
          disabled={disabled}
          className={className}
          onClick={onTouchStart || onMouseOver || undefined}
          onMouseOver={onMouseOver}
          onTouchStart={onTouchStart}
          {...props}
        >
          <P style={{ lineHeight: 1, ...style }} color={color.black} variant={varients === "link" ? "body2" : undefined} {...paragraphProps}>
            {children}
          </P>
        </Button>
      )}
    </>
  );
};
const StyledComponent = styled(Comp) <any>`
  ${(props) => (props.varients === "link" ? LinkButtonCss : ButtonCss)}
`;

const LinkButton: React.FC<any> = ({ children, varients = "link", ...props }) => {
  return (
    <StyledComponent varients={varients} {...props}>
      {children}
    </StyledComponent>
  );
};

export default LinkButton;
