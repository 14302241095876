import OutlinedInput, { OutlinedInputProps } from "@material-ui/core/OutlinedInput";
import React from "react";
import styled from "styled-components";

export interface StyledOutlinedInputProps extends OutlinedInputProps {
  paddingTopBottom?: number;
  paddingLeftRight?: number;
  divRef?: any;
  children?: React.ReactNode;
}

const Styled = styled(({ paddingTopBottom = 15, paddingLeftRight = 15, children, className, ...props }) => (
  <OutlinedInput {...props} classes={{ input: className }}>
    {children && children}
  </OutlinedInput>
))`
  padding: ${(props) => props.paddingTopBottom - 0.5}px ${(props) => props.paddingLeftRight}px !important;
`;

export default Styled;

// const StyledOutlinedInput: React.FC<StyledOutlinedInputProps> = ({
//   children,
//   divRef,
//   paddingTopBottom = 15,
//   paddingLeftRight = 15,
//   label,
//   ...props
// }) => {
//   return (
//     <Styled ref={divRef && divRef} paddingTopBottom={paddingTopBottom} paddingLeftRight={paddingLeftRight} {...props}>
//       {children && children}
//     </Styled>
//   );
// };

// export default StyledOutlinedInput;
