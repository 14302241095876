import config from "@config/index";
import configCA from "./config-CA";
import configUS from "./config-US";
import configDrup from "./config-drup";

const defaultConfig = () => {
  if (config.drup) {
    return configDrup;
  } else if (config.country === "CA") {
    return configCA;
  } else if (config.country === "US") {
    return configUS;
  } else {
    return configCA;
  }
};

export default defaultConfig();
