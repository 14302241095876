import GeneralAutoSuggestField from "@components/form/GeneralAutoSuggestField";
import { Col, Row, StyledParagraph } from "@components/general";
import config from "@config/index";
import { useFetchData } from "@hooks/useServiceCall";
import { updateAddressAction } from "@reducers/address";
import { StoreProps } from "@reducers/store";
import { AddressObject } from "@reducers/types";
import IconCurrentLocation from "@resources/images/IconCurrentLocation.svg";
import IconLocation from "@resources/images/IconLocation.svg";
import { color } from "@resources/styles";
import axios from "@utils/axios";
import { useCurrentAddress, useLocation } from "@utils/hooks";
import { useTranslation } from "@utils/i18n";
import isServer from "@utils/isServer";
import { getAddresses } from "@utils/services";
import { addressToString } from "@utils/utils";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const AddressCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 14px;
  padding-top: 6px;
  cursor: pointer;
  &:hover {
    background: ${color.greyOpacity(0.2)}
  }
`;

const AddressComponent = ({ address, onSelect }) => {
  return (
    <AddressCard onClick={() => onSelect(address)}>
      <div><IconLocation style={{ width: 18, height: 18 }} /></div>
      <div style={{
        flexGrow: 1,
        padding: 10,
        borderBottom: `1px solid ${color.grayLighter}`,
        textAlign: "left",
        fontSize: 18,
        color: color.black
      }}>
        {addressToString(address)}
      </div>
    </AddressCard>
  )
}

interface AddressSuggestModalProps {
  closeModal: () => void;
}

const AddressSuggestModal: React.FC<AddressSuggestModalProps> = ({ closeModal }) => {
  const { t } = useTranslation("components/address-modal");
  const dispatch = useDispatch();
  const { currentAddress, getCurrentAddress } = useCurrentAddress();
  const address = useSelector((store: StoreProps) => store.address);
  const [_localStoredAddress, setLocalStoredAddress] = useLocation();
  const [value, setValue] = useState(addressToString(address));
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false);
  const { response: userAddresses } = useFetchData(getAddresses);

  useEffect(() => {
    if (loadingCurrentLocation && !_.isEmpty(currentAddress)) {
      setAddress(currentAddress);
      setValue(addressToString(currentAddress));
      setLoadingCurrentLocation(false);
    }
  }, [currentAddress]);

  const getSuggestion = async (v: string) => {
    const { data } = await axios.get(`/api/googlemaps/prediction?keyword=${v}&country=${config.country || "CA"}`);
    return data.result;
  };

  const setAddress = async (v) => {
    if (v?.province && !isServer) {
      localStorage.setItem("province", v.province);
    }
    setLocalStoredAddress(v);
    await dispatch(updateAddressAction(v));
    closeModal();
  };

  const onGetLocationError = () => {
    window.alert(t("location.warn"));
    setLoadingCurrentLocation(false);
  }

  const handleUseCurrentLocation = () => {
    setLoadingCurrentLocation(true);
    getCurrentAddress(onGetLocationError);
  }

  const onSelect = async (selected) => {
    if (selected) {
      const { data } = await axios.get(`/api/googlemaps/detail?place_id=${selected.place_id}`);
      const detail = data?.result?.address_components || [];
      const geo = data?.result?.geometry?.location;

      const newValues: AddressObject = {
        coordinates: [geo.lat, geo.lng],
        street: selected.street,
        city: detail.filter((e) => e.types.includes("locality"))[0]?.short_name || "",
        province: detail.filter((e) => e.types.includes("administrative_area_level_1"))[0]?.short_name || "",
        postal: detail.filter((e) => e.types.includes("postal_code"))[0]?.short_name || "",
        country: detail.filter((e) => e.types.includes("country"))[0]?.long_name || "",
      };

      setAddress(newValues);
    }
  };

  return (
    <Row justify="center" alignItems="flex-start" spacing={2}>
      <Col xs={12} md={11}>
        <StyledParagraph style={{ padding: 15 }} guideline="h2" color={color.black} align="center">
          {t("title")}
        </StyledParagraph>
        <StyledParagraph guideline="p3" color={color.black} align="center">
          {t("description")}
          {!_.isEmpty(userAddresses) && <> {t("subtitle")}</>}
        </StyledParagraph>

        <GeneralAutoSuggestField
          style={{ marginTop: 12, marginBottom: 12 }}
          getSuggestionValue={(suggestion) => suggestion?.description}
          placeholder={t("search.placeholder")}
          startAdornment={<IconLocation style={{ width: 21, height: 21, marginRight: 8 }} />}
          endAdornment={<IconCurrentLocation style={{ cursor: "pointer" }} onClick={() => {
            handleUseCurrentLocation();
          }} />}
          loading={loadingCurrentLocation}
          value={value}
          onChange={(e, v) => setValue(v)}
          onSelect={onSelect}
          getSuggestion={getSuggestion}
        />

        {
          !_.isEmpty(userAddresses) && userAddresses?.map(addr => (
            <AddressComponent key={addr.id} onSelect={() => {
              setAddress(addr);
            }} address={addr} />
          ))
        }
      </Col>
    </Row>
  );
};

export default AddressSuggestModal;
