import { Row, StyledParagraph } from "@components/general";
import { color } from "@resources/styles";
import { registerSearchFilter } from "@utils/elasticsearch/config";
import { getCurrentESName, useRangeSelect } from "@utils/elasticsearch/hooks";
import { RangeFilterComponent } from "@utils/elasticsearch/types";
import _ from "lodash";
import React from "react";
import RangeSliderView from "./RangeSliderView";

interface Props {
  name: string;
  textBox: {
    textAlign: "left" | "right";
    startAdornment?: string;
    endAdornment?: string;
  };
}

let RangeSliderFilter: RangeFilterComponent<Props> = ({ id, name, textBox, ...config }) => {
  const { value = [], hide, options, updateValue } = useRangeSelect(getCurrentESName(), { id, ...config });

  if (hide) {
    return null;
  }

  let sliderRange = config.dynamic ? options : config.range;

  if (_.isEmpty(sliderRange)) {
    return null;
  }

  return (
    <Row>
      <Row>
        <StyledParagraph align="left" color={color.black} size={17} lineHeight={20} variant="body2">
          {name}
        </StyledParagraph>
      </Row>
      <RangeSliderView options={sliderRange} value={value} onChange={updateValue} textBox={textBox} />
    </Row>
  );
};

RangeSliderFilter = React.memo(RangeSliderFilter, _.isEqual);

RangeSliderFilter.manullyResigsterFilter = (esName, { id, ...config }) => {
  registerSearchFilter(esName, id, { ...config, type: "range" });
};

export default RangeSliderFilter;
