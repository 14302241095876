import { PrimaryButton } from "@components/general";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
import { color } from "@resources/styles";
import { useTranslation } from "@utils/i18n";
import _ from "lodash";
import React from "react";

export interface GeneralModalProps {
  open: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  title?: string;
  description?: string;
  type?: "update" | "delete" | "save" | "confirm";
  buttonText?: string;
  submitting?: boolean
}

const GeneralModal: React.FC<GeneralModalProps> = ({
  open,
  title,
  onSubmit,
  type = "update",
  onCancel,
  description,
  buttonText,
  submitting = false,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px 24px" }}>
        <PrimaryButton loading={submitting} onClick={onSubmit} style={
          type === "delete" ? { backgroundColor: color.red, color: color.white, border: "none" } : {}}
          autoFocus fullWidth>
          {buttonText || t(`common:common.button.${type}`)}
        </PrimaryButton>
        <PrimaryButton loading={submitting} secondary onClick={onCancel} fullWidth>
          {t("common:common.button.cancel")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default GeneralModal;
