import config from "@config/index";
import CAFooter from "@config/ui/footer-CA";
import USFooter from "@config/ui/footer-US";
import DrupFooter from "@config/ui/footer-drup";
import { asynMenu as loadCAMenuAsync } from "@config/ui/navigation-CA";
import { asynMenu as loadDrupMenuAsync } from "@config/ui/navigation-drup";
import USNaviConfig from "@config/ui/navigation-US";
import searchConfig from "@config/ui/products-search/index";
import { updateConfigAction } from "@reducers/navigation";
import _ from "lodash";
import { getStore } from "./elasticsearch/config";
import { useMultiElasticSearch } from "./elasticsearch/hooks";

const mode = () => {
  if (config.drup) {
    return "drup";
  } else {
    return config.country;
  }
}

const NaviConfigs = {
  US: USNaviConfig,
  CA: loadCAMenuAsync,
  drup: loadDrupMenuAsync,
};

const FooterConfigs = {
  US: USFooter,
  CA: CAFooter,
  drup: DrupFooter
};

/**
 * Load app configurations
 */
export const loadAppConfigs = async () => {
  const store = getStore();
  const { getState, dispatch } = store;

  if (!getState().navigation.loaded) {
    let menus = NaviConfigs[mode()];
    if (_.isFunction(menus)) {
      menus = await menus();
    }
    await dispatch(updateConfigAction({
      header: menus,
      footer: FooterConfigs[mode()],
      loaded: true
    }
    ));
  }
};

/**
 * Load product search configurations
 */
export const loadSearchConfigs = async (esSearchName) => {
  // @ts-ignore
  return searchConfig.filter((conf) => {
    return conf.elasticsearch.name === esSearchName;
  })[0];
};

export const registerAllElasticSearch = async () => {
  useMultiElasticSearch(searchConfig.map((cfg) => cfg.elasticsearch));
};
