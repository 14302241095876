import * as ROUTE from "@resources/routeConst";
import _ from "lodash";

const config = {
  rootCategory: "Cannabis",
  collectionUrl: ROUTE.PRODUCTS,
  elasticsearch: {
    name: "cannabis",
    url: `/api/search/`,
    indices: ["products"],
    baseQuery: {
      bool: {
        should: [
          {
            prefix: {
              "categories.raw": "Cannabis"
            }
          }
        ]
      }
    },
    keywordSearch: true, // for display or not in keyword search
  },
  sortOptions: [
    { value: "-manual_weight", title: "sorter.sort_option.relevance" },
    { value: "price", title: "sorter.sort_option.price_low_high" },
    { value: "-price", title: "sorter.sort_option.price_high_low" },
    { value: "product_specs/thc[number]", title: "sorter.sort_option.thc_low_high" },
    { value: "-product_specs/thc[number]", title: "sorter.sort_option.thc_high_low" },
    { value: "product_specs/cbd[number]", title: "sorter.sort_option.cbd_low_high" },
    { value: "-product_specs/cbd[number]", title: "sorter.sort_option.cbd_high_low" },
    { value: "producer.name.raw", title: "sorter.sort_option.producer_az" },
    { value: "-producer.name.raw", title: "sorter.sort_option.producer_za" },
    { value: "brand.name.raw", title: "sorter.sort_option.brand_az" },
    { value: "-brand.name.raw", title: "sorter.sort_option.brand_za" },
    { value: "name.raw", title: "sorter.sort_option.strain_az" },
    { value: "-name.raw", title: "sorter.sort_option.strain_za" },
  ],
  filterOptions: [
    {
      component: `FreeShippingFilter`,
    },
    {
      component: `InStockFilter`,
    },
    {
      component: `CategoryFilter`,
      prefix: "Cannabis",
      react: ["Price", "Keyword", "Product Composition", "Brand", "Producer", "Type"]
    },
    {
      component: `MultiSelectFilter`,
      showCount: true,
      id: "Product Composition",
      name: "Product Composition",
      dataField: "product_specs/product_composition",
      react: ["Price", "Keyword", "Category", "Brand", "Producer", "Type"]
    },
    {
      component: `MultiSelectFilter`,
      name: "Type",
      id: "Type",
      showCount: true,
      dataField: "product_specs/cannabis_type",
      react: ["Price", "Keyword", "Category", "Brand", "Producer", "Product Composition"]
    },
    {
      component: `SearchableMultipleSelectFilter`,
      name: "Brand",
      id: "Brand",
      showCount: true,
      enableSelectAll: true,
      size: 100,
      dataField: "brand.name.raw",
      react: ["Price", "Keyword", "Category", "Type", "Producer", "Product Composition"]
    },
    {
      component: `SearchableMultipleSelectFilter`,
      name: "Producer",
      label: "search.filters.producer_label",
      id: "Producer",
      showCount: true,
      enableSelectAll: true,
      size: 100,
      dataField: "producer.name.raw",
      react: ["Price", "Keyword", "Category", "Type", "Brand", "Product Composition"]
    },
    {
      component: `RangeSliderFilter`,
      name: "Price",
      id: "Price",
      dynamic: true,
      dataField: "price",
      textBox: {
        textAlign: "left",
        startAdornment: "$",
      },
      react: ["Producer", "Keyword", "Category", "Type", "Brand", "Product Composition"]
    },
    {
      component: `TerpenesFilter`,
      showCount: true,
      name: "Terpenes",
    }
  ],
  resultComponent: {
    component: `SearchResultCard`,
    showSpecs: [
      { key: "cannabis_type", format: "${value}" },
      { key: "thc", format: "THC: ${value}" },
      { key: "cbd", format: "CBD: ${value}" },
      { key: "cbg", format: "CBG: ${value}" },
    ],
  }
};

export default config;
