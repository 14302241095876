import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import { color } from "@resources/styles";
import React from "react";
import styled from "styled-components";

interface StyledOutlinedFormControlProps extends FormControlProps {
  children: React.ReactNode;
}

const Styled = styled(FormControl)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  & div:focus {
    background-color: ${color.realWhite};
  }
`;

const StyledOutlinedFormControl: React.FC<StyledOutlinedFormControlProps> = ({ children, ...props }) => {
  return (
    <Styled variant="outlined" fullWidth {...props}>
      {children}
    </Styled>
  );
};

export default StyledOutlinedFormControl;
