import { GeneralCheckBoxField } from "@components/form";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import React from "react";

interface ItemOption {
  level: number;
  parent?: ItemOption;
  value: any;
  label: any;
  children?: ItemOption[];
}

interface Props {
  options?: ItemOption[];
  value?: string[];
  onChange: (values: any) => void;
}

const MultiSelectTreeView: React.FC<Props> = ({ options = [], value = [], onChange }) => {
  const handleChange = (opt: ItemOption, selected: boolean) => {
    const val: string = opt.value;
    let newValue = [...value];

    if (selected && value.includes(val)) {
      newValue = [...value];
    }

    if (selected && !value.includes(val)) {
      newValue = [...value, val];
    }

    if (!selected && value.includes(val)) {
      newValue = value.filter((e) => e !== val);
    }

    // deselect all child options, if parent is deselected
    if (!selected && value.includes(val)) {
      newValue = newValue.filter((e) => !_.startsWith(e, val));
    }

    // deselect parent option, if one of the child is seleced
    if (selected && !value.includes(val)) {
      newValue = newValue.filter((e) => e === val || !_.startsWith(val, e));
    }

    // deselect parent option, if one of the child is deseleced
    if (!selected && value.includes(val)) {
      newValue = newValue.filter((e) => e === val || !_.startsWith(val, e));
    }

    // // mark parent as selected, if all children all selected
    // if (selected && !value.includes(val)) {
    //   const allChildrenSelected = opt.parent?.children?.every((o) => newValue.includes(o.value)) as boolean;

    //   if (allChildrenSelected) {
    //     newValue = newValue.filter((e) => e !== opt.parent?.value);
    //     newValue.push(opt.parent?.value);
    //   }
    // }

    if (onChange) {
      onChange(_.compact(newValue));
    }
  };

  const isSelected = (val: string) => {
    return value.includes(val);
  };

  const isOptionPartOfValue = (opt: ItemOption) => {
    return value.some((val) => _.startsWith(opt.value, val));
  };

  const isOptionSiblingOfValue = (opt: ItemOption) => {
    const selectedValueParents = value.map((val) => {
      const parts = val.split("/");
      parts.pop();
      return parts.join("/");
    });

    return selectedValueParents.some((val) => opt.parent && opt.parent.value === val);
  };

  const shouldShowCurrentOption = (opt: ItemOption) => {
    return (
      opt.level === options[0].level || // show first level labels
      isOptionPartOfValue(opt) || // show selected label's children
      isOptionSiblingOfValue(opt) // show selected label's siblings
    );
  };

  const renderNodes = (opts, level) => {
    return opts.map((opt, idx) => {
      if (!shouldShowCurrentOption(opt)) {
        return null;
      }
      return (
        <Grid container key={opt.value}>
          <Grid item style={{ paddingLeft: level * 15 }}>
            <GeneralCheckBoxField
              name={opt.value}
              value={isSelected(opt.value)}
              onChange={(selected) => handleChange(opt, selected)}
              label={opt.label}
            />
          </Grid>
          {!_.isEmpty(opt.children) && renderNodes(opt.children, level + 1)}
        </Grid>
      );
    });
  };

  return <Grid container>{renderNodes(options, 0)}</Grid>;
};

export default React.memo(MultiSelectTreeView, _.isEqual);
