import { User } from "@reducers/types";
import _ from "lodash";
import Cookies from "universal-cookie";
import isServer from "./isServer";
import { serverSideRequire } from "./utils";

export const VISITOR_ID = "_visitor_id_";
export const CANNALOGUE_USER = "_cannalogue_user_";

let httpContext: any = null;
// load httpContext in sever mode
if (isServer) {
  httpContext = serverSideRequire("express-http-context");
}

export const setCookie = (name: string, value: string | null, options = {}) => {
  if (isServer) {
    // TODO:
  } else {
    const cookies = new Cookies(document.cookie);
    cookies.set(name, value, options);
  }
};

export const getCookie = (name: string) => {
  if (isServer && httpContext && httpContext.get("req.headers")?.cookie) {
    // TODO:
    const cookies = new Cookies(httpContext.get("req.headers")?.cookie);
    return cookies.get(name);
  } else if (!isServer) {
    const cookies = new Cookies(document.cookie);
    return cookies.get(name);
  } else {
    return undefined;
  }
};

export const saveUserInfoToCookie = (userInfo: User) => {
  const infoToSave = _.pick(userInfo, ["id", "first_name", "last_name", "groups"]);
  setCookie(CANNALOGUE_USER, JSON.stringify(infoToSave), { httpOnly: false, path: "/" });
};

export const clearUserInfoInCookie = () => {
  setCookie(CANNALOGUE_USER, JSON.stringify({}), { httpOnly: false, path: "/" });
};
