import Button from "@material-ui/core/Button";
import { useCurrentUser } from "@reducers/store";
import { logoutAction } from "@reducers/user";
import { logout } from "@utils/services";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { PrimaryButton } from ".";

const styles: React.CSSProperties = {
  backgroundColor: "#FF4136",
  height: "80px",
  width: "100%",
  position: "fixed",
  left: 0,
  bottom: 0,
  zIndex: 101,
  color: "white",
  fontSize: 20,
  fontWeight: 500,
  textAlign: "center",
  verticalAlign: "middle",
  lineHeight: "50px",
  padding: 15,
};

const DelegateWarning: React.FC<any> = (props) => {
  const user = useCurrentUser();
  const { delegator, exp: expire } = user;
  const dispatch = useDispatch();

  if (!delegator || !expire) {
    return null;
  }

  const handleSignout = () => {
    dispatch(logoutAction());
    logout();
  };

  const time = moment.unix(expire).format("MMMM Do YYYY, h:mm:ss a");
  return (
    <div style={styles}>
      You are in delegate mode! Your session will expire at {time}
      <PrimaryButton secondary onClick={handleSignout} style={{ marginLeft: 15, padding: "8px 12px" }}>
        Sign Out
      </PrimaryButton>
    </div>
  );
};

export default DelegateWarning;
