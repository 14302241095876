import { GeneralTextField } from "@components/form";
import { Row, StyledParagraph } from "@components/general";
import { Grid, Slider, withStyles } from "@material-ui/core";
import IconFilterHandler from "@resources/images/IconFilterHandler.svg";
import { color } from "@resources/styles";
import _ from "lodash";
import React from "react";
import styled from "styled-components";

interface TextBoxProps {
  textAlign: string;
  startAdornment?: any;
  endAdornment?: any;
}

interface RangeSliderViewProps {
  value?: { min: number; max: number };
  options?: { min: number; max: number };
  dynamic?: boolean;
  renderValueLabel?: (val: number) => any;
  onChange: (value: { min: number; max: number }) => void;
  textBox: TextBoxProps;
}

const defaultProps = {
  textBox: {
    textAlign: "left",
    startAdornment: null,
    endAdornment: null,
  },
};

const StyledSlider = withStyles({
  root: {
    color: color.blue,
    height: 10,
  },
  thumb: {
    boxShadow: "#ebebeb 0px 2px 2px",
    "&:focus,&:hover,&$active": {
      boxShadow: "#ccc 0px 2px 3px 1px",
    },
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const StyledThumbContainer = styled(({ value, ...props }) => <IconFilterHandler {...props} />)`
  position: absolute;
  top: -17px;
`;

const StyledThumb = (props: any) => {
  return (
    <span style={{ position: "relative" }} {...props}>
      <StyledThumbContainer value={props.value} />
    </span>
  );
};

const RangeSliderView: React.FC<RangeSliderViewProps> = (props) => {
  const {
    onChange,
    options: { min = 0, max = 100 } = { min: 0, max: 100 },
    value: { min: minValue = min, max: maxValue = max } = { min: 0, max: 100 },
    textBox,
  } = { ...props };

  // handle slidebar onchange
  const handleChange = (event: any, newValue: number | number[]) => {
    onChange({ min: newValue[0], max: newValue[1] });
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={11} sm={11}>
          <StyledSlider
            value={[minValue, maxValue]}
            ThumbComponent={StyledThumb}
            onChange={handleChange}
            valueLabelDisplay="off"
            aria-labelledby="range-slider"
            min={min}
            max={max}
          />
        </Grid>
      </Grid>

      <Grid container justify="space-between">
        <Grid item>
          <StyledParagraph size={17} lineHeight={20} color={color.black}>
            {textBox.startAdornment}
            {min}
            {textBox.endAdornment}
          </StyledParagraph>
        </Grid>
        <Grid item>
          <StyledParagraph size={17} lineHeight={20} color={color.black}>
            {textBox.startAdornment}
            {max}
            {textBox.endAdornment}
          </StyledParagraph>
        </Grid>
      </Grid>

      <Row alignItems="center">
        <Grid item xs={2} sm={2}>
          <StyledParagraph align="left" size={17} lineHeight={20} color={color.black}>
            from
          </StyledParagraph>
        </Grid>
        <Grid item xs={4} sm={4}>
          <GeneralTextField
            min={min}
            max={max}
            inputStyle={{ textAlign: textBox.textAlign }}
            startAdornment={textBox.startAdornment}
            endAdornment={textBox.endAdornment}
            paddingTopBottom={10}
            paddingLeftRight={0}
            name="t1"
            type="number"
            value={minValue}
            onChange={(changed) => onChange({ min: parseFloat(changed), max: maxValue })}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          {/* FIXME: ths style could be done better */}
          <StyledParagraph align="right" size={17} lineHeight={20} color={color.black} style={{ marginRight: 15 }}>
            to
          </StyledParagraph>
        </Grid>
        <Grid item xs={4} sm={4}>
          <GeneralTextField
            min={min}
            max={max}
            inputStyle={{ textAlign: textBox.textAlign }}
            startAdornment={textBox.startAdornment}
            endAdornment={textBox.endAdornment}
            paddingTopBottom={10}
            paddingLeftRight={0}
            name="t1"
            type="number"
            value={maxValue}
            onChange={(changed) => onChange({ min: minValue, max: parseFloat(changed) })}
          />
        </Grid>
      </Row>
    </>
  );
};

export default React.memo(RangeSliderView, _.isEqual);
