import _ from "lodash";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";

export const useDebouncedValue = (value, onChange, delay = 100) => {
  const [debounceValue, setDebounceValue] = useState<any>(value);
  const [debouncedOnChange] = useDebouncedCallback(onChange, delay);

  useEffect(() => {
    if (!_.isEqual(debounceValue, value)) {
      debouncedOnChange(debounceValue);
    }
  }, [debounceValue]);

  useEffect(() => {
    if (!_.isEqual(debounceValue, value)) {
      setDebounceValue(value);
    }
  }, [value]);

  const updateValue = (e) => {
    if (e.persist) {
      e.persist();
    }
    setDebounceValue(e);
  };

  return [debounceValue, updateValue];
};
