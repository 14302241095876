import Typography, { TypographyProps } from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";

const Styled = styled(({ children, ...props }) => (
  <Typography component="p" {...props} variant="button">
    {children}
  </Typography>
))`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ParagraphButton: React.FC<TypographyProps> = ({ children, ...props }) => {
  return <Styled {...props}>{children}</Styled>;
};

export default ParagraphButton;
