import { Grid, IconButton, Paper, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AlertDataProps, removeAlertAction } from "@reducers/alerts";
import { StoreProps } from "@reducers/store";
import usePrevious from "@utils/usePrevious";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledParagraph from "./StyledParagraph";
import WidthContext from "./WidthContext";

const AlertWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const { message, type }: AlertDataProps = useSelector((state: StoreProps) => state.alerts);
  const prevMessage = usePrevious(message);
  const width = useContext(WidthContext);

  return (
    <div>
      {message && (
        <Snackbar
          transitionDuration={prevMessage === null ? 500 : 300}
          style={{ top: "5vh" }}
          open={true}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Paper style={{ paddingTop: 0 }} elevation={5}>
            <Alert
              style={{ maxWidth: "80vw", minWidth: "30vw", wordBreak: "break-word" }}
              severity={type}
              onClose={() => {
                dispatch(removeAlertAction());
              }}
              action={
                <Grid style={{ height: "100%", paddingTop: 5 }} container alignItems="flex-start">
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      dispatch(removeAlertAction());
                    }}
                  >
                    X
                  </IconButton>
                </Grid>
              }
            >
              {/* <AlertTitle>{_.capitalize(type)}</AlertTitle> */}
              <StyledParagraph style={{ color: "inherit" }} guideline="p2" variant="body2" dangerouslySetInnerHTML={{ __html: message }} />
            </Alert>
          </Paper>
        </Snackbar>
      )}
      {children}
    </div>
  );
};

export default AlertWrapper;
