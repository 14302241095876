import { ClickAwayListener, Fade } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
import { color } from "@resources/styles";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import StyledParagraph from "./StyledParagraph";
import WidthContext from "./WidthContext";

interface CustomTooltipProps {
  tip?: string;
  placement?: TooltipProps["placement"];
  style?: CSSProperties;
  children: any;
}

const Popup = styled.div`
  border: 2px solid #36c0cc;
  padding: 24px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
`;

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const StyledTooltip = styled(Tooltip)`
  display: inline;
  position: relative;
  line-height: 18px;
  vertical-align: middle;
  .MuiTooltip-popper {
    background-color: blue;
  }

  cursor: pointer;

  &:hover {
    & svg {
      fill: ${color.primary};
    }
  }
`;

const CustomTooltip: React.FC<CustomTooltipProps> = ({ tip, placement = "top", style, children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const width = useContext(WidthContext);
  const mobile = width === "xs" || width === "sm";

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return mobile ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledTooltip
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 400 }}
        classes={{ tooltip: classes.root }}
        placement={placement}
        title={
          <Popup>
            <StyledParagraph align="left" size={13} lineHeight={20} color={color.black}>
              {tip}
            </StyledParagraph>
          </Popup>
        }
        style={style}
      >
        <div onClick={handleTooltipOpen}>{children}</div>
      </StyledTooltip>
    </ClickAwayListener>
  ) : (
    <StyledTooltip
      disableFocusListener
      disableTouchListener
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 400 }}
      classes={{ tooltip: classes.root }}
      placement={placement}
      title={
        <Popup>
          <StyledParagraph align="left" size={13} lineHeight={20} color={color.black}>
            {tip}
          </StyledParagraph>
        </Popup>
      }
      style={style}
    >
      <span>{children}</span>
    </StyledTooltip>
  );
};

export default CustomTooltip;
